import { AbstractControl, ValidatorFn } from '@angular/forms';

// Fonction de validation personnalisée pour le numéro de téléphone
export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const phoneNumberRegex = /^(06|07)[0-9]{8}$/; // Numéro commençant par 06 ou 07, suivi de 8 chiffres
    const isValid = phoneNumberRegex.test(control.value);
    return isValid ? null : { invalidPhoneNumber: true };
  };
}

// Fonction de validation personnalisée pour le numéro de téléphone
export function textNotEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const minLength = 1; // Minimum length required
    const isValid = control.value && control.value.length >= minLength;
    return isValid ? null : { minLength: { requiredLength: minLength, actualLength: control.value ? control.value.length : 0 } };
  };
}
