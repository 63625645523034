import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OptionService {

  constructor(private httpClient: HttpClient) { }
  private optionsUrl = `${environment.apiUrl}/options`;

  public getOptionsIds(licence: any, productID: any) {
    let params = { "procaisse_licence_id": licence, "productID": productID }
    return this.httpClient.post(`${this.optionsUrl}ByLicenceAndProduct`, params);
  }

  public getIngredients(licence: any, productID: any) {
    let params = { "procaisse_licence_id": licence, "productID": productID }
    return this.httpClient.post(`${this.optionsUrl}IngrByLicenceAndProduct`, params);
  }
  public getOptions(licence: any, optionIDS: any) {
    let params = { "procaisse_licence_id": licence, "optionIDS": optionIDS }
    return this.httpClient.post(`${this.optionsUrl}ByLicenceAndIds`, params);
  }
  public getOptionsItems(licence: any, optionID: any) {
    let params = { "procaisse_licence_id": licence, "optionID": optionID }
    return this.httpClient.post(`${this.optionsUrl}ItemsByLicenceAndOption`, params);
  }
  public getIngredientsStock(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.optionsUrl}IngByLicence`, params);
  }
  public saveIngredientPrice(licence: any, ingredient: any) {
    let params = { "procaisse_licence_id": licence, "ingredient": ingredient }
    return this.httpClient.post(`${this.optionsUrl}IngByLicenceUpdate`, params);
  }
  public saveOptionsQ(licence: any, options: any) {
    let params = { "procaisse_licence_id": licence, "options": options }
    return this.httpClient.post(`${this.optionsUrl}ByLicenceUpdate`, params);
  }
  public getAllOptionsItems(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.optionsUrl}ByLicence`, params);
  }
  public getOptionsByTabId(licence: any, options_id: any) {
    let params = { "procaisse_licence_id": licence, "options_id": options_id }
    return this.httpClient.post(`${this.optionsUrl}ByLicenceAndTabIDs`, params);
  }
  public getSubGroupsInfo(licence: any, sub_options_id: any) {
    let params = { "procaisse_licence_id": licence, "subOptionsIds": sub_options_id }
    return this.httpClient.post(`${this.optionsUrl}SubsByLicenceAndIdsV0`, params);
  }
}
