import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FranchiseService {
  constructor(private httpClient: HttpClient) { }
  private java_url = `${environment.java_api}`;
  private backoffice_url = `${environment.backofficeapiUrl}`;
  public getRestos(id) {
    return this.httpClient.post(`${this.java_url}/clickcollect`, id);
  }
  public getRestosPostgres(id) {
    return this.httpClient.get(`${this.backoffice_url}/api/franchiseprocaisse/clickcollect/${id}`)
  }
}
