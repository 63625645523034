import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-checkout-response',
  templateUrl: './checkout-response.component.html',
  styleUrls: ['./checkout-response.component.scss']
})
export class CheckoutResponseComponent implements OnInit {


@Input() failureStripeCode: string;

  constructor(
    private router: Router,) { }

  ngOnInit() {
  }

  reloadOrder(){ 
    window.location.reload();
    // this.router.navigate(["order-checkout"]);
  }
}
