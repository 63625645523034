import { Component, OnInit } from '@angular/core';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppImageRosolver } from 'src/app/managers/app-image-resolver.service';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { ProductService } from 'src/app/services/product.service';
import { OptionService } from 'src/app/services/option.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { filigrane } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
    trigger, state, style, transition,
    animate, group
} from '@angular/animations';
import { BasketBehaviorService } from 'src/app/services/basket-behavior.service';
import { AppUserService } from 'src/app/managers/app-user.service';
import { FormControl } from '@angular/forms';

let mapnamesizes = new Map();
mapnamesizes.set("size1","product_size_4");
mapnamesizes.set("size2","product_size_5");
mapnamesizes.set("size3","product_size_6");
mapnamesizes.set("size4","product_size_7");
mapnamesizes.set("size5","product_size_8");
mapnamesizes.set("size6","product_size_9");
mapnamesizes.set("size7","product_size_10");

declare var $: any;
@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                'transform': 'translateY(0%)', 'opacity': '1', 'visibility': 'visible'
            })),
            state('out', style({
                'transform': 'translateY(100%)', 'opacity': '0', 'visibility': 'hidden'
            })),
            transition('in => out', [group([
                animate('400ms ease-in-out', style({
                    'opacity': '0'
                })),
                animate('600ms ease-in-out', style({
                    'transform': 'translateY(100%)',
                })),
                animate('700ms ease-in-out', style({
                    'visibility': 'hidden'
                }))
            ]
            )]),
            transition('out => in', [group([
                animate('400ms ease-in-out', style({
                    'visibility': 'visible'
                })),
                animate('1s ease-in-out', style({
                    'transform': 'translateY(0%)',
                })),
                animate('800ms ease-in-out', style({
                    'opacity': '1'
                }))
            ]
            )])
        ]),
    ]
})
export class ProductDetailComponent implements OnInit {
    filigrane = filigrane;
    modalOption: NgbModalOptions = {};
    comment=new FormControl('');
    licenceID: any;
    orderType: any;
    categories: any;
    currentItem: any;
    currentItemInBasket: any;
    options: any;
    ingredients: any;
    ArrayIngrdients: any = {};
    loading = true;
    currentTicketLine: any = null;
    hide_ingredients: any;
    hideTitle: boolean = false;
    hideTitleOption: boolean = false;
    hideImageOption: boolean = false;
    private size: any = null;
    private mOrder: any = null;
    private price_order_type: any = null;
    availablePrices: any = [];
    sizeNames: any;
    sizeLabel: any = null;
    currentOption: any;
    modalCancel = false;
    isClosed = false;
    currentMenuTicketLine: any = [];
    menuLoading = [];
    currentMenuItem = [];
    currentMenuIndex: any;
    menuOptions: any = [];
    menuIngredients: any = [];
    hide_menu_ingredients: any = [];
    erreurMinMenu = false;
    activeCounter = -1;
    isFidProduct=false;
    discountC:any = 0;
    label_discountC: string = "";
    userData:any;
    backgroundColorOpacity: string = "";
    listCategoriesColor: string = "";
    swiperConfig = {
        direction: 'horizontal',
        slidesPerView: '3',
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    };
    stickyPriceAnimation = 'out';
    oneCatError=false;
    avantageImg: string = "";
    tvaP: any = 10;
    typeCommande: string;
    ttcP: any;
    currency: any;
    resto_siret: any;

    idItem: any;
    idCategory: any;
    lineIndex: any;
    queryParamsSubscription: any;

    getObjectKeys(obj: any): string[] {
        return obj ? Object.keys(obj) : [];
      }
    
    
    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private _location: Location,
        private appLicenceManager: AppLicenceManagerService,
        private modalService: NgbModal,
        private basketBehavior:BasketBehaviorService,
        private appImageResolver: AppImageRosolver,
        private appOrderService: AppOrderService,
        private productService: ProductService,
        private mAppUserService: AppUserService,
        private optionService: OptionService,
        private route: ActivatedRoute) {
        this.licenceID = this.appLicenceManager.licenceId;
        this.orderType = this.appOrderService.order.type;
    }

    async ngOnInit(): Promise<void> {
        try {
            // Utilisez une promise pour attendre que loadItem() soit terminé
            await new Promise<void>(resolve => {
              this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
                this.idItem = params['idItem']; 
                this.idCategory = params['idCategory']; 
                this.lineIndex = params['lineIndex'];
                // Appel à loadItem() qui doit résoudre la promise une fois terminé
                this.loadItem().then(() => resolve());
              });
            });

            if (this.currentItem && this.currentItem.sub_products) {
                const results = await this.fetchCurrentProducts(this.currentItem.sub_products);
            
                const ingredientsObj = {};
                const itemsWithoutIngredients = [];
            
                results.forEach(result => {
                    const subProduct = result.subProduct;
                    const product = result.product;
            
                    if (subProduct && product) {
                        ingredientsObj[subProduct.id_sub_product] = [];

                        for (let i = 0; i < subProduct.number_exemplary; ++i) {
                            ingredientsObj[subProduct.id_sub_product].push({
                                idItem: product.idItem,
                                name: product.name,
                                supplements: JSON.parse(JSON.stringify(product.ingredients))
                            });

                            // Création d'un objet sans ingrédients pour currentTicketLine.items
                            itemsWithoutIngredients.push({
                                ref_product: subProduct.id_sub_product,
                                exemplary: i,
                                name: product.name,
                                product: product.name,
                                quantity: 1,
                                supplements: [] // Ici, les suppléments sont vides
                            });
                        } 
                    }
                }); 
            
                console.log('currentTicketLine.items INITIAL', JSON.stringify(this.currentTicketLine.items, null, 2));
                this.ArrayIngrdients = ingredientsObj;
                // this.currentTicketLine.items = itemsWithoutIngredients;
                if(this.currentTicketLine.items.length === 0){
                    this.currentTicketLine.items = itemsWithoutIngredients;
                }
                this.updateTicketLineItemsWithSelectedSupplements();    
            
                console.log('this.currentItem', JSON.stringify(this.currentItem.sub_products, null, 2));
                console.log('ArrayIngrdients', JSON.stringify(this.ArrayIngrdients, null, 2));
                console.log('currentTicketLine.items', JSON.stringify(this.currentTicketLine.items, null, 2));
            } else {
                console.error('Erreur lors du chargement des données : currentItem ou sub_products non défini');
            }
            
        } catch (error) {
          console.error('Erreur lors du chargement des données :', error);
        }

      }

      updateTicketLineItemsWithSelectedSupplements() {
        if (this.currentTicketLine && this.currentTicketLine.items) {
            // Parcourt chaque élément dans ArrayIngrdients
            Object.keys(this.ArrayIngrdients).forEach(key => {
                const product = this.ArrayIngrdients[key];
    
                if (product && product.supplements) {
                    // Parcourt chaque supplément dans ArrayIngrdients
                    product.supplements.forEach(supplement => {
                        // Vérifie si le supplément existe dans currentTicketLine.items
                        const supplementExistsInTicketLine = this.currentTicketLine.items.some(item => 
                            item.ref_product === parseInt(key) && 
                            item.supplements.some(itemSupplement => 
                                itemSupplement.ref_option === supplement.id_ingredient
                            )
                        );
    
                        // Si le supplément existe dans currentTicketLine.items, marque comme 'selected'
                        if (supplementExistsInTicketLine) {
                            supplement.selected = true;
                        }
                    });
                }
            });
        }
    };
      

    ngOnDestroy() {
        this.queryParamsSubscription.unsubscribe();
    }

    async loadItem() {
        window.scrollTo(0,0)
        if (!this.licenceID) {
            throw new Error("Licence ID not defined");
        }
        if (this.idCategory === "FID") {
          this.isFidProduct=true;
        }
        
        this.currentItem = await this.fetchCurrentProduct(this.idItem);
        if(this.lineIndex && localStorage.getItem("myOrder")) {
            const myOrder = JSON.parse(localStorage.myOrder);

            this.currentItemInBasket = myOrder.ticketLines[this.lineIndex];
        
              if (this.currentItemInBasket) {
                this.comment.setValue(this.currentItemInBasket && this.currentItemInBasket.comment ? this.currentItemInBasket.comment : null);
                console.log('Utilisation du produit trouvé localement :', this.currentItemInBasket);
              } else {
                console.log('Utilisation du produit n\'est pas trouvé localement  :', this.currentItemInBasket);
              }
        }

        if (!this.currentItem) {
            //redirect to 404
            return;
        }
        if (this.currentItem.is_menu) {
            this.currentItem.cartesJson.forEach(element => {
                if (element.items.length) {
                    this.appImageResolver.resolveProducts(element.items)
                }
            });
        }
        const params = this.appLicenceManager.parameters
        if (params) {
            this.hideTitle = params.hide_name_products || false;
            this.hideTitleOption = params.hide_name_options || false;
            this.hideImageOption = params.hide_image_options && !this.hideTitleOption || false;
        }
        this.loadCategories();
        this.appImageResolver.resolveProduct(this.currentItem);
        this.mOrder = this.appOrderService.order;
        this.price_order_type = this.currentItem.different_price;
        const metaDataItemSize = {
            name: null,
            price: null
        }
        if (this.mOrder) {
            if (this.currentItem.many_size) {
                this.sizeNames = this.currentItem.sizeNames;
                if (this.price_order_type) {
                    this.getEnabledSizesWithPricesAndTypes();
                }
                else {
                    this.getEnabledSizesWithPrices();
                }
                this.size = this.availablePrices[0].size;
                this.sizeLabel = this.availablePrices[0].label;
                metaDataItemSize.price = this.availablePrices[0].price

            }
            else {
                this.size = null;
                if (this.price_order_type) {
                    this.getPricesByOrderTypes(metaDataItemSize)
                }
                else {
                    metaDataItemSize.price = this.currentItem.price
                }
            }
            metaDataItemSize.name = this.size;
            
        }

        

        // CALCUL TVA PAR PRODUCT
        // RECUPERATION DEPUIS LOCALSTORAGE MY ORDER 

        
    
        if (localStorage.getItem("myOrder")) {
            this.typeCommande = JSON.parse(localStorage["myOrder"]).type;
        }
        if(this.typeCommande == 'A Emporter') {
        this.tvaP = this.currentItem.tax_emp;
        }
        else if(this.typeCommande == 'Sur Place') {
            this.tvaP = this.currentItem.tax;
        }
        else if(this.typeCommande == 'En Livraison') {
            this.tvaP = this.currentItem.tax_lv;
        }

        console.log("TVA : " + this.tvaP);


        // CALCUL HT PAR GLOBAL PRICE PRODUCT - LES OPTIONS INCLUSES
        let htP = Math.round(metaDataItemSize.price / (1 + this.tvaP) * 100)/100;
        let tvaAmountP = Math.round((metaDataItemSize.price - htP) * 100)/100;

        // DETERMINER DISCOUNT - LABEL DISCOUNT - ISFIDPRODUCT
        this.discountC = this.isFidProduct ? metaDataItemSize.price : 0;
        this.label_discountC = this.isFidProduct ? "Fidelity" : null;

        this.currentTicketLine = {
            product: this.currentItem.name,
            path: this.currentItem.path,
            imageUrl: this.currentItem.imageUrl || null,
            size: metaDataItemSize.name,
            items: this.currentItemInBasket && this.currentItemInBasket.items ? this.currentItemInBasket.items : [],
            productPrice: metaDataItemSize.price,
            price_point:this.currentItem.price_point,
            price: 0,
            priceTotal: this.currentItemInBasket && this.currentItemInBasket.priceQTotal ? this.currentItemInBasket.priceQTotal : metaDataItemSize.price,
            priceQTotal: this.currentItemInBasket && this.currentItemInBasket.priceQTotal ? this.currentItemInBasket.priceQTotal : metaDataItemSize.price,
            quantity: this.currentItemInBasket && this.currentItemInBasket.quantity ? this.currentItemInBasket.quantity : 1,
            tva: this.tvaP,
            ht: htP,
            tvaAmount: tvaAmountP,
            supplements: this.currentItemInBasket && this.currentItemInBasket.supplements ? this.currentItemInBasket.supplements : [],
            options: this.currentItemInBasket && this.currentItemInBasket.options ? this.currentItemInBasket.options : [],
            discount: this.discountC,
            label_discount: this.label_discountC,
            basketIndex: this.mOrder.ticketLines.length,
            promotions: this.currentItem.promotions,
            note: null,
            comment:this.currentItemInBasket && this.currentItemInBasket.comment ? this.currentItemInBasket.comment : null,
            place_served: null,
            time_served: null,
            ref_product: this.idItem,
            ref_category: this.idCategory,
            isFidProduct:this.isFidProduct
        };
        console.log("thciket line current 1111", this.currentTicketLine);
        this.loading = false;
        this.fetchOptions();
        setTimeout(() => {
            this.stickyPriceAnimation = 'in';
        }, 300);

        this.getbackgroundColorOpacity();
        this.getCurrency();
    }

      getCurrency() {
        // this.currency = environment.currency;
    
        if (localStorage.getItem("CURRENT_RESTO")) {
          this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
        }
        
        this.httpClient
          .get<any>(
            `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
          )
          .subscribe((Currencyresponse) => {
            this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
            console.log(this.currency)
          });
      }
      
    
      getbackgroundColorOpacity() {
        this.httpClient
          .get<any>(
            `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
          )
          .subscribe((backgroundColorOpacityresponse) => {
            this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
            this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
          });
      }

      getAvantage() {
        this.httpClient
          .get<any>(
            `${environment.backofficeapiUrl}/api/clickncollectAvantage/angular/${environment.franchiseID}`
          )
          .subscribe((Avantageresponse) => {
            this.avantageImg = Avantageresponse.image_url;
          });
      }

    setSelectedSize(mSize) {
        this.size = mSize.size;
        this.sizeLabel = mSize.label;
        this.currentTicketLine.size = mSize.size;
        this.currentTicketLine.productPrice = mSize.price;
        this.refetchOptions();
        if (this.currentItem.is_menu) {
            this.refetchProductMenu();
        }
        this.calculTotal();
    }

    increment(arg) {
        if (arg === '+') {
            this.currentTicketLine.quantity += 1;
            this.calculTotal();
            for (let i = 0; i < this.currentMenuTicketLine.length; i++) {
                this.currentMenuTicketLine[i].quantity += 1
                this.calculMenuTotal(i)
            }
        }
        if (arg === '-' && this.currentTicketLine.quantity > 1) {
            this.currentTicketLine.quantity -= 1
            this.calculTotal();
            for (let i = 0; i < this.currentMenuTicketLine.length; i++) {
                this.currentMenuTicketLine[i].quantity -= 1
                this.calculMenuTotal(i)
            }
        }
    }

    setStateIngredient(ingredientItem): void {
        console.log("from setstate", this.currentTicketLine);
        const selected = ingredientItem.selected;
        ingredientItem.selected = !ingredientItem.selected;
        if (!selected) {
            this.currentTicketLine.supplements.push(this.makeOptionLine(ingredientItem, true));
        } else {
            this.currentTicketLine.supplements = this.currentTicketLine.supplements.filter(o => o.ref_option !== ingredientItem.id_ingredient);
        }
        
    }


    setStateIngredientSub(ingredientItem: any, productName: string, productId: number, exemplary: number): void {
        console.log("from setstate", this.currentTicketLine);
        const selected = ingredientItem.selected;
        ingredientItem.selected = !ingredientItem.selected;
    
        let newSupplement = this.makeOptionLine(ingredientItem, true);
    
        // Recherche du produit dans items
        const existingProduct = this.currentTicketLine.items.find(item => item.name === productName && item.exemplary === exemplary);
    
        if (!selected) {
            if (existingProduct) {
                // Le produit existe déjà, ajoutez simplement le supplement à son array
                existingProduct.supplements.push(newSupplement);
            } else {
                // Le produit n'existe pas encore, créez un nouvel objet avec le nom et l'array supplements
                const newProduct = {
                    ref_product: productId,
                    name: productName,
                    product: productName,
                    quantity: 1,
                    supplements: [newSupplement]
                };
                this.currentTicketLine.items.push(newProduct);
            }
        } else {
            // Retirer l'ingrédient de supplements
            if (existingProduct) {
                existingProduct.supplements = existingProduct.supplements.filter(supplement => supplement.ref_option !== newSupplement.ref_option);
            }
        }
    }
    
    
      
      

    setStateMenuIngredient(ingredientItem, index): void {
        const selected = ingredientItem.selected;
        ingredientItem.selected = !ingredientItem.selected;
        if (!selected) {
            this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(ingredientItem, true));
        } else {
            this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.filter(o => o.ref_option !== ingredientItem.id_ingredient);
        }
    }

    private loadCategories() {
        this.categories = JSON.parse(localStorage.restaurant_categories);
    }

    fetchCategory(category) {
        this.router.navigate(['categories', category.idCat]);
    }

    goBack() {
        this._location.back();
    }
    /************************************************************************************************************
        * *******************************************PROMOTIONS**************************************************
        * *******************************************************************************************************
       */
    checkPromotions(ticketline, order) {
        let promo_found = false;
        ticketline.promotions.forEach(promotion => {
            if ((promotion.type_order == order.type || promotion.type_order == undefined)
                && (promotion.size_product == undefined || promotion.size_product == this.size)) {
                promo_found = true;
                ticketline.promo_id = promotion.id;
                let products_in_promo = []
                var totalQuantity = 0;
                order.ticketLines.forEach(element => {
                    if (promotion.id == element.promo_id) {
                        products_in_promo.push(element)
                        totalQuantity += element.quantity;
                    }
                });
                products_in_promo.push(ticketline)
                totalQuantity += ticketline.quantity;
                if (totalQuantity >= promotion.quantity) {
                    this.recountPromotion(order, promotion, totalQuantity, products_in_promo);
                }
                else {
                    this.appOrderService.addLine(this.currentTicketLine);
                }
            }

        });
        if (!promo_found) {
            this.appOrderService.addLine(this.currentTicketLine);
        }
    }

    recountPromotion(order, promo, quantity, tab) {
        tab = tab.sort(function (a, b) {
            return a.productPrice - b.productPrice
        })
        var promo_number = Math.trunc(quantity / promo.quantity)
        tab.forEach(element => {
            if (promo_number == 0) {
                element.discount = 0;
                element.label_discount = "";
                element.priceQTotal = element.priceQTotal;
                element.ht = Math.round(element.priceQTotal / (1 + this.tvaP) * 100)/100;
                element.tvaAmount = Math.round((element.priceQTotal - element.ht) * 100)/100;
            }
            else {
                let price = 0
                if (element.quantity <= promo_number) {
                    price = (promo.discount / 100) * element.productPrice * element.quantity;
                    promo_number -= element.quantity;

                }
                else if (element.quantity > promo_number) {
                    price = (promo.discount / 100) * element.productPrice * promo_number;
                    promo_number = 0;
                }
                element.discount = price;
                element.label_discount = promo.name_promotion;
                element.priceQTotal -= price;
                element.ht = Math.round(element.priceQTotal / (1 + this.tvaP) * 100)/100;
                element.tvaAmount = Math.round((element.priceQTotal - element.ht) * 100)/100;
            }
            let index = order.ticketLines.findIndex((t) => t.basketIndex == element.basketIndex)
            if (index === -1) { order.ticketLines.push(element) }
            else if (index !== -1) {
                order.ticketLines[index] = element
            }
        });
        this.recountBasketPrices(order)
    }

    recountBasketPrices(order) {
        var globalPrice = 0;
        var globalQPrice = 0;
        order.ticketLines.forEach(line => {
            globalPrice += line.priceTotal;
            globalQPrice += line.priceQTotal;

        });
        order.globalPrice = globalPrice;
        order.globalQPrice = globalQPrice
        this.appOrderService.updateBasketPromo(order)
    }
    /************************************************************************************************************
     * *******************************************END**PROMOTIONS************************************************
     * **********************************************************************************************************
    */
    /************************************************************************************************************
     * ***********************************************BASKET*****************************************************
     * **********************************************************************************************************
    */
    hashObject(obj) {
        return JSON.stringify(obj)
            .split('')
            .reduce((acc, val) => {
                acc = ((acc << 5) - acc) + val.charCodeAt(0);
                return acc & acc;
            }, 0);
    }

    addToBasket() {
        this.currentTicketLine.comment=this.comment.value
        let selectedSize = 0
        if (this.currentItem.is_menu) {
            this.erreurMinMenu = false;
            this.currentMenuTicketLine.forEach(element => {
                if (element.product != undefined) { selectedSize++ }
            });
            this.erreurMinMenu = selectedSize === this.currentItem.cartesJson.length ? false : true
        }
        let erreur = false;
        if (!this.erreurMinMenu) {
            this.options.forEach(element => {
                let countSelected = 0;
                
                element.items.forEach(item => {
                    // item.selected = false;
                    if (item.selected) { countSelected += item.selected };
                });
                if (element.actualMin && element.actualMin > countSelected) {
                    element.minErreur = true;
                    erreur = true;
                    this.oneCatError=true
                }
                else {
                    element.minErreur = false;
                }
            });
            if (!erreur) {
                let menuErreur = false;
                this.menuOptions.forEach(group => {
                    group.forEach(element => {
                        let countSelected = 0;
                        element.items.forEach(item => {
                            if (item.selected) { countSelected += item.selected };
                        });
                        if (element.actualMin && element.actualMin > countSelected) {
                            element.minErreur = true;
                            menuErreur = true;
                        }
                        else {
                            element.minErreur = false;
                        }
                    });
                });
                if (!menuErreur) {
                    if (!this.currentTicketLine) {
                        return;
                    }
                    let price = this.currentTicketLine.priceQTotal;
                    this.currentMenuTicketLine.forEach(element => {
                        price += element.priceQTotal;
                        this.currentTicketLine.items.push(element)
                    });
                    this.currentTicketLine.priceQTotal = price;
                    if(this.isFidProduct){
                        this.currentTicketLine.priceTotal-=this.currentTicketLine.productPrice
                        this.currentTicketLine.priceQTotal-=this.currentTicketLine.productPrice
                        this.currentTicketLine.ht = Math.round(this.currentTicketLine.priceQTotal / (1 + this.tvaP) * 100)/100;
                        this.currentTicketLine.tvaAmount = Math.round((this.currentTicketLine.priceQTotal - this.currentTicketLine.ht) * 100)/100;
                        this.userData=this.mAppUserService.getUser()
                        this.userData.cagnotte-=this.currentTicketLine.price_point
                        this.mAppUserService.setUser(this.userData);
                        if (this.lineIndex)
                            this.appOrderService.replaceLine(this.lineIndex, this.currentTicketLine);
                        else {
                            const { basketIndex, ht, quantity, tvaAmount, priceQTotal, ...currentData } = this.currentTicketLine;
                            const currentHash = this.hashObject(currentData);

                            let foundMatchingHash = false;
                            let matchingIndex = -1;

                            for (const [index, line] of this.appOrderService.order.ticketLines.entries()) {
                                const { basketIndex, ht, quantity, tvaAmount, priceQTotal, ...data } = line;
                                const hash = this.hashObject(data);
                            
                                if (hash === currentHash) {
                                    foundMatchingHash = true;
                                    matchingIndex = index;
                                    break;
                                }
                            }

                            if (foundMatchingHash) {
                                this.appOrderService.changeLineQuantity(matchingIndex, this.currentTicketLine.quantity);
                            }
                            else {
                                this.appOrderService.addLine(this.currentTicketLine);
                            }
                        }
                        this.basketBehavior.onNumberBasket(this.appOrderService.order && this.appOrderService.order.ticketLines.length || 0)
                    }
                    else{
                        if (this.currentTicketLine.promotions.length) {
                            this.checkPromotions(this.currentTicketLine, this.appOrderService.order)
                        }
                        else if (!this.currentTicketLine.promotions.length) {
                            if (this.lineIndex)
                                this.appOrderService.replaceLine(this.lineIndex, this.currentTicketLine);
                            else {
                                const { basketIndex, ht, quantity, tvaAmount, priceQTotal, ...currentData } = this.currentTicketLine;
                                const currentHash = this.hashObject(currentData);

                                let foundMatchingHash = false;
                                let matchingIndex = -1;

                                for (const [index, line] of this.appOrderService.order.ticketLines.entries()) {
                                    const { basketIndex, ht, quantity, tvaAmount, priceQTotal, ...data } = line;
                                    const hash = this.hashObject(data);
                                
                                    if (hash === currentHash) {
                                        foundMatchingHash = true;
                                        matchingIndex = index;
                                        break;
                                    }
                                }

                                if (foundMatchingHash) {
                                    this.appOrderService.changeLineQuantity(matchingIndex, this.currentTicketLine.quantity);
                                }
                                else {
                                    this.appOrderService.addLine(this.currentTicketLine);
                                }
                            }
                            this.basketBehavior.onNumberBasket(this.appOrderService.order && this.appOrderService.order.ticketLines.length || 0)
                        }
                    }

                    /*if (this.currentItem.additionals.length) {
                        localStorage.setItem("additionalSales", JSON.stringify(this.currentItem.additionals))
                        this.router.navigate(['additional-sales']);
                    }
                    else {*/
                        if (this.idCategory)
                            this.router.navigate(['categories', this.idCategory]);
                        else
                            this.router.navigate(['categories']);
                    //}
                }
            }
        }
    }

    private calculTotal() {
        let priceTotal = this.currentTicketLine.productPrice;
        let priceQTotal = this.currentTicketLine.productPrice * this.currentTicketLine.quantity
        this.currentTicketLine.supplements.forEach(o => {
            priceTotal += o.price !== undefined ? o.price : 0;
            priceQTotal += o.price !== undefined ? o.price * this.currentTicketLine.quantity : 0;
        });
        this.currentTicketLine.priceTotal = priceTotal;
        this.currentTicketLine.priceQTotal = priceQTotal;
        this.currentTicketLine.ht = Math.round(this.currentTicketLine.priceQTotal / (1 + this.tvaP) * 100)/100;
        this.currentTicketLine.tvaAmount = Math.round((this.currentTicketLine.priceQTotal - this.currentTicketLine.ht) * 100)/100;
    }
    private calculMenuTotal(index) {
        let priceTotal = this.currentMenuTicketLine[index].productPrice;
        let priceQTotal = this.currentMenuTicketLine[index].productPrice * this.currentMenuTicketLine[index].quantity
        this.currentMenuTicketLine[index].supplements.forEach(o => {
            priceTotal += o.price !== undefined ? o.price : 0;
            priceQTotal += o.price !== undefined ? o.price * this.currentMenuTicketLine[index].quantity : 0;
        });
        this.currentMenuTicketLine[index].priceTotal = priceTotal;
        this.currentMenuTicketLine[index].priceQTotal = priceQTotal;
        this.currentTicketLine[index].ht = Math.round(this.currentMenuTicketLine[index].priceQTotal / (1 + this.tvaP) * 100)/100;
        this.currentTicketLine[index].tvaAmount = Math.round((this.currentMenuTicketLine[index].priceQTotal - this.currentTicketLine[index].ht) * 100)/100;
    }
    getCurrentPrice() {
        let price = this.currentTicketLine.priceQTotal;
        this.currentMenuTicketLine.forEach(element => {
            price += element.priceQTotal;
        });
        return price;
    }
    /************************************************************************************************************
      * *********************************************END**BASKET*************************************************
      * *********************************************************************************************************
     */
    /************************************************************************************************************ 
     * ***********************************************Menu******************************************************
     * *********************************************************************************************************
    */
    refetchProductMenu() {
        this.currentItem.cartesJson.forEach(menu => {
            menu.items.forEach(product => {
                product.selected = false;
            });
        })
        this.currentMenuTicketLine = [];
        this.menuLoading = [];
        this.currentMenuItem = [];
        this.currentMenuIndex = null;
        this.menuOptions = [];
        this.menuIngredients = [];
    }

    fetchProductMenu(carte, item, index, contentOptions) {
        if (!item.selected) {

            this.currentMenuTicketLine[index] = {
                product: item.name,
                path: item.path,
                imageUrl: item.imageUrl || null,
                size: this.size,
                productPrice: item.price,
                price: 0,
                priceTotal: item.price,
                priceQTotal: item.price,
                quantity: 1,
                tva: item.tva,
                ht: item.ht,
                tvaAmount: item.tvaAmount,
                supplements: [],
                options: [],
                discount: 0.0,
                label_discount: null,
                note: null,
                place_served: null,
                time_served: null,
                ref_product: item.idItem,
                ref_category: item.idCat,
            };
            carte.items.forEach(element => {
                element.selected = false;
            });
            this.menuLoading[index] = true;
            item.selected = true;
            this.currentMenuItem[index] = item;
            this.activeCounter = index;
            this.fetchMenuOptions(item, index)
            this.modalService.open(contentOptions, {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                scrollable: true
            });
        }
    }

    private async fetchMenuOptions(product, index) {
        const ingredients = product.ingredients;
        let options: any = [];
        options = product.newOptions
        this.menuOptions[index] = options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true;
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'menuCall', index);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.showQT = false
                optionItem.selected = 0;
                optionItem.free = 0;
                optionItem.freeStyle = false;
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.menuIngredients[index] = ingredients && Array.isArray(ingredients) && ingredients.map(item => {
            item.selected = false;
            return item;
        }) || [];
        if (this.menuIngredients[index] && this.menuIngredients[index].length) this.hide_menu_ingredients[index] = true;
        this.appImageResolver.resolveOptions(this.menuOptions[index]);
        this.appImageResolver.resolveIngredients(this.menuIngredients[index]);
        this.menuLoading[index] = false;
    }

    /************************************************************************************************************ 
      * *****************************************END***Menu*******************************************************
      * **********************************************************************************************************
     */

    /************************************************************************************************************ 
     * ***********************************************SUPPLEMENT*************************************************
     * **********************************************************************************************************
    */
    private async fetchOptions() {
        const ingredients = this.currentItem.ingredients;
        let options: any = [];
        options = this.currentItem.newOptions
        this.options = options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true;
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'simpleCall', -1);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.showQT = false
                optionItem.selected = 0;
                optionItem.free = 0;
                optionItem.actualPrice = actualPrice;

                // Vérifier si this.currentItemInBasket est défini et contient la propriété supplements
                if (this.currentItemInBasket && this.currentItemInBasket.supplements) {
                    // Vérifier si optionItem existe dans le panier
                    const selectedSupplement = this.currentItemInBasket.supplements.find(supplement => supplement.ref_option === optionItem.id);
                    if (selectedSupplement) {
                        optionItem.selected = selectedSupplement.quantity;
                        optionItem.free = selectedSupplement.number_free;
                        optionItem.showQT = true;
                        optionCategory.total_selected++;
                    }
                }

                return optionItem;
            })
            return optionCategory;
        });

        // prefill the ingredients choice
        if (ingredients && Array.isArray(ingredients)) {
            this.ingredients = ingredients.map(item => {
                item.selected = this.currentTicketLine.supplements.some(
                    supplement => supplement.ref_option === item.id_ingredient
                );
                return item;
            });
        } else {
            this.ingredients = [];
        }
          
        if (this.ingredients && this.ingredients.length) this.hide_ingredients = true;
        this.appImageResolver.resolveOptions(this.options);
        this.appImageResolver.resolveIngredients(this.ingredients);
    }
    
    refetchOptions() {
        this.ingredients.forEach(element => {
            element.selected = false;
        });
        this.options = this.options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'simpleCall', -1);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.selected = 0;
                optionItem.showQT = false
                optionItem.free = 0;
                optionItem.freeStyle = false;
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.currentTicketLine.supplements = [];
        this.calculTotal();
    }

    private async fetchSubOptions(optionParentItem) {
        let subOptionsDataIds: any = []
        subOptionsDataIds = await this.findSubOptionsInfos(optionParentItem.groups_sub_supplement);
        this.currentOption.sub_options_data = subOptionsDataIds.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true
            optionCategory.infos = this.getInfoSubGroupSupplement(optionCategory);
            optionCategory.sub_supplements = optionCategory.sub_supplements.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.showQT = false
                optionItem.selected = 0;
                optionItem.free = 0;
                optionItem.parentIndex = optionParentItem.listIndex
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.appImageResolver.resolveSubOptions(this.currentOption.sub_options_data);
        this.currentOption.sub_loaded = true;

    }

    private makeOptionLine(optionItem, isIngredient = false, parent = -1) {
        return {
            name: !isIngredient && optionItem.name || `Sans ${optionItem.name}`, // Sans ?
            is_ingredient: isIngredient,
            quantity: !isIngredient && optionItem.selected ? optionItem.selected : 1,
            price: !isIngredient && (optionItem.selected - optionItem.free) * optionItem.actualPrice || 0,
            ref_option: !isIngredient && optionItem.id || optionItem.id_ingredient || null, // id_ingredient
            ref_product: -1,
            ref_carte: -1,
            number_option: 1,
            number_free: !isIngredient && optionItem.free ? optionItem.free : 0,
            name_product: null,
            name_carte: null,
            index_carte: -1,
            listIndex: optionItem.listIndex,
            parentIndex: parent > -1 && parent
        }
    }

    makeSelection2(group, item, param, index): void {
        console.log("item makeselection222");
        
        group.minErreur = false;
        let option_free = group.option_free;
        let max_option = group.max_option;
        group.max_selected = max_option
        group.max_free = option_free
        this.makeCounts(group, item, 'sub', null, param, index)
        this.calculTotal();
        this.modalCancel = this.getCancelBtnValue();
    }

    makeSelection(content, group, item, param, index): void {
        console.log("item makeselection1111",content, group, item, param, index);
        let oCategory = null;
        
        group.minErreur = false;
        if (param == "menuCall") {
            oCategory = this.currentMenuItem[index].options.find(c => c.idSupplement === group.id)
            this.currentMenuIndex = index;
        }
        else {
            oCategory = this.currentItem.options.find(c => c.idSupplement === group.id)
        }

        console.log("oCategory", oCategory);

        let option_free = oCategory.option_free;
        let max_option = oCategory.max_option;
        if (this.size && oCategory.many_size) {
            switch (this.sizeLabel) {
                case 'product_size_1':
                    option_free = oCategory.option_free;
                    max_option = oCategory.max_option;
                    break;
                case 'product_size_2':
                    option_free = oCategory.option_free_size2;
                    max_option = oCategory.max_option_size2;
                    break;
                case 'product_size_3':
                    option_free = oCategory.option_free_size3;
                    max_option = oCategory.max_option_size3;
                    break;
                case 'product_size_4':
                    option_free = oCategory.option_free_size4;
                    max_option = oCategory.max_option_size4;
                    break;
                case 'product_size_5':
                    option_free = oCategory.option_free_size5;
                    max_option = oCategory.max_option_size5;
                    break;
                case 'product_size_6':
                    option_free = oCategory.option_free_size6;
                    max_option = oCategory.max_option_size6;
                    break;
                case 'product_size_7':
                    option_free = oCategory.option_free_size7;
                    max_option = oCategory.max_option_size7;
                    break;
                case 'product_size_8':
                    option_free = oCategory.option_free_size8;
                    max_option = oCategory.max_option_size8;
                    break;
                case 'product_size_9':
                    option_free = oCategory.option_free_size9;
                    max_option = oCategory.max_option_size9;
                    break;
                case 'product_size_10':
                    option_free = oCategory.option_free_size10;
                    max_option = oCategory.max_option_size10;
                    break;
            }
        }
        else if (!this.size || !oCategory.many_size) {
            option_free = oCategory.option_free;
            max_option = oCategory.max_option;
        }

        group.max_selected = max_option
        group.max_free = option_free
        this.makeCounts(group, item, 'option', content, param, index)
        
        if (param == "menuCall") {
            this.calculMenuTotal(index);
        }
        else {
            this.calculTotal();
        }
    }

    makeCounts(group, item, param, content, menuParam, index) {
        if (group.total_selected < group.max_selected) {
            if (item.selected == 0) {
                item.selected = 1;
                group.total_selected++;
                if (group.total_free < group.max_free) {
                    item.free++;
                    group.total_free++;
                    item.freeStyle = true;
                    if (group.total_free == group.max_free) {
                        group.show_prices = true;
                    }
                }
                if (menuParam == "menuCall") {
                    item.listIndex = this.currentMenuTicketLine[index].supplements.length;
                    if (param === "sub") { this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(item, false, item.parentIndex)); }
                    else if (param !== "sub") { this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(item)); }
                }
                else if (menuParam == "simpleCall") {
                    item.listIndex = this.currentTicketLine.supplements.length;
                    if (param === "sub") { this.currentTicketLine.supplements.push(this.makeOptionLine(item, false, item.parentIndex)); }
                    else if (param !== "sub") { this.currentTicketLine.supplements.push(this.makeOptionLine(item)); }
                }

                if (item.groups_sub_supplement.length) {
                    this.modalCancel = false;
                    this.currentOption = item;
                    item.quantity_off = true;
                    this.modalOption.backdrop = 'static';
                    this.modalOption.keyboard = false;
                    this.modalService.open(content, this.modalOption)
                    this.fetchSubOptions(item)
                }
            }
            else {
                group.total_selected --;
                item.selected = 0;
                this.distributeFree(group, item, param, menuParam, index)
                group.total_free -= item.free;
                item.free = 0;
                item.freeStyle = false;
                this.removeItem(item, menuParam, index)
                if (group.total_free < group.max_free) {
                    group.show_prices = false;
                }
            }
        }
        else {
            if (item.selected != 0) {
                group.total_selected -= item.selected
                item.selected = 0;
                this.distributeFree(group, item, param, menuParam, index)
                group.total_free -= item.free;
                item.free = 0;
                item.freeStyle = false;
                this.removeItem(item, menuParam, index)
                if (group.total_free < group.max_free) {
                    group.show_prices = false;
                }
            }
            else {
                // else : click select on unselected while max is exceeded
            }

        }
        item.showQT = item.selected == 0 ? false : true;
    }

    incrementOQ(group, item, param, index): void {
        group.minErreur = false;
        if (group.total_selected < group.max_selected) {
            item.selected++;
            group.total_selected++;
            if (group.total_free < group.max_free) {
                item.free++;
                group.total_free++;
                item.freeStyle = true;
                if (group.total_free == group.max_free) {
                    group.show_prices = true;
                }
            }
            else {
                item.freeStyle = false;
            }
            let ticketSupplement = null;
            if (param === "simpleCall") {
                ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == item.listIndex)
            }
            else if (param === "menuCall") {
                ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == item.listIndex)
            }
            ticketSupplement.number_free = item.free;
            ticketSupplement.number_option = item.selected;
            ticketSupplement.quantity = item.selected;
            ticketSupplement.price = (item.selected - item.free) * item.actualPrice;

        }
        else {
            //else : click on increment while max is exceeded
            //later item max selection < group max selection
            // increment item selection, retrieve the number from selected list 
        }
        if (param === "simpleCall") {
            this.calculTotal();
        }
        else if (param === "menuCall") {
            this.calculMenuTotal(index);
        }
    }

    decrementOQ(group, item, param, index): void {
        if (item.selected > 0 && item.selected > item.free) {
            item.selected--;
            group.total_selected--;
            if (item.selected == item.free) { item.freeStyle = true }
        }
        else if (item.selected > 0 && item.selected == item.free) {
            item.selected--;
            group.total_selected--;
            item.free--;
            group.total_free--;
            this.distributeOneFree(group, item, param, index)
            if (group.total_free < group.max_free) {
                group.show_prices = false;
            }
        }
        if (item.free == 0) {
            item.freeStyle = false
        }
        if (item.selected > 0) {
            let ticketSupplement = null;
            if (param === "simpleCall") {
                ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == item.listIndex)
            }
            else if (param === "menuCall") {
                ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == item.listIndex)
            }
            ticketSupplement.number_free = item.free;
            ticketSupplement.number_option = item.selected;
            ticketSupplement.quantity = item.selected;
            ticketSupplement.price = (item.selected - item.free) * item.actualPrice;
        }
        else if (item.selected == 0) {
            this.removeItem(item, param, index)
        }
        if (param === "simpleCall") {
            this.calculTotal();
        }
        else if (param === "menuCall") {
            this.calculMenuTotal(index);
        }
        item.showQT = item.selected == 0 ? false : true;

    }

    checkSubsMinRequired() {
        let minErreur = false
        this.currentOption.sub_options_data.forEach(element => {
            if (element.min_option > element.total_selected) {
                element.minErreur = true;
                minErreur = true;
            }
        });
        if (minErreur === false) {
            this.modalService.dismissAll('saved')
        }
    }

    distributeFree(group, item, param, menuParam, index) {
        if (item.free > 0) {
            let selectedItems = null;
            if (param == "sub") {
                selectedItems = group.sub_supplements.filter((i) => i.selected > 0)
            }
            else {
                selectedItems = group.items.filter((i) => i.selected > 0)
            }
            selectedItems.forEach(element => {
                let counterFree = 0;
                if (element.selected > item.free && element.selected > element.free) {
                    counterFree = item.free;
                }
                else if (element.selected <= item.free && element.selected > element.free) {
                    counterFree = element.selected - element.free;
                }
                element.free += counterFree;
                item.free -= counterFree
                if (element.selected == element.free) {
                    element.freeStyle = true;
                }
                let ticketSupplement = null;
                if (menuParam === "menuCall") {
                    ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == element.listIndex)

                }
                else if (menuParam === "simpleCall") {
                    ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == element.listIndex)
                }
                ticketSupplement.number_free = element.free;
                ticketSupplement.price = (element.selected - element.free) * element.actualPrice;
            });
        }
    }

    distributeOneFree(group, item, param, index) {
        let selectedItems = null;
        if (item.parentIndex === undefined) { selectedItems = group.items.filter((i) => i.selected > 0 && i !== item) }
        else { selectedItems = group.sub_supplements.filter((i) => i.selected > 0 && i !== item) }
        let value = 1
        selectedItems.forEach(element => {
            if (value > 0) {
                if (element.selected > element.free) {
                    element.free += value;
                    group.total_free += value;
                    value--;
                }
                if (element.selected == element.free) {
                    element.freeStyle = true;
                }
                let ticketSupplement = null
                if (param === "simpleCall") {
                    ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == element.listIndex)
                }
                else if (param === "menuCall") {
                    ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == element.listIndex)
                }

                ticketSupplement.number_free = element.free;
                ticketSupplement.price = (element.selected - element.free) * element.price;
            }
        });
    }

    removeItem(item, param, index) {
        this.currentTicketLine.supplements = this.currentTicketLine.supplements.filter(_item => _item.ref_option != item.id);

        /*if (item.parentIndex === undefined) {
            this.updateIndexes(item, "option", param, index);
        }
        else {
            this.updateIndexes(item, "sub", param, index)
        }*/
    }

    updateIndexes(item, param, menuParam, index) {
        let listIndex = item.listIndex;
        let count = 1
        if (menuParam === "simpleCall") {
            this.currentTicketLine.supplements = this.currentTicketLine.supplements.
                filter(o => o.listIndex !== listIndex);
            this.currentTicketLine.supplements.forEach(element => {
                if (element.parentIndex === listIndex) {
                    count++;
                    this.currentTicketLine.supplements = this.currentTicketLine.supplements.
                        filter(o => o.listIndex !== element.listIndex);
                }
            })
            this.currentTicketLine.supplements.forEach(element => {
                this.currentTicketLine.supplements.forEach(element1 => {
                    if (element1.parentIndex !== false && element1.parentIndex === element.listIndex) {
                        element1.parentIndex -= count
                    }
                });
                this.updateOptionIndexes(param, element, count)
                element.listIndex -= count;
            });
        }
        else if (menuParam === "menuCall") {
            this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.
                filter(o => o.listIndex !== listIndex);
            this.currentMenuTicketLine[index].supplements.forEach(element => {
                if (element.parentIndex === listIndex) {
                    count++;
                    this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.
                        filter(o => o.listIndex !== element.listIndex);
                }
            })
            this.currentMenuTicketLine[index].supplements.forEach(element => {
                this.currentMenuTicketLine[index].supplements.forEach(element1 => {
                    if (element1.parentIndex !== false && element1.parentIndex === element.listIndex) {
                        element1.parentIndex -= count
                    }
                });
                this.updateOptionIndexes(param, element, count)
                element.listIndex -= count;
            });
        }

    }

    updateOptionIndexes(param, compared, value) {
        let selectedItems = []
        if (param == "sub") {
            this.currentOption.sub_options_data.forEach(element => {
                selectedItems = element.sub_supplements.filter((o) => o.selected > 0);
                selectedItems.forEach(item => {
                    if (item.listIndex === compared.listIndex) {
                        item.listIndex -= value
                    }
                });
            });
        }
        else {
            this.options.forEach(element => {
                selectedItems = element.items.filter((o) => o.selected > 0)
                selectedItems.forEach(item => {
                    if (item.listIndex === compared.listIndex) {
                        item.listIndex -= value
                    }
                });
            });
        }
    }

    //More TESTS on options and sub options AND promotions
    /************************************************************************************************************ 
     * *********************************************END***SUPPLEMENT*********************************************
     * **********************************************************************************************************
    */
    /************************************************************************************************************ 
     * ***********************************************HTTP*******************************************************
     * **********************************************************************************************************
    */
   
    private fetchCurrentProduct(productId) {
        return new Promise(async (resolve, reject) => {
            this.productService.getProduct(this.licenceID, productId, this.orderType).subscribe(res => {
                resolve(res);
            }, _ => {
                reject(null);
            });
        })
    }

    private async fetchCurrentProducts(subProducts): Promise<any[]> {
        const results = [];
      
        for (const subProduct of subProducts) {
          try {
            const product = await this.fetchCurrentProduct(subProduct.id_sub_product);
            results.push({ product, subProduct });
          } catch (error) {
            // Gérer les erreurs, par exemple, stocker l'erreur avec le subProduct concerné
            results.push({ subProduct, error });
          }
        }
      console.log("results" + results);
        return results;
      }

    private findSubOptionsInfos(sub_ids) {
        return new Promise((resolve, reject) => {
            this.optionService.getSubGroupsInfo(this.licenceID, sub_ids).subscribe((result) => {
                resolve(result);
            });
        });
    }
    /************************************************************************************************************ 
     * *********************************************END**HTTP****************************************************
     * **********************************************************************************************************
    */

    /************************************************************************************************************ 
     * ***********************************************TOOLS******************************************************
     * **********************************************************************************************************
    */
    getPricesByOrderTypes(md) {
        switch (this.mOrder.type) {
            case OrderTypeEnum.ON_THE_SPOT:
                md.price = this.currentItem.price_sp;
                break;
            case OrderTypeEnum.TAKE_AWAY:
                md.price = this.currentItem.price_emp;
                break;
            case OrderTypeEnum.DELIVERY:
                md.price = this.currentItem.price_lv;
                break;
        }
    }

    getEnabledSizesWithPrices() {

        if (
          this.sizeNames.product_size_1 !== "" &&
          this.currentItem.size_junior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_1",
            size: this.sizeNames.product_size_1,
            price: this.currentItem.price_junior,
          });
        }
        if (
          this.sizeNames.product_size_2 !== "" &&
          this.currentItem.size_senior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_2",
            size: this.sizeNames.product_size_2,
            price: this.currentItem.price_senior,
          });
        }
        if (
          this.sizeNames.product_size_3 !== "" &&
          this.currentItem.size_mega_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_3",
            size: this.sizeNames.product_size_3,
            price: this.currentItem.price_mega,
          });
        }
        if (
          this.currentItem.sizes[0] &&
          this.currentItem.sizes[0].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[0].name_size),
            size: this.currentItem.sizes[0].label_size,
            price: this.currentItem.sizes[0].price,
          });
        }
        if (
          this.currentItem.sizes[1] &&
          this.currentItem.sizes[1].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[1].name_size),
            size: this.currentItem.sizes[1].label_size,
            price: this.currentItem.sizes[1].price,
          });
        }
        if (
          this.currentItem.sizes[2] &&
          this.currentItem.sizes[2].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[2].name_size),
            size: this.currentItem.sizes[2].label_size,
            price: this.currentItem.sizes[2].price,
          });
        }
        if (
          this.currentItem.sizes[3] &&
          this.currentItem.sizes[3].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[3].name_size),
            size: this.currentItem.sizes[3].label_size,
            price: this.currentItem.sizes[3].price,
          });
        }
        if (
          this.currentItem.sizes[4] &&
          this.currentItem.sizes[4].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[4].name_size),
            size: this.currentItem.sizes[4].label_size,
            price: this.currentItem.sizes[4].price,
          });
        }
        if (
          this.currentItem.sizes[5] &&
          this.currentItem.sizes[5].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[5].name_size),
            size: this.currentItem.sizes[5].label_size,
            price: this.currentItem.sizes[5].price,
          });
        }
        if (
          this.currentItem.sizes[6] &&
          this.currentItem.sizes[6].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(this.currentItem.sizes[6].name_size),
            size: this.currentItem.sizes[6].label_size,
            price: this.currentItem.sizes[6].price,
          });
        }
      }

      getEnabledSizesWithPricesAndTypes() {
        switch (this.mOrder.type) {
          case OrderTypeEnum.ON_THE_SPOT:
            if (
              this.sizeNames.product_size_1 !== "" &&
              this.currentItem.size_junior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_1",
                size: this.sizeNames.product_size_1,
                price: this.currentItem.price_sp_junior, 
              });
            }
            if (
              this.sizeNames.product_size_2 !== "" &&
              this.currentItem.size_senior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_2",
                size: this.sizeNames.product_size_2,
                price: this.currentItem.price_sp_senior,
              });
            }
            if (
              this.sizeNames.product_size_3 !== "" &&
              this.currentItem.size_mega_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_3",
                size: this.sizeNames.product_size_3,
                price: this.currentItem.price_sp_mega,
              });
            }
            if (
              this.currentItem.sizes[0] &&
              this.currentItem.sizes[0].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[0].name_size),
                size: this.currentItem.sizes[0].label_size,
                price: this.currentItem.sizes[0].price_sp,
              });
            }
            if (
              this.currentItem.sizes[1] &&
              this.currentItem.sizes[1].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[1].name_size),
                size: this.currentItem.sizes[1].label_size,
                price: this.currentItem.sizes[1].price_sp,
              });
            }
            if (
              this.currentItem.sizes[2] &&
              this.currentItem.sizes[2].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[2].name_size),
                size: this.currentItem.sizes[2].label_size,
                price: this.currentItem.sizes[2].price_sp,
              });
            }
            if (
              this.currentItem.sizes[3] &&
              this.currentItem.sizes[3].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[3].name_size),
                size: this.currentItem.sizes[3].label_size,
                price: this.currentItem.sizes[3].price_sp,
              });
            }
            if (
              this.currentItem.sizes[4] &&
              this.currentItem.sizes[4].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[4].name_size),
                size: this.currentItem.sizes[4].label_size,
                price: this.currentItem.sizes[4].price_sp,
              });
            }
            if (
              this.currentItem.sizes[5] &&
              this.currentItem.sizes[5].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[5].name_size),
                size: this.currentItem.sizes[5].label_size,
                price: this.currentItem.sizes[5].price_sp,
              });
            }
            if (
              this.currentItem.sizes[6] &&
              this.currentItem.sizes[6].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[6].name_size),
                size: this.currentItem.sizes[6].label_size,
                price: this.currentItem.sizes[6].price_sp,
              });
            }
            break;
          case OrderTypeEnum.TAKE_AWAY:
            if (
              this.sizeNames.product_size_1 !== "" &&
              this.currentItem.size_junior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_1",
                size: this.sizeNames.product_size_1,
                price: this.currentItem.price_emp_junior,
              });
            }
            if (
              this.sizeNames.product_size_2 !== "" &&
              this.currentItem.size_senior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_2",
                size: this.sizeNames.product_size_2,
                price: this.currentItem.price_emp_senior,
              });
            }
            if (
              this.sizeNames.product_size_3 !== "" &&
              this.currentItem.size_mega_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_3",
                size: this.sizeNames.product_size_3,
                price: this.currentItem.price_emp_mega,
              });
            }
            if (
              this.currentItem.sizes[0] &&
              this.currentItem.sizes[0].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[0].name_size),
                size: this.currentItem.sizes[0].label_size,
                price: this.currentItem.sizes[0].price_emp,
              });
            }
            if (
              this.currentItem.sizes[1] &&
              this.currentItem.sizes[1].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[1].name_size),
                size: this.currentItem.sizes[1].label_size,
                price: this.currentItem.sizes[1].price_emp,
              });
            }
            if (
              this.currentItem.sizes[2] &&
              this.currentItem.sizes[2].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[2].name_size),
                size: this.currentItem.sizes[2].label_size,
                price: this.currentItem.sizes[2].price_emp,
              });
            }
            if (
              this.currentItem.sizes[3] &&
              this.currentItem.sizes[3].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[3].name_size),
                size: this.currentItem.sizes[3].label_size,
                price: this.currentItem.sizes[3].price_emp,
              });
            }
            if (
              this.currentItem.sizes[4] &&
              this.currentItem.sizes[4].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[4].name_size),
                size: this.currentItem.sizes[4].label_size,
                price: this.currentItem.sizes[4].price_emp,
              });
            }
            if (
              this.currentItem.sizes[5] &&
              this.currentItem.sizes[5].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[5].name_size),
                size: this.currentItem.sizes[5].label_size,
                price: this.currentItem.sizes[5].price_emp,
              });
            }
            if (
              this.currentItem.sizes[6] &&
              this.currentItem.sizes[6].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[6].name_size),
                size: this.currentItem.sizes[6].label_size,
                price: this.currentItem.sizes[6].price_emp,
              });
            }
            break;
          case OrderTypeEnum.DELIVERY:
            if (
              this.sizeNames.product_size_1 !== "" &&
              this.currentItem.size_junior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_1",
                size: this.sizeNames.product_size_1,
                price: this.currentItem.price_lv_junior,
              });
            }
            if (
              this.sizeNames.product_size_2 !== "" &&
              this.currentItem.size_senior_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_2",
                size: this.sizeNames.product_size_2,
                price: this.currentItem.price_lv_senior,
              });
            }
            if (
              this.sizeNames.product_size_3 !== "" &&
              this.currentItem.size_mega_enabled
            ) {
              this.availablePrices.push({
                label: "product_size_3",
                size: this.sizeNames.product_size_3,
                price: this.currentItem.price_lv_mega,
              });
            }
            if (
              this.currentItem.sizes[0] &&
              this.currentItem.sizes[0].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[0].name_size),
                size: this.currentItem.sizes[0].label_size,
                price: this.currentItem.sizes[0].price_lv,
              });
            }
            if (
              this.currentItem.sizes[1] &&
              this.currentItem.sizes[1].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[1].name_size),
                size: this.currentItem.sizes[1].label_size,
                price: this.currentItem.sizes[1].price_lv,
              });
            }
            if (
              this.currentItem.sizes[2] &&
              this.currentItem.sizes[2].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[2].name_size),
                size: this.currentItem.sizes[2].label_size,
                price: this.currentItem.sizes[2].price_lv,
              });
            }
            if (
              this.currentItem.sizes[3] &&
              this.currentItem.sizes[3].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[3].name_size),
                size: this.currentItem.sizes[3].label_size,
                price: this.currentItem.sizes[3].price_lv,
              });
            }
            if (
              this.currentItem.sizes[4] &&
              this.currentItem.sizes[4].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[4].name_size),
                size: this.currentItem.sizes[4].label_size,
                price: this.currentItem.sizes[4].price_lv,
              });
            }
            if (
              this.currentItem.sizes[5] &&
              this.currentItem.sizes[5].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[5].name_size),
                size: this.currentItem.sizes[5].label_size,
                price: this.currentItem.sizes[5].price_lv,
              });
            }
            if (
              this.currentItem.sizes[6] &&
              this.currentItem.sizes[6].size_enabled
            ) {
              this.availablePrices.push({
                label: mapnamesizes.get(this.currentItem.sizes[6].name_size),
                size: this.currentItem.sizes[6].label_size,
                price: this.currentItem.sizes[6].price_lv,
              });
            }
            break;
        }
      }
      
    getOptionsPrices(optionItem) {
        let actualPrice = optionItem.price || 0;
        if (this.size) {
            if (optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    switch (this.sizeLabel) {
                        case "product_size_1":
                            actualPrice = mPriceByTypeOrder["price_junior"] || actualPrice;
                            break;
                        case "product_size_2":
                            actualPrice = mPriceByTypeOrder["price_senior"] || actualPrice;
                            break;
                        case "product_size_3":
                            actualPrice = mPriceByTypeOrder["price_mega"] || actualPrice;
                            break;
                        case "product_size_4":
                            actualPrice = mPriceByTypeOrder["price_size1"] || actualPrice;
                            break;
                        case "product_size_5":
                            actualPrice = mPriceByTypeOrder["price_size2"] || actualPrice;
                            break;
                        case "product_size_6":
                            actualPrice = mPriceByTypeOrder["price_size3"] || actualPrice;
                            break;
                        case "product_size_7":
                            actualPrice = mPriceByTypeOrder["price_size4"] || actualPrice;
                            break;
                        case "product_size_8":
                            actualPrice = mPriceByTypeOrder["price_size5"] || actualPrice;
                            break;
                        case "product_size_9":
                            actualPrice = mPriceByTypeOrder["price_size6"] || actualPrice;
                            break;
                        case "product_size_10":
                            actualPrice = mPriceByTypeOrder["price_size7"] || actualPrice;
                            break;
                    }
                }
            }
            else if (optionItem.many_size && !optionItem.many_type) {
                switch (this.sizeLabel) {
                    case "product_size_1":
                        actualPrice = optionItem.price_junior;
                        break;
                    case "product_size_2":
                        actualPrice = optionItem.price_senior;
                        break;
                    case "product_size_3":
                        actualPrice = optionItem.price_mega;
                        break;
                    case "product_size_4":
                        actualPrice = optionItem.price_size1;
                        break;
                    case "product_size_5":
                        actualPrice = optionItem.price_size2;
                        break;
                    case "product_size_6":
                        actualPrice = optionItem.price_size3;
                        break;
                    case "product_size_7":
                        actualPrice = optionItem.price_size4;
                        break;
                    case "product_size_8":
                        actualPrice = optionItem.price_size5;
                        break;
                    case "product_size_9":
                        actualPrice = optionItem.price_size6;
                        break;
                    case "product_size_10":
                        actualPrice = optionItem.price_size7;
                        break;
                }
            }
            else if (!optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price"] || actualPrice
                }
            }
        }
        else {
            if (optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price_junior"] || actualPrice
                }
            }
            else if (optionItem.many_size && !optionItem.many_type) {
                actualPrice = optionItem.price_junior;
            }
            else if (!optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price"] || actualPrice
                }
            }
        }
        return actualPrice;
    }

    getInfoGroupSupplement(option, param, index) {
        let oCategory = null;
        if (param == "menuCall") {
            oCategory = this.currentMenuItem[index].options.find(c => c.idSupplement === option.id)
        }
        else {
            oCategory = this.currentItem.options.find(c => c.idSupplement === option.id)
        }
        let info_option = "";
        let free_number = 0;
        if (this.size && oCategory.many_size) {
            switch (this.sizeLabel) {
                case 'product_size_1':
                    if (oCategory.min_option > 0) {
                        info_option += oCategory.min_option + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option
                    if (oCategory.max_option > 0) {
                        info_option += oCategory.max_option + ' maximum '
                    }
                    if (oCategory.option_free > 0) {
                        free_number = oCategory.option_free;
                        info_option += oCategory.option_free + ' offert '
                    }
                    break;
                case 'product_size_2':
                    if (oCategory.min_option_size2 > 0) {
                        info_option += oCategory.min_option_size2 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size2
                    if (oCategory.max_option_size2 > 0) {
                        info_option += oCategory.max_option_size2 + ' maximum '
                    }
                    if (oCategory.option_free_size2 > 0) {
                        free_number = oCategory.option_free_size2
                        info_option += oCategory.option_free_size2 + ' offert '
                    }
                    break;
                case 'product_size_3':
                    if (oCategory.min_option_size3 > 0) {
                        info_option += oCategory.min_option_size3 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size3
                    if (oCategory.max_option_size3 > 0) {
                        info_option += oCategory.max_option_size3 + ' maximum '
                    }
                    if (oCategory.option_free_size3 > 0) {
                        free_number = oCategory.option_free_size3;
                        info_option += oCategory.option_free_size3 + ' offert '
                    }
                    break;
                case 'product_size_4':
                    if (oCategory.min_option_size4 > 0) {
                        info_option += oCategory.min_option_size4 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size4
                    if (oCategory.max_option_size4 > 0) {
                        info_option += oCategory.max_option_size4 + ' maximum '
                    }
                    if (oCategory.option_free_size4 > 0) {
                        free_number = oCategory.option_free_size4;
                        info_option += oCategory.option_free_size4 + ' offert '
                    }
                    break;
                case 'product_size_5':
                    if (oCategory.min_option_size5 > 0) {
                        info_option += oCategory.min_option_size5 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size5
                    if (oCategory.max_option_size5 > 0) {
                        info_option += oCategory.max_option_size5 + ' maximum '
                    }
                    if (oCategory.option_free_size5 > 0) {
                        free_number = oCategory.option_free_size5;
                        info_option += oCategory.option_free_size5 + ' offert '
                    }
                    break;
                case 'product_size_6':
                    if (oCategory.min_option_size6 > 0) {
                        info_option += oCategory.min_option_size6 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size6
                    if (oCategory.max_option_size6 > 0) {
                        info_option += oCategory.max_option_size6 + ' maximum '
                    }
                    if (oCategory.option_free_size6 > 0) {
                        free_number = oCategory.option_free_size6
                        info_option += oCategory.option_free_size6 + ' offert '
                    }
                    break;
                case 'product_size_7':
                    if (oCategory.min_option_size7 > 0) {
                        info_option += oCategory.min_option_size7 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size7
                    if (oCategory.max_option_size7 > 0) {
                        info_option += oCategory.max_option_size7 + ' maximum '
                    }
                    if (oCategory.option_free_size7 > 0) {
                        free_number = oCategory.option_free_size7
                        info_option += oCategory.option_free_size7 + ' offert '
                    }
                    break;
                case 'product_size_8':
                    if (oCategory.min_option_size8 > 0) {
                        info_option += oCategory.min_option_size8 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size8
                    if (oCategory.max_option_size8 > 0) {
                        info_option += oCategory.max_option_size8 + ' maximum '
                    }
                    if (oCategory.option_free_size8 > 0) {
                        free_number = oCategory.option_free_size8
                        info_option += oCategory.option_free_size8 + ' offert '
                    }
                    break;
                case 'product_size_9':
                    if (oCategory.min_option_size9 > 0) {
                        info_option += oCategory.min_option_size9 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size9
                    if (oCategory.max_option_size9 > 0) {
                        info_option += oCategory.max_option_size9 + ' maximum '
                    }
                    if (oCategory.option_free_size9 > 0) {
                        free_number = oCategory.option_free_size9
                        info_option += oCategory.option_free_size9 + ' offert '
                    }
                    break;
                case 'product_size_10':
                    if (oCategory.min_option_size10 > 0) {
                        info_option += oCategory.min_option_size10 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size10
                    if (oCategory.max_option_size10 > 0) {
                        info_option += oCategory.max_option_size10 + ' maximum '
                    }
                    if (oCategory.option_free_size10 > 0) {
                        free_number = oCategory.option_free_size10
                        info_option += oCategory.option_free_size10 + ' offert '
                    }
                    break;
            }
        }
        if (!this.size || !oCategory.many_size) {
            if (oCategory.min_option > 0) {
                info_option += oCategory.min_option + ' minimum '
                oCategory.actualMin = oCategory.min_option
            }
            if (oCategory.max_option > 0) {
                info_option += oCategory.max_option + ' maximum '
            }
            if (oCategory.option_free > 0) {
                free_number = oCategory.option_free
                info_option += oCategory.option_free + ' offert '
            }
        }
        free_number > 0 ? option.show_prices = false : true;
        option.actualMin = oCategory.actualMin
        return info_option;
    }

    getInfoSubGroupSupplement(option) {
        let info_option = "";
        if (option.min_option > 0) {
            info_option += option.min_option + ' minimum '
        }
        if (option.max_option > 0) {
            info_option += option.max_option + ' maximum '
        }
        if (option.option_free > 0) {
            option.show_prices = false
            info_option += option.option_free + ' offert '
        }
        else if (option.min_option <= 0) {
            option.show_prices = true
        }
        return info_option;
    }
    /************************************************************************************************************ 
     * *****************************************END***TOOLS******************************************************
     * **********************************************************************************************************
    */






    /************************************************************************************************************ 
    * ***********************************************Design*Control**********************************************
    * ***********************************************************************************************************
   */
    toggleOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleMenuOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleSubOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleIngredient() {
        if (this.hide_ingredients == true) this.hide_ingredients = false;
        else this.hide_ingredients = true;
    }
    toggleMenuIngredient(index) {
        if (this.hide_menu_ingredients[index] == true) this.hide_menu_ingredients[index] = false;
        else this.hide_menu_ingredients[index] = true;
    }
    getCancelBtnValue() {
        for (let k = 0; k < this.currentOption.sub_options_data.length; k++) {
            for (let m = 0; m < this.currentOption.sub_options_data[k].sub_supplements.length; m++) {
                if (this.currentOption.sub_options_data[k].sub_supplements[m].selected) {
                    return true;
                }
            }
        }
        return false;
    }
    /************************************************************************************************************ 
     * *********************************************END**Design*Control******************************************
     * **********************************************************************************************************
    */
   
    // isSupplementSelected(optionItem): boolean {
        
    //     if (this.currentItemInBasket && this.currentItemInBasket.supplements && this.currentItemInBasket.supplements.length > 0) {
    //       // Vérifie si l'optionItem.name existe dans les suppléments
    //       console.log("isSupplementSelected" + this.currentItemInBasket.supplements.find(supplement => supplement.ref_option === optionItem.id));
    //       return this.currentItemInBasket.supplements.some(supplement => supplement.name === optionItem.name);
    //     }
    //     // Si currentItemInBasket.supplements n'existe pas ou est vide, utilisez simplement optionItem.selected
    //     return optionItem.selected;
    //   }

    isSupplementSelected(optionItem): boolean {

        if (this.currentItemInBasket && this.currentItemInBasket.supplements && this.currentItemInBasket.supplements.length > 0) {
          // Vérifie si l'optionItem.name existe dans les suppléments
          const selectedSupplement = this.currentItemInBasket.supplements.find(supplement => supplement.ref_option === optionItem.id);
          if (selectedSupplement) {
            optionItem.showQT = true;
            return selectedSupplement.quantity;
          }
        }
        // Si currentItemInBasket.supplements n'existe pas, est vide ou l'optionItem.name n'a pas été trouvé, utilisez optionItem.selected
        return optionItem.selected;
      }

    }
