import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyOrderService {

  constructor(private httpClient: HttpClient) { }
  private myOrdersUrl = `${environment.apiUrl}/orders`;
  private myOrdersUrlNew = `${environment.apiUrl}/franchise/orders`;

  public createOrder(order) {
    return this.httpClient.post(`${this.myOrdersUrl}`, order);
  }
  public createOrderNew(order) {
    return this.httpClient.post(`${this.myOrdersUrlNew}`, order);
  }
  public getOrdersByLicenceAndClient(params) {
    return this.httpClient.post(`${this.myOrdersUrl}ByLicenceAndClient`, params);
  }

  public notifyEcran(token: any) {
    let params = { "message": "nouvelle commande" };
    return this.httpClient.post(`${environment.apiUrl}/fcm/${token}`, params);
  }

  public getOrder(orderId) {
    return this.httpClient.get(`${this.myOrdersUrl}/${orderId}/getInfos`);
  }

  public fetchOrderDelivery(licenceId) {
    const params = { procaisse_licence_id: licenceId };
    return this.httpClient.post(`${environment.apiUrl}/ordersJsonDelivery`, params);
  }

  public fetchOrderParams(licenceId): any {
    const params = { procaisse_licence_id: licenceId };
    return this.httpClient.post(`${environment.apiUrl}/ordersJsonExtraParams`, params);
  }
}
