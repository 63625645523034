import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

//TODO: A supprimer
@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private apiUrl = `${environment.apiUrl}`;
  constructor(private httpClient: HttpClient) { }
public createPayment(licence:any,amount: any,token:any){
  let params= {"procaisse_licence_id":licence,"amount":amount,"stripeTokenId":token}
  return this.httpClient.post(`${this.apiUrl}/stripe`,params);
}
public intializepayment(licence:any,amount: any){
  let params= {"procaisse_licence_id":licence,"amount":amount}
  return this.httpClient.post(`${this.apiUrl}/intializepayment`,params);
}
public intializepayment2(licence:any,amount: any){
  let params= {"procaisse_licence_id":licence,"amount":amount}
  return this.httpClient.post(`${this.apiUrl}/intializepayment2`,params);
}
public createpaymentintent(licence:any,amount: any){
  let params= {"procaisse_licence_id":licence,"amount":amount}
  return this.httpClient.post(`${this.apiUrl}/create-payment-intent`,params);
}
// public sendInvoice(email:any, amount: any, licence:any){
//   let params= {"email":email, "amount":amount, "procaisse_licence_id":licence}
//   return this.httpClient.post(`${this.apiUrl}/invoicesend`,params);
// }
}
