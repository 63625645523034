import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { BasketBehaviorService } from 'src/app/services/basket-behavior.service';
import { MyOrderService } from 'src/app/services/my-order.service';
import { environment } from 'src/environments/environment';
import { home_animation, home_exit } from '../../app-animation';
declare var require: any
const moment = require('moment-timezone');
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [home_animation, home_exit]
})
export class HomeComponent implements OnInit {
  current_resto:any;
  orderType: null;
  AVAILABLE_TYPES = OrderTypeEnum;
  private _order = null;
  isDeliveryEnabled = false;
  isAtSpotEnabled = false;
  isTakeAwayEnabled = false;
  isDeliveryAtPlatform = false;
  urlPlatform = null;
  showTypeOrders = true;
  showZones = false;
  orderCity = null;
  zones = [];
  isClosed = false;
  licenceID:any;
  constructor(private appLicenceManager: AppLicenceManagerService,
    private myOrderService: MyOrderService,
    private router: Router,
    private route: ActivatedRoute,
    private appOrderService: AppOrderService,
    private basketBehavior: BasketBehaviorService) {
    this.licenceID = this.route.snapshot.paramMap.get("licenceId");
    this.appLicenceManager.factory(this.licenceID);
  }

  ngOnInit() {
    this.myOrderService.fetchOrderParams(this.appLicenceManager.licenceId).subscribe(res => {
      if (res) {
        this.isDeliveryAtPlatform = res.platform && res.platform_url || false;
        if (this.isDeliveryAtPlatform) {
          this.urlPlatform = res.platform_url;
        }
        const isoWeekDay = moment().isoWeekday(), now = moment();
        let mDay = null;
        if (res.days && res.days.length) {
          mDay = res.days.find(d => {
            return d.day === isoWeekDay;
          });
        }
        let isDayOff = mDay && mDay.dayOff || false;
        let isOpenTime = false;
        if (mDay) {
          mDay.workShifts.forEach(s => {
           // this.displayOnSpot(now, s, res.at_spot)
            //this.displayTakeAway(now, s, res.take_away)
           // this.displayDelivery(now, s, res.has_delivery, res.delivery)
            const sHours = parseInt(s.start_shift),
              eHours = parseInt(s.end_shift);
            if (now.hours() >= sHours && now.hours() < eHours) {
              isOpenTime = true;
            }
          });
        }
          this.isDeliveryEnabled = res.has_delivery && res.delivery || false;
          // this.isAtSpotEnabled = res.at_spot || false;
          // this.isTakeAwayEnabled = res.take_away || false;
          this.current_resto=this.appLicenceManager.currentResto;
          this.isAtSpotEnabled = this.current_resto && this.current_resto.delivery || false;
          this.isTakeAwayEnabled = this.current_resto && this.current_resto.takeAway || false;
        
        this.isClosed = !isOpenTime || isDayOff || false;
        this.basketBehavior.closedNotification(this.isClosed);
        localStorage.setItem("isClosed", JSON.stringify(this.isClosed));
        if (res.zones && res.zones.length) {
          res.zones = res.zones.map(zone => {
            zone.cities = zone.cities.map(city => {
              city.min_order = parseFloat(zone.amount) || 0;
              return city;
            })
            return zone;
          });

          //TODO: Sort min to max amount
        }

        this.zones = res.zones || [];
        this.appLicenceManager.saveParameters(res);
      }
    });
    this._order = this.appOrderService.order;
    if (this._order && this._order.type) {
      this.orderType = this._order.type;
    }
  }

  setOrderType(mValue) {
    this.orderType = mValue;
    if (!this.orderType) {
      return;
    }
    if (this.orderType === OrderTypeEnum.DELIVERY && this.isDeliveryAtPlatform) {
      //TODO: redirect to platform url 'this.urlPlatform'

      return;
    }
    if (!this._order) {
      this.appOrderService.factory(this.orderType);
    } else {
      if (this._order.type !== this.orderType) {
        this.appOrderService.factory(this.orderType);
      } else {
        this.appOrderService.setType(this.orderType);
      }
    }
    if (this.orderType === OrderTypeEnum.DELIVERY && this.zones.length) {
      //Redirect to choose City
      this.showTypeOrders = false;
      this.showZones = true;
      return;
    }

    this.router.navigate(['categories']);
  }

  setCity() {
    if (!this.orderCity && this.orderType === OrderTypeEnum.DELIVERY) {
      return;
    }
    this.appOrderService.setOrderCity(this.orderCity);
    this.router.navigate(['categories']);
  }
  // displayOnSpot(now, timing, value) {
  //   let startTab = timing.start_shift_at_spot !== undefined ? timing.start_shift_at_spot.split(':') : timing.start_shift.split(':');
  //   let endTab = timing.end_shift_at_spot !== undefined ? timing.end_shift_at_spot.split(':') : timing.end_shift.split(':');
  //   let debut = moment(now).hours(startTab[0]).minutes(startTab[1]).seconds(0)
  //   let fin = null
  //   if (startTab[0] >= endTab[0]) {
  //     fin = moment(debut).add(1, 'days')
  //   }
  //   else { fin = moment(debut) }
  //   fin.hours(endTab[0]).minutes(endTab[1]).seconds(59)
  //   if (now.isAfter(debut) && now.isBefore(fin) && value) {
  //     this.isAtSpotEnabled = true
  //   }
  // }
  // displayTakeAway(now, timing, value) {
  //   let startTab = timing.start_shift_take_away !== undefined ? timing.start_shift_take_away.split(':') : timing.start_shift.split(':');
  //   let endTab = timing.end_shift_take_away !== undefined ? timing.end_shift_take_away.split(':') : timing.end_shift.split(':');
  //   let debut = moment(now).hours(startTab[0]).minutes(startTab[1]).seconds(0)
  //   let fin = null
  //   if (startTab[0] >= endTab[0]) {
  //     fin = moment(debut).add(1, 'days')
  //   }
  //   else { fin = moment(debut) }
  //   fin.hours(endTab[0]).minutes(endTab[1]).seconds(0)
  //   if (now.isAfter(debut) && now.isBefore(fin) && value) {
  //     this.isTakeAwayEnabled = true
  //   }
  // }
  // displayDelivery(now, timing, value1, value2) {
  //   let startTab = timing.start_shift_delivery !== undefined ? timing.start_shift_delivery.split(':') : timing.start_shift.split(':');
  //   let endTab = timing.start_shift_delivery !== undefined ? timing.start_shift_delivery.split(':') : timing.end_shift.split(':');
  //   let debut = moment(now).hours(startTab[0]).minutes(startTab[1]).seconds(0)
  //   let fin = null
  //   if (startTab[0] >= endTab[0]) {
  //     fin = moment(debut).add(1, 'days')
  //   }
  //   else { fin = moment(debut) }
  //   fin.hours(endTab[0]).minutes(endTab[1]).seconds(59)
  //   if (now.isAfter(debut) && now.isBefore(fin) && value1 && value2) {
  //     this.isDeliveryEnabled = true
  //   }
  // }
}
