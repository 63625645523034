import { Component, OnInit } from '@angular/core';
import { AppUserService } from 'src/app/managers/app-user.service';
import { UserService } from 'src/app/services/user.service';
import { AppSnackBarService } from 'src/app/managers/app-snackbar.service';
import { Router } from "@angular/router";

@Component({
    selector: 'app-account-profil',
    templateUrl: './account-profil.component.html',
    styleUrls: ['./account-profil.component.scss']
})
export class AccountProfilComponent implements OnInit {

    userData: any = {};
    userPassword: any = {};
    view = 'profile';
    passwordError = null;
    constructor(
        private router: Router,
        private appUserService: AppUserService,
        private appSnackBarService: AppSnackBarService,
        private userService: UserService) {

    }
    ngOnInit(): void {
        this.userService.getUserNew(this.appUserService.user.id).subscribe(res => {
            this.userData = res;
            this.userData.phone = this.userData.login;
        }, _ => {
        });
    }

    save() {
        const mData = {
            email: this.userData.email,
            login: this.userData.phone,
            phone: this.userData.phone,
            first_name: this.userData.first_name,
            last_name: this.userData.last_name,
            neofid:this.userData.neofid
        };
        if (mData.first_name && mData.last_name) {
            this.userService.updateUserNew(this.appUserService.user.id, mData).subscribe(res => {
                this.appSnackBarService.handle({ data: "Votre profil a été bien mis à jour" });
                const oldUser = this.appUserService.user;
                const newUser = Object.assign({}, oldUser, mData);
                this.appUserService.setUser(newUser);
            }, _ => {
            })
        }
    }

    delete() {
        if (confirm("êtes-vous sûr de vouloir supprimer votre compte?")) {
          this.router.navigate(["/"]);
        }
      }

      goToCategories(){
        this.router.navigate(['/categories'])
    }

    showViewPassword() {
        this.view = 'password';
        this.userPassword = {
            oldPassword: null,
            newPassword: null
        };
        this.passwordError = null;
        // this.view = 'profile';
    }

    cancelPassword() {
        this.view = 'profile';
    }

    savePassword() {
        this.passwordError = null;
        if (!this.userPassword.oldPassword || !this.userPassword.newPassword) {
            this.passwordError = "Merci de saisir tous les champs.";
            return;
        }
        if (this.userPassword.newPassword.length < 6) {
            this.passwordError = "Le nouveau mot de passe doit être avoir au moins 6 caractères.";
            return;
        }
        if (this.userPassword.oldPassword == this.userPassword.newPassword) {
            this.passwordError = "Le nouveau mot de passe doit être différent de l'ancien.";
            return;
        }
        this.userService.updatePasswordNew(this.appUserService.user.id, this.userPassword).subscribe(res => {
            if (typeof res === 'string') {
                this.passwordError = res;
            } else {
                this.appSnackBarService.handle({ data: "Votre mot de passe a été bien mis à jour." });
                this.view = 'profile';
            }
        }, _ => {
            this.passwordError = "Une erreur se produite, veuillez réessayer.";
        });
    }

}
