import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpClient: HttpClient) { }
  private apiUrl = `${environment.apiUrl}`;
  public getClients(franchiseUUID: any) {
    return this.httpClient.post(`${this.apiUrl}/franchise/usersByFranchise`, { franchiseUUID });
  }

  public deleteClient(userId: any) {
    return this.httpClient.delete(`${this.apiUrl}/franchise/users/` + userId);
  }
}
