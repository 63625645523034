import { Component, OnInit } from "@angular/core";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { CategorieService } from "src/app/services/categorie.service";
import { AppImageRosolver } from "src/app/managers/app-image-resolver.service";
import { AppOrderService } from "src/app/managers/app-order.service";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { BasketBehaviorService } from "src/app/services/basket-behavior.service";
import { filigrane } from "../../../environments/environment";
import { phone } from "../../../environments/environment";
import { AppUserService } from "src/app/managers/app-user.service";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { ProductService } from "src/app/services/product.service";
declare var require: any;
const moment = require("moment-timezone");
declare var $: any;


let mapnamesizes = new Map();
mapnamesizes.set("size1","product_size_4");
mapnamesizes.set("size2","product_size_5");
mapnamesizes.set("size3","product_size_6");
mapnamesizes.set("size4","product_size_7");
mapnamesizes.set("size5","product_size_8");
mapnamesizes.set("size6","product_size_9");
mapnamesizes.set("size7","product_size_10");

@Component({
  selector: "app-categories-list",
  templateUrl: "./categories-list.component.html",
  styleUrls: ["./categories-list.component.scss"],
})
export class CategoriesListComponent implements OnInit {
  filigrane = filigrane;
  phone = phone;
  licenceID: any;
  orderType: any;
  categories: any;
  currentCategory: any;
  currentSubCategory: any;
  hideTitle: boolean = false;
  loading: Boolean = false;
  tab;
  basketOrder = false;
  category_loyalty = false;
  connected = false;
  userData: any;
  modalOption: NgbModalOptions = {};
  formGenderBirthday: FormGroup = null;
  genderbdayError = false;
  currentProduct: any;
  fideliteOpen: any;
  resto_siret: any;
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  avantageImg: string = "";
  showSubcatProduct: Boolean = true;

  // section to get real price product 
  availablePrices: any = [];
  sizeNames: any;
  intialPriceValue: number;
  currency: any;

  category_id: any;


  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
    private appUser: AppUserService,
    private appLicenceManager: AppLicenceManagerService,
    private appImageResolver: AppImageRosolver,
    private appOrderService: AppOrderService,
    private categorieService: CategorieService,
    private basketBehavior: BasketBehaviorService,
    mAppUserService: AppUserService,
    private route: ActivatedRoute,
    private productService: ProductService,
  ) {
    this.licenceID = this.appLicenceManager.licenceId;
    this.category_loyalty = appLicenceManager.parameters.has_fid ? true : false;
    this.orderType = this.appOrderService.order.type;
    this.connected = mAppUserService.isAuthenticated() ? true : false;
    if (this.connected) {
      this.userData = mAppUserService.getUser();
    }

    this.category_id = this.route.snapshot.params.id;
  }

  redirectLogin() {
    this.router.navigate(["authenticatecallcenter"], {
      queryParams: { returnUrl: "categories" },
    });
  }

  ngOnInit() {
    if (!this.licenceID) {
      throw new Error("Licence ID not defined");
    }
    window.scrollTo(0, 0);
    if (this.userData) {
      this.basketBehavior.onCagnotteChange(this.userData.cagnotte);
      this.basketBehavior.cagnotte.subscribe((cagnotte) => {
        this.userData.cagnotte = cagnotte;
      });
    }

    this.basketBehavior.basketOrder.subscribe(
      (basketOrder) => (this.basketOrder = basketOrder)
    );
    const params = this.appLicenceManager.parameters;
    if (params && params.hide_name_products) {
      this.hideTitle = true;
    }
    
    this.loadCategories();
    this.getFidelStatus();
    this.getbackgroundColorOpacity();
    this.getAvantage();
    this.getAvantage();
    this.getCurrency();

    

    this.sizeNames =  this.fetchCurrentProduct();
    console.log(this.sizeNames);
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }
  
  // addVisibleInitial(){
  //   this.currentCategory.sous_categories.map(object => {
  //     return {...object, visible: true};
  //   });
  // }

  showSubcatproductof() {
    this.showSubcatProduct = false;
  }
  

  getAvantage() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectAvantage/angular/${environment.franchiseID}`
      )
      .subscribe((Avantageresponse) => {
        this.avantageImg = Avantageresponse ? Avantageresponse.image_url : null;
      });
  }
  

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
        this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
      });
  }

  getFidelStatus() {
    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/clickandcollect/${this.resto_siret}`
      )
      .subscribe((clickncollectparamresponse) => {
        this.fideliteOpen = clickncollectparamresponse.fidelite_open ? true : false;
      });
  }

  private loadCategories() {
    this.categorieService.getCategories(this.licenceID).subscribe(
      (res) => {
        this.categories = res;
        if (this.fideliteOpen == true) {
          this.categories.unshift({
            name: "Avantages",
            idCat: "FID",
            parent_id: -1,
            path: this.avantageImg,
            khour: this.avantageImg,
          });
        }
        this.appImageResolver.resolveCategories(this.categories);

        this.loading = true;

        if (!this.category_id)
          this.category_id = this.categories[0].idCat;

        this.categorieService
          .getCategory(this.licenceID, this.category_id, this.orderType)
          .subscribe(
            (res) => {
              this.currentCategory = res;
              if (
                this.currentCategory.sous_categories &&
                Array.isArray(this.currentCategory.sous_categories)
              ) {
                this.appImageResolver.resolveCategories(
                  this.currentCategory.sous_categories
                );
                for (
                  let i = 0;
                  i < this.currentCategory.sous_categories.length;
                  i++
                ) {
                  this.appImageResolver.resolveProducts(
                    this.currentCategory.sous_categories[i].products
                  );
                }
              }
              this.appImageResolver.resolveProducts(
                this.currentCategory.products
              );
            },
            (_) => {}
          );
      },
      (_) => {}
    );
  }

  private loadCategories2(id) {
    this.categorieService.getCategories(this.licenceID).subscribe(
      (res) => {
        this.categories = res;
        if (this.appLicenceManager.parameters.has_fid == true) {
          this.categories.unshift({
            name: "Fidelité",
            idCat: "FID",
            parent_id: -1,
          });
        }

        this.categorieService
          .getCategory(this.licenceID, id, this.orderType)
          .subscribe(
            (res) => {
              this.currentCategory = res;
              if (
                this.currentCategory.sous_categories &&
                Array.isArray(this.currentCategory.sous_categories)
              ) {
                this.appImageResolver.resolveCategories(
                  this.currentCategory.sous_categories
                );
                for (
                  let i = 0;
                  i < this.currentCategory.sous_categories.length;
                  i++
                ) {
                  this.appImageResolver.resolveProducts(
                    this.currentCategory.sous_categories[i].products
                  );
                }
              }
              this.appImageResolver.resolveProducts(
                this.currentCategory.products
              );
            },
            (_) => {}
          );
      },
      (_) => {}
    );
  }

  fetchCategory(category) {
    this.currentCategory = null;
    this.categorieService
      .getCategory(this.licenceID, category.idCat, this.orderType)
      .subscribe(
        (res) => {
          this.currentCategory = res;
          this.category_id = this.currentCategory.idCat;
          if (
            this.currentCategory.sous_categories &&
            Array.isArray(this.currentCategory.sous_categories)
          ) {
            this.appImageResolver.resolveCategories(
              this.currentCategory.sous_categories
            );
            for (
              let i = 0;
              i < this.currentCategory.sous_categories.length;
              i++
            ) {
              this.appImageResolver.resolveProducts(
                this.currentCategory.sous_categories[i].products
              );
            }
          }

          if (
            this.currentCategory.products &&
            Array.isArray(this.currentCategory.products)
          ) {
            this.appImageResolver.resolveProducts(
              this.currentCategory.products
            );
          }
        },
        (_) => {}
      );
  }
  fetchProduct(item, content) {
    if (this.currentCategory.idCat === "FID") {
      if (this.connected && this.userData.cagnotte >= item.price_point) {
        if (this.userData.cagnotte >= item.price_point) {
          if (this.userData.birthday && this.userData.gender) {
            localStorage.setItem(
              "restaurant_categories",
              JSON.stringify(this.categories)
            );

            this.router.navigate(["product-details"], {
              queryParams: {
                idItem: item.idItem,
                idCategory: this.currentCategory.idCat,
              }
            });

          } else {
            this.currentProduct = item;
            this.formGenderBirthday = new FormGroup({
              gender: new FormControl("", Validators.required),
              day: new FormControl("", Validators.required),
              month: new FormControl("", Validators.required),
              year: new FormControl("", Validators.required),
            });
            this.modalOption.backdrop = "static";
            this.modalOption.keyboard = false;
            this.modalService.open(content, this.modalOption);
          }
        }
      }
    } else {
      localStorage.setItem(
        "restaurant_categories",
        JSON.stringify(this.categories)
      );

      this.router.navigate(["product-details"], {
        queryParams: {
          idItem: item.idItem,
          idCategory: this.currentCategory.idCat,
        }
      });
    }
  }
  updateUserInfos() {
    let gender = this.formGenderBirthday.get("gender").value;
    let day = this.formGenderBirthday.get("day").value;
    let month = this.formGenderBirthday.get("month").value;
    let year = this.formGenderBirthday.get("year").value;
    let birthdate = moment(`${year}-${month}-${day}`);
    if (birthdate._isValid && gender !== "") {
      this.genderbdayError = false;
      this.userData.birthday = `${day}-${month}-${year}`;
      this.userData.gender = gender;
      this.userService
        .registerUserGenderBirthday(this.userData)
        .subscribe((res) => {
          this.appUser.setUser(this.userData);
          this.modalService.dismissAll("finished");
          localStorage.setItem(
            "restaurant_categories",
            JSON.stringify(this.categories)
          );
          this.router.navigate(["product-details"], {
            queryParams: {
              idItem: this.currentProduct.idItem,
              idCategory: this.currentCategory.idCat,
            }
          });
        });
    } else {
      this.genderbdayError = true;
    }
  }
  openBasket() {
    this.basketBehavior.onOpenBasket(true);
  }
  sortBy(prop: string) {
    const itemsWithNewProperty = this.currentCategory.products.map((item) => {
      // details : different_price - many_size - orderType
      
    this.intialPriceValue = 0;
      this.availablePrices = [];
      if (this.orderType) {
        if (item.many_size) {
          if (item.different_price) {
            this.getEnabledSizesWithPricesAndTypes(item);
        }
        else {
            this.getEnabledSizesWithPrices(item);
        }
        this.intialPriceValue = this.availablePrices[0].price;
        }
        else {
          if (item.different_price) {
            switch (this.orderType) {
              case OrderTypeEnum.ON_THE_SPOT:
                this.intialPriceValue = item.price_sp;
                  break;
              case OrderTypeEnum.TAKE_AWAY:
                this.intialPriceValue = item.price_emp;
                  break;
              case OrderTypeEnum.DELIVERY:
                this.intialPriceValue = item.price_lv;
                  break;
              }
            }
            else {
              this.intialPriceValue = item.price;
            }

        }
      }
      return { ...item, price: this.intialPriceValue };
      // return { ...item, intialPrice: this.intialPriceValue };
  });

    return itemsWithNewProperty.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }
  secondSortBy(listItems: any, prop: string) {
    return listItems.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }


  // section to get real first price product

 
getEnabledSizesWithPrices(item) {

    if (
      this.sizeNames.product_size_1 !== "" &&
      item.size_junior_enabled
    ) {
      this.availablePrices.push({
        label: "product_size_1",
        size: this.sizeNames.product_size_1,
        price: item.price_junior,
      });
    }
    if (
      this.sizeNames.product_size_2 !== "" &&
      item.size_senior_enabled
    ) {
      this.availablePrices.push({
        label: "product_size_2",
        size: this.sizeNames.product_size_2,
        price: item.price_senior,
      });
    }
    if (
      this.sizeNames.product_size_3 !== "" &&
      item.size_mega_enabled
    ) {
      this.availablePrices.push({
        label: "product_size_3",
        size: this.sizeNames.product_size_3,
        price: item.price_mega,
      });
    }
    if (
      item.sizes[0] &&
      item.sizes[0].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[0].name_size),
        size: item.sizes[0].label_size,
        price: item.sizes[0].price,
      });
    }
    if (
      item.sizes[1] &&
      item.sizes[1].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[1].name_size),
        size: item.sizes[1].label_size,
        price: item.sizes[1].price,
      });
    }
    if (
      item.sizes[2] &&
      item.sizes[2].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[2].name_size),
        size: item.sizes[2].label_size,
        price: item.sizes[2].price,
      });
    }
    if (
      item.sizes[3] &&
      item.sizes[3].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[3].name_size),
        size: item.sizes[3].label_size,
        price: item.sizes[3].price,
      });
    }
    if (
      item.sizes[4] &&
      item.sizes[4].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[4].name_size),
        size: item.sizes[4].label_size,
        price: item.sizes[4].price,
      });
    }
    if (
      item.sizes[5] &&
      item.sizes[5].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[5].name_size),
        size: item.sizes[5].label_size,
        price: item.sizes[5].price,
      });
    }
    if (
      item.sizes[6] &&
      item.sizes[6].size_enabled
    ) {
      this.availablePrices.push({
        label: mapnamesizes.get(item.sizes[6].name_size),
        size: item.sizes[6].label_size,
        price: item.sizes[6].price,
      });
    }
  }
  getEnabledSizesWithPricesAndTypes(item) {
    switch (this.orderType) {
      case OrderTypeEnum.ON_THE_SPOT:
        if (
          this.sizeNames.product_size_1 !== "" &&
          item.size_junior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_1",
            size: this.sizeNames.product_size_1,
            price: item.price_sp_junior, 
          });
        }
        if (
          this.sizeNames.product_size_2 !== "" &&
          item.size_senior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_2",
            size: this.sizeNames.product_size_2,
            price: item.price_sp_senior,
          });
        }
        if (
          this.sizeNames.product_size_3 !== "" &&
          item.size_mega_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_3",
            size: this.sizeNames.product_size_3,
            price: item.price_sp_mega,
          });
        }
        if (
          item.sizes[0] &&
          item.sizes[0].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[0].name_size),
            size: item.sizes[0].label_size,
            price: item.sizes[0].price_sp,
          });
        }
        if (
          item.sizes[1] &&
          item.sizes[1].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[1].name_size),
            size: item.sizes[1].label_size,
            price: item.sizes[1].price_sp,
          });
        }
        if (
          item.sizes[2] &&
          item.sizes[2].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[2].name_size),
            size: item.sizes[2].label_size,
            price: item.sizes[2].price_sp,
          });
        }
        if (
          item.sizes[3] &&
          item.sizes[3].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[3].name_size),
            size: item.sizes[3].label_size,
            price: item.sizes[3].price_sp,
          });
        }
        if (
          item.sizes[4] &&
          item.sizes[4].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[4].name_size),
            size: item.sizes[4].label_size,
            price: item.sizes[4].price_sp,
          });
        }
        if (
          item.sizes[5] &&
          item.sizes[5].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[5].name_size),
            size: item.sizes[5].label_size,
            price: item.sizes[5].price_sp,
          });
        }
        if (
          item.sizes[6] &&
          item.sizes[6].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[6].name_size),
            size: item.sizes[6].label_size,
            price: item.sizes[6].price_sp,
          });
        }
        break;
      case OrderTypeEnum.TAKE_AWAY:
        if (
          this.sizeNames.product_size_1 !== "" &&
          item.size_junior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_1",
            size: this.sizeNames.product_size_1,
            price: item.price_emp_junior,
          });
        }
        if (
          this.sizeNames.product_size_2 !== "" &&
          item.size_senior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_2",
            size: this.sizeNames.product_size_2,
            price: item.price_emp_senior,
          });
        }
        if (
          this.sizeNames.product_size_3 !== "" &&
          item.size_mega_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_3",
            size: this.sizeNames.product_size_3,
            price: item.price_emp_mega,
          });
        }
        if (
          item.sizes[0] &&
          item.sizes[0].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[0].name_size),
            size: item.sizes[0].label_size,
            price: item.sizes[0].price_emp,
          });
        }
        if (
          item.sizes[1] &&
          item.sizes[1].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[1].name_size),
            size: item.sizes[1].label_size,
            price: item.sizes[1].price_emp,
          });
        }
        if (
          item.sizes[2] &&
          item.sizes[2].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[2].name_size),
            size: item.sizes[2].label_size,
            price: item.sizes[2].price_emp,
          });
        }
        if (
          item.sizes[3] &&
          item.sizes[3].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[3].name_size),
            size: item.sizes[3].label_size,
            price: item.sizes[3].price_emp,
          });
        }
        if (
          item.sizes[4] &&
          item.sizes[4].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[4].name_size),
            size: item.sizes[4].label_size,
            price: item.sizes[4].price_emp,
          });
        }
        if (
          item.sizes[5] &&
          item.sizes[5].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[5].name_size),
            size: item.sizes[5].label_size,
            price: item.sizes[5].price_emp,
          });
        }
        if (
          item.sizes[6] &&
          item.sizes[6].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[6].name_size),
            size: item.sizes[6].label_size,
            price: item.sizes[6].price_emp,
          });
        }
        break;
      case OrderTypeEnum.DELIVERY:
        if (
          this.sizeNames.product_size_1 !== "" &&
          item.size_junior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_1",
            size: this.sizeNames.product_size_1,
            price: item.price_lv_junior,
          });
        }
        if (
          this.sizeNames.product_size_2 !== "" &&
          item.size_senior_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_2",
            size: this.sizeNames.product_size_2,
            price: item.price_lv_senior,
          });
        }
        if (
          this.sizeNames.product_size_3 !== "" &&
          item.size_mega_enabled
        ) {
          this.availablePrices.push({
            label: "product_size_3",
            size: this.sizeNames.product_size_3,
            price: item.price_lv_mega,
          });
        }
        if (
          item.sizes[0] &&
          item.sizes[0].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[0].name_size),
            size: item.sizes[0].label_size,
            price: item.sizes[0].price_lv,
          });
        }
        if (
          item.sizes[1] &&
          item.sizes[1].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[1].name_size),
            size: item.sizes[1].label_size,
            price: item.sizes[1].price_lv,
          });
        }
        if (
          item.sizes[2] &&
          item.sizes[2].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[2].name_size),
            size: item.sizes[2].label_size,
            price: item.sizes[2].price_lv,
          });
        }
        if (
          item.sizes[3] &&
          item.sizes[3].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[3].name_size),
            size: item.sizes[3].label_size,
            price: item.sizes[3].price_lv,
          });
        }
        if (
          item.sizes[4] &&
          item.sizes[4].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[4].name_size),
            size: item.sizes[4].label_size,
            price: item.sizes[4].price_lv,
          });
        }
        if (
          item.sizes[5] &&
          item.sizes[5].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[5].name_size),
            size: item.sizes[5].label_size,
            price: item.sizes[5].price_lv,
          });
        }
        if (
          item.sizes[6] &&
          item.sizes[6].size_enabled
        ) {
          this.availablePrices.push({
            label: mapnamesizes.get(item.sizes[6].name_size),
            size: item.sizes[6].label_size,
            price: item.sizes[6].price_lv,
          });
        }
        break;
    }
  }


fetchCurrentProduct() {
    return new Promise(async (resolve, reject) => {
        this.categorieService.getSizesName(this.licenceID).subscribe(res => {
            resolve(res);
        }, _ => {
            reject(null);
        });
    })
}

async changeProductQuantity(product: any, qtyOffset: number) {
  let lineIndex = -1;

  for (let i = 0; i < this.appOrderService.order.ticketLines.length; i++) {
    const line = this.appOrderService.order.ticketLines[i];
    if (line.ref_product == product.idItem) {
      lineIndex = i;
      break;
    }
  }

  if (lineIndex != -1) {
    const currentQty = this.appOrderService.order.ticketLines[lineIndex].quantity;

    if (currentQty == 1 && qtyOffset == -1) {
      this.appOrderService.removeLine(null, lineIndex);
    }
    else {
      this.appOrderService.changeLineQuantity(lineIndex, qtyOffset);
    }
  }
  else if (qtyOffset == 1) {
    const currentItem = await this.fetchCurrentProduct2(product.idItem);

    // CALCUL HT PAR GLOBAL PRICE PRODUCT - LES OPTIONS INCLUSES
    const tvaP = currentItem.tax_lv;
    let htP = Math.round(currentItem.price / (1 + tvaP) * 100)/100;
    let tvaAmountP = Math.round((currentItem.price - htP) * 100)/100;

    // DETERMINER DISCOUNT - LABEL DISCOUNT - ISFIDPRODUCT
    const isFidProduct = (currentItem.idCategory === "FID");
    const discountC = isFidProduct ? currentItem.price : 0;
    const label_discountC = isFidProduct ? "Fidelity" : null;

    const currentTicketLine = {
      product: currentItem.name,
      path: currentItem.path,
      imageUrl: currentItem.imageUrl || null,
      size: null,
      items: [],
      productPrice: currentItem.price,
      price_point: currentItem.price_point,
      price: 0,
      priceTotal: currentItem.price,
      priceQTotal: currentItem.price,
      quantity: 1,
      tva: tvaP,
      ht: htP,
      tvaAmount: tvaAmountP,
      supplements: [],
      options: [],
      discount: discountC,
      label_discount: label_discountC,
      basketIndex: this.appOrderService.order.ticketLines.length,
      promotions: currentItem.promotions,
      note: null,
      comment: null,
      place_served: null,
      time_served: null,
      ref_product: currentItem.idItem,
      ref_category: currentItem.idCat,
      isFidProduct: isFidProduct
    };

    this.appOrderService.addLine(currentTicketLine);
  }
}
 
private fetchCurrentProduct2(productId): Promise<any> {
  return new Promise(async (resolve, reject) => {
      this.productService.getProduct(this.licenceID, productId, this.orderType).subscribe(res => {
          resolve(res);
      }, _ => {
          reject(null);
      });
  })
}

}
