import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesListComponent } from './routes/categories-list/categories-list.component';
import { ProductDetailComponent } from './routes/product-detail/product-detail.component';
import { OrderCheckoutComponent } from './routes/order-checkout/order-checkout.component';
import { SecurityComponent } from './routes/security/security.component';
import { SecurityCallCenterComponent } from './routes/security-call-center/security-call-center.component';
import { AppLicenceGuardService } from './security/licence-guard.service';
import { AccountOrdersComponent } from './routes/account/account-orders/account-orders.component';
import { AppOrderGuardService } from './security/order-guard.service';
import { AppAuthGuardService } from './security/auth-guard.service';
import { AppRedirectGuardService, AppRedirectGuardFranchiseService } from './security/redirect-guard.service';
import { AppFormPasswordRecoveryComponent } from './components/app-form-password-recovery/app-form-password-recovery.component';
import { AccountProfilComponent } from './routes/account/profil/account-profil/account-profil.component';
import { AssistanceComponent } from './routes/account/assistance/assistance.component';
import { AccountOrderDetailComponent } from './routes/account/account-order-detail/account-order-detail.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { GlobalSalesComponent } from './routes/global-sales/global-sales.component';
import { AddionalSalesComponent } from './components/addional-sales/addional-sales.component';
import { OrderStatutComponent } from './routes/order-statut/order-statut.component';
import { MultiLayoutComponent } from './layouts/multi-layout/multi-layout.component';
import {OrderTypeComponent } from './routes/order-type/order-type/order-type.component';
import { OrderTypesComponent } from './routes/order-types/order-types.component';
import { FideliteDetailsComponent } from './routes/fidelite-details/fidelite-details.component';
import { AppFormPasswordResetComponent } from './components/app-form-password-reset/app-form-password-reset.component';
import { AppLinkResetComponent } from './components/app-link-reset/app-link-reset.component';
import { RegisterSolutionComponent } from './components/register-solution/register-solution.component';
import { CheckoutResponseComponent } from './routes/checkout-response/checkout-response.component';
import { SpinnerLoaderComponent } from './routes/spinner-loader/spinner-loader.component';
import { StripeCheckoutComponent } from './routes/stripe-checkout/stripe-checkout.component';
import { ClientListComponent } from './routes/client-list/client-list.component';
import { ClientsOrdersComponent } from './routes/clients-orders/clients-orders.component';
import { NouveauClientComponent } from './routes/nouveau-client/nouveau-client.component';
import { CallMultiLayoutComponent } from './layouts/call-multi-layout/call-multi-layout.component';
import { CustomerDetailsComponent } from './routes/customer-details/customer-details.component';

const routes: Routes = [
  {
    path:'call-restos',
   //canActivate:[AppAuthGuardService],
    component:CallMultiLayoutComponent
  },
  {
    path: 'customer-details',
    canActivate: [AppLicenceGuardService],
    component: CustomerDetailsComponent
  },
  {
    path:'',
   //canActivate:[AppAuthGuardService],
    component:MultiLayoutComponent
  },
  {
    path: 'authenticate',
    canActivate: [AppLicenceGuardService, AppRedirectGuardService],
    component: SecurityComponent
  },
  {
    path: 'nouveau-client',
    component: NouveauClientComponent
  },
  {
    path: 'authenticatecallcenter',
    component: SecurityCallCenterComponent
  },
  {
    path: 'list-client',
    canActivate: [AppOrderGuardService],
    component: ClientListComponent
  },
  {
    path: 'clients-orders',
    canActivate: [AppOrderGuardService],
    component: ClientsOrdersComponent
  },
  {
    path: 'reregister',
    canActivate: [AppLicenceGuardService, AppRedirectGuardService],
    component: RegisterSolutionComponent
  },
  {
    path: 'order-types',
    canActivate: [AppLicenceGuardService],
    component: OrderTypesComponent
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        redirectTo :'categories',
        pathMatch:'full'
      },
      {
        path: 'order-type',
        canActivate: [AppLicenceGuardService],
        component: OrderTypeComponent
      },
      {
        path: 'categories',
        canActivate: [AppOrderGuardService],
        component: CategoriesListComponent,
        data: { animation: 'pose0' }
      },
      {
        path: 'categories/:id',
        canActivate: [AppOrderGuardService],
        component: CategoriesListComponent,
        data: { animation: 'pose0' }
      },
      {
        path: 'product-details',
        canActivate: [AppOrderGuardService],
        component: ProductDetailComponent,
        data: { animation: 'pose1' }
      },
      {
        path: 'global-additional',
        canActivate: [AppOrderGuardService],
        component: GlobalSalesComponent
      },
      {
        path: 'additional-sales',
        canActivate: [AppOrderGuardService],
        component: AddionalSalesComponent
      },
      {
        path: 'order-checkout',
        canActivate: [AppLicenceGuardService, AppOrderGuardService, AppAuthGuardService],
        component: OrderCheckoutComponent
      },
      {
        path: 'account-orders',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: AccountOrdersComponent
      },
      {
        path: 'détail-fidelité',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: FideliteDetailsComponent
      },
      {
        path: 'suivi',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: OrderStatutComponent
      },
      {
        path: 'account-order-detail',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: AccountOrderDetailComponent
      },
      {
        path: 'account-profil',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: AccountProfilComponent
      },
      {
        path: 'assistance',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: AssistanceComponent
      },
     
      {
        path: 'account-password-recovery',
        canActivate: [AppLicenceGuardService, AppRedirectGuardService],
        component: AppFormPasswordRecoveryComponent
      },
      {
        path: 'account-password-reset',
        canActivate: [AppLicenceGuardService, AppRedirectGuardService],
        component: AppFormPasswordResetComponent
      },
      {
        path: 'resetLink/:id',
        canActivate: [AppLicenceGuardService, AppRedirectGuardService],
        component: AppLinkResetComponent
      },
      {
        path: 'checkoutresponse',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: CheckoutResponseComponent
      },
      {
        path: 'spinnerloader',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: SpinnerLoaderComponent
      },
      {
        path: 'stripecheckout',
        canActivate: [AppLicenceGuardService, AppAuthGuardService],
        component: StripeCheckoutComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
