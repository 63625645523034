import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrdersClientsService {

  constructor(private httpClient: HttpClient) { }
  private apiUrl = `${environment.apiUrl}`;
  
  public getClients(franchiseUUID: any) {
    return this.httpClient.post(`${this.apiUrl}/franchise/usersByFranchise`, { franchiseUUID });
  }

  public getOrdersByLicenceAndDate(procaisse_licence_id: any, startDay: string, endDay: string) {
    const url = `${this.apiUrl}/ordersByLicenceAndDayClickv3/${procaisse_licence_id}&${startDay}&${endDay}`;
    return this.httpClient.post(url, {}); // Vous devez également inclure un corps vide, car la méthode post s'attend à un corps.
  }

  public getOrdersByLicenceAndClient(procaisse_licence_id: any, client_id: string) {
    const url = `${this.apiUrl}/ordersByLicenceAndClient`;
    return this.httpClient.post(url, {procaisse_licence_id, client_id});
  }
}
