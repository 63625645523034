import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppOrderStatusResolver } from 'src/app/managers/app-order-status-resolver.service';
import { MyOrderService } from 'src/app/services/my-order.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-order-statut',
  templateUrl: './order-statut.component.html',
  styleUrls: ['./order-statut.component.scss']
})
export class OrderStatutComponent implements OnInit {
  @ViewChild('modalOrder', {static: false}) el!: ElementRef;

  order: any ;
  OrderStatus : string;
  displayStyle: string = "none";
  // latitude: any;
  // longitude: any;
  latitude = 44.928251;
  longitude = 4.892005;
  localtionChosen = false;
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  adresseRestaurant: any;
  openAddressRestaurant: any;
  nomRestaurant: any;
  siretRestaurant: any;
  adresseRestaurant2: any;
  nomClient: any;
  prenomClient: any;
  modePaiement: any;
  typeCommande: any;
  DisplayOrderTopDf: boolean = true;
  taxDetailsArray: any;
  totalTaxDetailsObj: any;
  nombreArticle: any;
  genererTicketButton: boolean = false;
  currency: any;
  resto_siret: any;

  constructor( 
    private httpClient: HttpClient,
    private myOrderService: MyOrderService,
    private appOrderStatusResolver: AppOrderStatusResolver) { }

  ngOnInit() {
    window.scrollTo(0,0)
     // 63b416ced7274356242358ee
    // const orderId = "63c5141442402859990a1de5";
    const orderId = localStorage.getItem("order_id");
    this.myOrderService.getOrder(orderId).subscribe(res => {
      this.order = res;
      this.order.style = this.appOrderStatusResolver.resolveStyle(this.order);
    }, (_) => {
    });
    this.getOrderStatus();
    this.chargeMapDataCurrentResto();
    this.getbackgroundColorOpacity();

    setTimeout(() => {
      this.getTvaDetails();
    }, 1000);

    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

  getTvaDetails() {

var itemsbefore = this.order.items;
var itemsafter = [];
itemsbefore.reduce(function(res, value) {
if (!res[value.tva]) {
res[value.tva] = 
{ 
  tva: value.tva, 
  tvaAmount: 0,
  ht: 0,
  priceQTotal: 0
};
itemsafter.push(res[value.tva])
}
res[value.tva].tvaAmount += value.tvaAmount;
res[value.tva].ht += value.ht;
res[value.tva].priceQTotal += value.priceQTotal;

res[value.tva].tvaAmount = Number.parseFloat((res[value.tva].tvaAmount).toFixed(2));
res[value.tva].ht = Number.parseFloat((res[value.tva].ht).toFixed(2));
res[value.tva].priceQTotal = Number.parseFloat((res[value.tva].priceQTotal).toFixed(2));

return res;
}, {});

this.taxDetailsArray = itemsafter;

var TotalTaxDetails = {
  totalTvaAmount : 0,
  totalHt : 0,
  totalTtc : 0
};
    for (var i=0; i < this.taxDetailsArray.length;i++) {
      TotalTaxDetails.totalTvaAmount += this.taxDetailsArray[i].tvaAmount;
      TotalTaxDetails.totalHt += this.taxDetailsArray[i].ht;
      TotalTaxDetails.totalTtc += this.taxDetailsArray[i].priceQTotal;
    }
     
this.totalTaxDetailsObj = TotalTaxDetails;

this.totalTaxDetailsObj.totalTvaAmount = Number.parseFloat((this.totalTaxDetailsObj.totalTvaAmount).toFixed(2));
this.totalTaxDetailsObj.totalHt = Number.parseFloat((this.totalTaxDetailsObj.totalHt).toFixed(2));
this.totalTaxDetailsObj.totalTtc = Number.parseFloat((this.totalTaxDetailsObj.totalTtc).toFixed(2));

this.nombreArticle = this.order.items.length;

// console.log(this.taxDetailsArray);
// console.log(this.totalTaxDetailsObj);
// console.log(this.order.items);




  }

  makePDF() {
    
    this.DisplayOrderTopDf = true;
    let pdf = new jsPDF('p','px', [500, 900]);
    // pdf.text("Hello Biborne Click&collect", 10,10);
    pdf.html(this.el.nativeElement, {
      callback: (pdf) => {
        pdf.save(`Commande-${this.order.num_order}-${this.nomRestaurant}.pdf`);
      }
    });
  }
  

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
        this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
      });
  }

  chargeMapDataCurrentResto(){
    if (localStorage.getItem("CURRENT_RESTO")) {
      // this.latitude = JSON.parse(localStorage["CURRENT_RESTO"]).lat;
      // this.longitude = JSON.parse(localStorage["CURRENT_RESTO"]).lng;

      this.latitude = Math.floor(parseFloat(JSON.parse(localStorage["CURRENT_RESTO"]).lat) * 100) / 100;
      this.longitude = Math.floor(parseFloat(JSON.parse(localStorage["CURRENT_RESTO"]).lng) * 100) / 100;
      this.longitude = Math.floor(parseFloat(JSON.parse(localStorage["CURRENT_RESTO"]).lng) * 100) / 100;
      this.nomRestaurant = JSON.parse(localStorage["CURRENT_RESTO"]).pseudo;
      this.siretRestaurant = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
      this.adresseRestaurant2 = JSON.parse(localStorage["CURRENT_RESTO"]).adresse;
    }
    this.openAddressRestaurant = `https://www.google.com/maps?q=${this.adresseRestaurant}`;
    
    if (localStorage.getItem("userData")) {
      this.nomClient = JSON.parse(localStorage["userData"]).first_name;
      this.prenomClient = JSON.parse(localStorage["userData"]).last_name;
    }
    
    if (localStorage.getItem("myOrder")) {
      this.typeCommande = JSON.parse(localStorage["myOrder"]).type;
    }
    
    if (localStorage.getItem("orderExtraInfo")) {
      this.modePaiement = JSON.parse(localStorage["orderExtraInfo"]).payment_mode;
    }
  }
  
  onChoseLocation(event){
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.localtionChosen = true;
  }

  getOrderStatus() {

    setTimeout(() => { 
      // TYPES STATUS [ sent - cancel - reject | - pending - paid - cooking - ready]
      // this.OrderStatus = "paid";
      this.OrderStatus = this.order.status;
      this.genererTicketButton = this.OrderStatus == 'paid' ? true : false;
     }, 300);
  
  }

  openPopup() {
    this.displayStyle = "block";
    }
    
    closePopup() {
    this.DisplayOrderTopDf = false;
    this.displayStyle = "none";
    }
    

}
