import { environment } from 'src/environments/environment';
import { AppLicenceManagerService } from './app-licence-manager.service.';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export class AppImageRosolver {

    private baseUrl = `${environment.apiUrl}`;
    private defaultImage = '/assets/images/noimage.png';
    private defaultImageOp = '/assets/images/noimageop.png';
    constructor(private appLicenceManager: AppLicenceManagerService, private sanitizer: DomSanitizer) {

    }

    private trustAsStyle(imageUrl) {
        return this.sanitizer.bypassSecurityTrustStyle(`url('${imageUrl}')`)
    }

    resolveCategories(categories): any {
        if (categories && Array.isArray(categories)) {
            categories.forEach(category => {
                category.imageUrl = this.defaultImage;
                if (category.path) {
                    category.imageUrl = `${this.baseUrl}/resourcesNew/${this.appLicenceManager.licenceId}/catagories/${category.path.trim()}`;
                }
                category.imageUrlAsBackground = this.trustAsStyle(category.imageUrl);
            })
        }
        return categories
    }

    resolveProducts(products): any {
        if (products && Array.isArray(products)) {
            products.forEach(product => {
                this.resolveProduct(product);
            });
        }
        return products;
    }

    resolveProduct(product) {
        product.imageUrl = this.defaultImage;
        if (product.path) {
            product.imageUrl = `${this.baseUrl}/resourcesNew/${this.appLicenceManager.licenceId}/products/${product.path.trim()}`
        }
        product.imageUrlAsBackground = this.trustAsStyle(product.imageUrl);
        return product;
    }

    resolveSubOptions(subOptions): any {
        if (subOptions && Array.isArray(subOptions)) {
            subOptions.forEach(optionCategory => {
                if (optionCategory.sub_supplements && Array.isArray(optionCategory.sub_supplements)) {
                    optionCategory.sub_supplements.forEach(optionItem => {
                        optionItem.imageUrl = this.defaultImageOp;
                        if (optionItem.path) {
                            optionItem.imageUrl = `${this.baseUrl}/resourcesNew/${this.appLicenceManager.licenceId}/supplements/${optionItem.path.trim()}`
                        }
                        optionItem.imageUrlAsBackground = this.trustAsStyle(optionItem.imageUrl);
                    })
                }
            });
        }
        return subOptions;
    }
    resolveOptions(options): any {
        if (options && Array.isArray(options)) {
            options.forEach(optionCategory => {
                if (optionCategory.items && Array.isArray(optionCategory.items)) {
                    optionCategory.items.forEach(optionItem => {
                        optionItem.imageUrl = this.defaultImageOp;
                        if (optionItem.path) {
                            optionItem.imageUrl = `${this.baseUrl}/resourcesNew/${this.appLicenceManager.licenceId}/supplements/${optionItem.path.trim()}`
                        }
                        optionItem.imageUrlAsBackground = this.trustAsStyle(optionItem.imageUrl);
                    })
                }
            });
        }
        return options;
    }

    resolveIngredients(ingredients): any {
        if (ingredients && Array.isArray(ingredients)) {
            ingredients.forEach(item => {
                item.imageUrl = this.defaultImageOp;
                if (item.path) {
                    item.imageUrl = `${this.baseUrl}/resourcesNew/${this.appLicenceManager.licenceId}/supplements/${item.path.trim()}`
                }
                item.imageUrlAsBackground = this.trustAsStyle(item.imageUrl);
            });
        }
        return ingredients;
    }
}