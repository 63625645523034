import { Component, OnInit } from '@angular/core';
import { AppUserService } from 'src/app/managers/app-user.service';
import { FideliteService } from 'src/app/services/fidelite.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fidelite-details',
  templateUrl: './fidelite-details.component.html',
  styleUrls: ['./fidelite-details.component.scss']
})
export class FideliteDetailsComponent implements OnInit {
  userData:any;
  franchise_uuid:any;
  transactions:any;
  constructor(private appUserService:AppUserService,
    private fideliteService:FideliteService) { 
    this.userData=appUserService.getUser();
    this.franchise_uuid=environment.franchiseUUID
  }

  ngOnInit() {
    this.getTransactions2();
  }
getTransactions(){
  const formData = new FormData();
  formData.append("franchise_uuid",this.franchise_uuid)
  formData.append("phone",this.userData.phone)
  this.fideliteService.getTransactions(formData).subscribe((res)=>{
    this.transactions=res;
  })
}
getTransactions2(){
  let param= {franchise_uuid:this.franchise_uuid,phone:this.userData.phone}
  this.fideliteService.getTransactions2(param).subscribe((res)=>{
    this.transactions=res;
  })
}
}
