import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { StripeService } from "src/app/services/stripe.service";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Params, Router } from "@angular/router";
 
declare var Stripe: any;
@Component({
  selector: "app-stripe-checkout",
  templateUrl: "./stripe-checkout.component.html",
  styleUrls: ["./stripe-checkout.component.scss"],
})
export class StripeCheckoutComponent implements OnInit {
  constructor(
              private httpClient: HttpClient,
              protected activeModal: NgbActiveModal,
              private appLicenceManager: AppLicenceManagerService,
              private stripeService: StripeService,
              private router: Router) { this.getPublicKeyStripe() }

  @Input() amount: number;

  @ViewChild("payment-element", { static: true }) cardElement: ElementRef;

  stripe: any;
  paymentElement: any;
  idealBank: any;
  cardErrors: any;

  failureStripe: boolean = false;
  failureStripeSpinner: boolean = true;
  submitButton: boolean = false;
  failureStripeCode: string;
  wordSecretClient: any;
  orderTotal: number;
  orderTotalPay: number;
  eamilStripe: string;
  publickeystripe: string;
  resto_siret: any;
  labeltotal: string = "";
  clientSecretGet: string;
  Elements: any;

  async ngOnInit() {
    
    if (localStorage.getItem("totalPaid")) {
      this.orderTotalPay = Math.floor(parseFloat(JSON.parse(localStorage["totalPaid"])) * 100);
    }

    if (localStorage.getItem("userData")) {
      this.labeltotal = JSON.parse(localStorage["userData"]).phone;
    }
    await this.getPublicKeyStripe();

    this.declencheur();

    }

    reloadOrder(){ 
      window.location.reload();
      // this.router.navigate(["order-checkout"]);
    }

    async declencheur () {
      const resuletass = await this.stripeService
      .createpaymentintent(
        this.appLicenceManager.licenceId,this.orderTotalPay)
      .subscribe((createpaymentintentresponse) => {
        for (let [key, value] of Object.entries(createpaymentintentresponse)) {
          // console.log(`${key}: ${value}`);
          this.clientSecretGet = value;
          return this.clientSecretGet;
        };
      });


    setTimeout(() => {

      this.stripe = Stripe(this.publickeystripe);
      
      const clientSecret = this.clientSecretGet;
      const options = {
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {
          theme: 'stripe'
        },
      };

      const elements = this.stripe.elements(options);
      this.paymentElement = elements.create('payment');
      this.paymentElement.mount('#payment-element');
      this.failureStripeSpinner = false;
      this.submitButton = true;
      this.Elements = elements;
      
    }, 1200);
    
    }
  

  async getPublicKeyStripe() {
    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
   this.httpClient
        .get<any>(
          `${environment.backofficeapiUrl}/api/restos/findPublicKeyStripe/${this.appLicenceManager.licenceId}&${this.resto_siret}`
        )
        .subscribe((pksresponse) => {
          this.publickeystripe = pksresponse ? pksresponse.public_key_stripe : null;
        });
        
  }

  // async confirmPayment(clientSecret: any) {
    
  //   const { source, error } = await this.stripe.createSource(this.paymentElement);
  //   const _this = this;
  
  //   const result2 = await this.stripe.confirmCardPayment(clientSecret, {
  //     automatic_payment_methods: { enabled: true }
  //   })
  //   .then(function (result: { error: { code: string; }; paymentIntent: any; }) {
  //     if (result.error) {

  //       console.error(result.error);
  //       _this.failureStripe = true;
  //       _this.failureStripeSpinner = false;
  //       _this.failureStripeCode = result.error.code;
  //       _this.cardErrors = "Une erreur est survenue lors de paiement";
  //     }
  //     else{
  //       console.log(result.paymentIntent);
  //       _this.failureStripeSpinner = false;
  //       _this.failureStripe = true;
  //       _this.activeModal.close(source.id);
  //     }
  //   });
  // }


  async confirmPayment2 () {
    const form = document.getElementById('payment-form');

    const _this = this;

const elements = this.Elements;
  const {error} = await this.stripe.confirmPayment({
    //`Elements` instance that was used to create the Payment Element
    elements,
    confirmParams: {
      // return_url: window.location.origin + '/suivi'
    },
    
   redirect: 'if_required',
  });

  if (error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    // const messageContainer = document.querySelector('#error-message');
    // messageContainer.textContent = error.message;

    console.error(error);
    _this.failureStripe = true;
    _this.failureStripeSpinner = false;
    _this.failureStripeCode = error.code;
    _this.cardErrors = "Une erreur est survenue lors de paiement";
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
          // console.log(result.paymentIntent);
          _this.failureStripeSpinner = false;
          _this.failureStripe = true;
          _this.activeModal.close(this.clientSecretGet);
  }
  }





    


  async handleForm(e: { preventDefault: () => void; }) {
    e.preventDefault();
    this.cardErrors = null;
    this.failureStripeSpinner = true;
    this.failureStripe = false;
    // this.confirmPayment(this.clientSecretGet);
    this.confirmPayment2();
  }

}
