import { Component, OnInit } from "@angular/core";
// import { Client } from '../client.model';
import { OrdersClientsService } from "../../services/orders-clients.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as moment from "moment";

class Client {
  constructor(
    public nom: string,
    public prenom: string,
    public email: string,
    public adresse: string,
    public telephone: string
  ) {}
}

@Component({
  selector: "app-clients-orders",
  templateUrl: "./clients-orders.component.html",
  styleUrls: ["./clients-orders.component.scss"],
})
export class ClientsOrdersComponent implements OnInit {

  searchTerm: string = "";
  selectedDate: string = "";
  customers: any = [];
  currency: any;
  imageLogo: any = "";
  isDisplayed: boolean = false;
  // selectedRestaurant: string = "4204377838092";
  selectedRestaurant: string = "";
  restaurants: any;
  isFetchingOrders: boolean = false;

  numValidOrders: any = 0;
  totalPriceValidOrders: any = 0;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private ordersClientsService: OrdersClientsService
  ) {}


  async ngOnInit(): Promise<void> {
    this.selectedDate = moment().format("YYYY-MM-DD");
    this.getRestaurants();
    this.getCurrency();
    this.getimageLogo();
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse
          ? logoresponse.image_url
          : "../../../assets/images/main-logo.png";
      });
  }

  private fetchUsersFranchise(
    procaisse_licence_id: any,
    startDay: string,
    endDay: string
  ) {
    return new Promise(async (resolve, reject) => {
      this.ordersClientsService
        .getOrdersByLicenceAndDate(procaisse_licence_id, startDay, endDay)
        .subscribe(
          (res: any) => {
            resolve(res.data);
            console.log(res.data);
          },
          (_) => {
            reject(null);
          }
        );
    });
  }

  getCurrency() {
    this.currency = environment.currency;

    // if (localStorage.getItem("CURRENT_RESTO")) {
    // this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    // }
  }

  toggleDisplay(order: any) {
    order.isDisplayed = !order.isDisplayed;
  }

  onFiltersChange() {
    if (!this.selectedRestaurant) {
      return;
    }
  
    this.isFetchingOrders = true;
    const day = moment(this.selectedDate).format("YYYY-MM-DD");
    const nextDay = moment(this.selectedDate)
      .add(1, "days")
      .format("YYYY-MM-DD");
  
    this.fetchUsersFranchise(this.selectedRestaurant, day, nextDay)
      .then((customers) => {
        this.customers = customers;
  
        // Save selectedRestaurant in local storage
        localStorage.setItem('LICENCE_ID', this.selectedRestaurant);
  
        this.isFetchingOrders = false;

        this.numValidOrders = 0;
        this.totalPriceValidOrders = 0;

        for (const order of this.customers) {
          if (order.status !== 'reject' && order.status !== 'cancel') {
            this.numValidOrders++;
            this.totalPriceValidOrders += order.sub_total;
          }
        }
      })
      .catch((error) => {
        // Handle error here
        console.error("Error fetching orders:", error);
        this.isFetchingOrders = false;
      });
  }
  

   getRestaurants() {
    this.httpClient
      .get(`${environment.backofficeapiUrl}/api/franchiseprocaisse/restos/${environment.franchiseID}`)
      .subscribe((response) => {
        this.restaurants = response;        
      });
  }
}
