import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppUserService } from "src/app/managers/app-user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
@Component({
  selector: "app-security-call-center",
  templateUrl: "./security-call-center.component.html",
  styleUrls: ["./security-call-center.component.scss"],
})
export class SecurityCallCenterComponent implements OnInit {
  currentTab = "login";
  returnUrl = null;
  imageLogo: any = "";
  backgroundColorOpacity: string = "";
  constructor(
    private _location: Location,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appUser: AppUserService
  ) {}

  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl || null;

    this.getimageLogo();
    this.getbackgroundColorOpacity();
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse ? logoresponse.image_url : "../../../assets/images/main-logo.png";
      });
  }

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : null;
      });
  }

  setTab(tabName) {
    this.currentTab = tabName;
  }

  success(event) {
    //Redirect
    this.appUser.triggerFranchiseAuth(true);
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
      return;
    }
    this.router.navigate(["/list-client"]);
  }

  goBack() {
    this._location.back();
  }
}
