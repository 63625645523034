import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AppLicenceManagerService {
    private readonly _franchise = 'FRANCHISE';
    private readonly _currentResto = 'CURRENT_RESTO';
    private readonly _storageName = 'LICENCE_ID';
    private readonly _orderParams = 'ORDER_PARAMS';
    private _defaultLicenceId = null;
    private _defaultFranchise:any={};

    constructor() {
        this._defaultLicenceId = environment.franchiseUUID || null;
        this._defaultFranchise.franchiseUUID=environment.franchiseUUID || null
        this._defaultFranchise.franchiseID=environment.franchiseID || null
        this._defaultFranchise.has_fid=environment.has_fid || null
    }

    get franchise(){
        return localStorage.getItem(this._franchise) || this._defaultFranchise;
    }
    private _updateFranchise(mData) {
        localStorage.setItem(this._franchise, mData);
    }
    franchiseFactory(newFranchise) {
        this._updateFranchise(newFranchise);
    }
    saveResto(params) {
        localStorage.setItem(this._currentResto, JSON.stringify(params));
    }
    get currentResto() {
        return JSON.parse(localStorage.getItem(this._currentResto) )|| null;
    }
    private _updateStorage(mData) {
        localStorage.setItem(this._storageName, mData);
    }

    get licenceId() {
        return localStorage.getItem(this._storageName) || this._defaultLicenceId;
    }

    get parameters() {
        const mData = localStorage.getItem(this._orderParams);
        if (mData) {
            return JSON.parse(mData);
        }
        return null;
    }

    clear() {
        localStorage.removeItem(this._storageName);
        localStorage.removeItem(this._orderParams);
        localStorage.removeItem(this._currentResto);
    }

    factory(newLicenceId) {
        this._updateStorage(newLicenceId);
    }

    saveParameters(params) {
        localStorage.setItem(this._orderParams, JSON.stringify(params));
    }
}
