import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FideliteService {
  constructor(private httpClient: HttpClient) { }
  private fideliteURL = `http://217.69.13.134:8080/api`;
  private fideliteURL2=environment.apiUrl
  
  public getTransactions(params) {

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Token cee340aa55e074c65a53b1f91788d287e2e6c058'
  })
};
    return this.httpClient.post(`${this.fideliteURL}/getLoyaltyTransactionsByUserNew`, params,httpOptions);
  }

  public getTransactions2(params){
    return this.httpClient.post(`${this.fideliteURL2}/franchise/users/transactions`, params);
  }
}
