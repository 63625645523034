import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "src/app/services/user.service";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { phoneNumberValidator, textNotEmptyValidator } from '../app-form-register/custom-validators';

@Component({
  selector: "app-form-password-recovery",
  templateUrl: "./app-form-password-recovery.component.html",
  styleUrls: ["./app-form-password-recovery.component.scss"],
})
export class AppFormPasswordRecoveryComponent implements OnInit {
  toggle = null;
  togglePlaceholder = true;

  userData: FormGroup;
  error = null;
  success = false;

  userDatalogin: FormGroup;
  errorlogin = null;
  successlogin = false;
  franchiseUuid : any;
  clickAndCollectUrl : any;
  logoFranchise : any;
  nameFranchise : any;
  buttonClicked : boolean = true;
  mailByPhone : any;
  displayedEmail : any;

  enableDisableBtn() {
    this.toggle = !this.toggle;
    this.togglePlaceholder = !this.togglePlaceholder;
  }

  result: any;
  constructor(
    private httpClient: HttpClient,
    private _location: Location,
    private userService: UserService,
    private router: Router,
    private appLicenceManager: AppLicenceManagerService
  ) {}

  ngOnInit() {
    this.userData = new FormGroup({
      // login: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
    });

    this.userDatalogin = new FormGroup({
      login: new FormControl("", [Validators.required, phoneNumberValidator()]),
    });

    
    this.getFranchiseUuidbyfranchiseId();
  }

  onEmailInputChange(inputValue: string) {
      const cleanedValue = inputValue.replace(/\s/g, '');
      this.userData.get('email').setValue(cleanedValue);
    }
    onPhoneInputChange(inputValue: string) {
        const cleanedValue = inputValue.replace(/\s/g, '');
        this.userDatalogin.get('login').setValue(cleanedValue);
      }

  getFranchiseUuidbyfranchiseId() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/franchiseprocaisse/findFranchiseUuid/${environment.franchiseID}`
      )
      .subscribe((response) => {
        this.franchiseUuid = response ? response.franchise_uuid : null;
        this.clickAndCollectUrl = response ? response.click_and_collect_url : null;
        this.nameFranchise = response ? response.franchise_name : null;
        this.logoFranchise = response ? response.logo : null;
        console.log(this.franchiseUuid);
      });
  }

  send() {
    this.success = false;
    this.error = null;
    if (this.userData.valid) {
      const mData = {
        ...this.userData.value,
        url: `https://${this.clickAndCollectUrl}/resetLink`,
        // franchiseUUID: this.appLicenceManager.franchise.franchiseUUIDEmail,
        franchiseUUID: this.franchiseUuid,
        franchiseName: this.nameFranchise,
        logoFranchise: this.logoFranchise,
      };
      this.userService.forgotPassword2(mData).subscribe(
        (res) => {
          this.success = true;
        },
        (_) => {
          this.error = "Mail inexistant merci de créer votre compte";
          // this.error = "Une erreur s'est produite.";
        }
      );
    }
  }

  sendlogin() {
    this.successlogin = false;
    this.errorlogin = null;
    if (this.userDatalogin.valid) {
      const mDatalogin = {
        ...this.userDatalogin.value,
        url: `https://${this.clickAndCollectUrl}/resetLink`,
        franchiseUUID: this.franchiseUuid,
        franchiseName: this.nameFranchise,
        logoFranchise: this.logoFranchise,
      };
      // this.userService.forgotPasswordloginSms(mDatalogin).subscribe(
        this.userService.forgotPasswordlogin(mDatalogin).subscribe(
          (res: any) => {
            this.mailByPhone = res.email;
            // Supposons que mailByPhone contienne l'adresse e-mail complète, par exemple "layeabalo19@gmail.com"
            const atIndex = this.mailByPhone.indexOf('@');
            const firstPart = this.mailByPhone.slice(0, 4);
            const hiddenPart = '*'.repeat(atIndex - 4);
            const lastPart = this.mailByPhone.slice(atIndex);

            this.displayedEmail = firstPart + hiddenPart + lastPart;

          this.successlogin = true;

          
        },
        (_) => {
          this.errorlogin = "Téléphone inexistant merci de créer votre compte";
          // this.errorlogin = "Une erreur s'est produite.";
        }
      );
    }
    
    this.buttonClicked = false;
  }
  goBack() {
    this._location.back();
  }

  reloadPage(){ 
    window.location.reload();
  }
}
