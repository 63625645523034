import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLicenceManagerService } from '../managers/app-licence-manager.service.';
import { AppUserService } from '../managers/app-user.service';

@Injectable({
    providedIn: 'root'
})
export class AppRedirectGuardService implements CanActivate {


    constructor(
        private router: Router,
        private appLicenceManager: AppLicenceManagerService,
        private appUser: AppUserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.appUser.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['account-orders']);
        return false;
    }
}

export class AppRedirectGuardFranchiseService implements CanActivate {


    constructor(
        private router: Router,
        private appLicenceManager: AppLicenceManagerService,
        private appUser: AppUserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.appUser.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['list-client']);
        return false;
    }
}