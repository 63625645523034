import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppOrderService {

    private readonly _storageName = 'myOrder';
    private readonly _onUpdate = new Subject<any>()
    onUpdate: Observable<any> = this._onUpdate.asObservable()

    constructor() {

    }

    private _updateStorage(mData) {
        mData.lastUpdate = Date.now();
        localStorage.setItem(this._storageName, JSON.stringify(mData));
    }

    get order() {
        let mData = null;
        if (mData = localStorage.getItem(this._storageName)) {
            mData = JSON.parse(mData);
        }
        return mData;
    }

    get sortedOrder() {
        // get the order from storage
        const order = this.order;

        // get the restaurant categories from storage
        const categories = JSON.parse(localStorage.restaurant_categories);

        // sort ticket lines by category position
        order.ticketLines.sort((a, b) => {
            if (a.ref_category !== undefined && b.ref_category !== undefined) {
                const categoryA = categories.find(cat => cat.idCat == a.ref_category);
                const categoryB = categories.find(cat => cat.idCat == b.ref_category);
                
                if (categoryA && categoryB) {
                    return categoryA.position - categoryB.position;
                }
            }
            
            return 0;
        });

        return order;
    }

    removeLine(line: any, index: number): any {
        //TODO : a revoir
        const order = this.order;
        order.ticketLines.forEach(element => {
            if(element.basketIndex>index){
                element.basketIndex-=1;
            }
        });
        let mLine = null;
        if (order && typeof order.ticketLines[index] != undefined) {
            mLine = order.ticketLines[index];
            order.ticketLines.splice(index, 1);
            order.globalPrice -= mLine.priceTotal;
            order.globalQPrice -= mLine.priceQTotal;
            this._updateStorage(order);
            this._onUpdate.next(this.order);
        }
        return mLine;
    }

    addLine(line: any) {
        const order = this.order;
        order.ticketLines.unshift(line);
        order.globalPrice += line.priceTotal;
        order.globalQPrice += line.priceQTotal;
        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }

    replaceLine(lineIndex: any, newLine: any) {
        const order = this.order;

        const currentLine = order.ticketLines[lineIndex];
        order.globalPrice -= currentLine.priceTotal;
        order.globalQPrice -= currentLine.priceQTotal
        
        order.ticketLines[lineIndex] = newLine;

        order.globalPrice += newLine.priceTotal;
        order.globalQPrice += newLine.priceQTotal;
        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }

    changeLineQuantity(lineIndex: any, qtyOffset: number) {
        const order = this.order;
        const line = order.ticketLines[lineIndex];
        
        line.quantity += qtyOffset;
        line.priceQTotal += line.priceTotal * qtyOffset;

        order.globalPrice += line.priceTotal * qtyOffset;
        order.globalQPrice += line.priceTotal * qtyOffset;

        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }

    clear() {
        localStorage.removeItem(this._storageName);
        this._onUpdate.next(this.order);
    }
    clearMulti(type) {
        const orderType=type;
        localStorage.removeItem(this._storageName);
        this.factory(orderType)
        this._onUpdate.next(this.order);
    }
    factory(orderType) {
        const mOrder = {
            type: orderType,
            price: 0.00,
            globalPrice: 0.00,
            globalQPrice: 0.00,
            indexTicketLine: 0,
            orderCity: null,
            ticketLines: []
        }
        this._updateStorage(mOrder);
        this._onUpdate.next(this.order);
        return mOrder;
    }

    setType(orderType) {
        const order = this.order;
        if (!order) {
            throw new Error("La commande n'est pas initialisé");
        }
        order.type = orderType;
        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }

    setOrderCity(orderCity) {
        const order = this.order;
        if (!order) {
            throw new Error("La commande n'est pas initialisé");
        }
        order.orderCity = orderCity;
        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }
    updateBasketPromo(order){
        this._updateStorage(order);
        this._onUpdate.next(this.order);
    }
}
