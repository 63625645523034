import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUserService } from 'src/app/managers/app-user.service';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';

@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss']
})
export class AccountSidebarComponent implements OnInit {
  menuItem: any[];

  constructor(private router: Router,
    private appUser: AppUserService,
    private appOrder: AppOrderService,
    private appLicenceManager: AppLicenceManagerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const path = this.route.snapshot.routeConfig.path;
    this.initMenu(path)
  }
  initMenu(path) {
    this.menuItem = [
      { title: "accueil", active: false, route: '', isLogout: false },
      { title: "mes commandes", active: false, route: 'account-orders', isLogout: false },
      { title: "mon compte", active: false, route: 'account-profil', isLogout: false },
      { title: "assistance", active: false, route: 'assistance', isLogout: false },
      { title: "déconnexion", active: false, route: '', isLogout: true }
    ];
    this.menuItem.forEach(v => {
      if (v.route === path) {
        v.active = true;
      }
    })
  }
  navOnTap(item) {
    if(item.title=="accueil"){
      this.router.navigate(['']);
    }
    if (item.isLogout) {
      this.appUser.logoutUser();
      this.appOrder.clearMulti(this.appOrder.order.type);
      this.router.navigate(['/']);
    } else if (item.route) {
      this.router.navigate([item.route]);
    }
  }
}
