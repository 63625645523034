import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/my-order';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { AppImageRosolver } from 'src/app/managers/app-image-resolver.service';
import { filigrane } from 'src/environments/environment';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-order-recap',
  templateUrl: './order-recap.component.html',
  styleUrls: ['./order-recap.component.scss']
})
export class OrderRecapComponent implements OnInit {
  filigrane=filigrane;
  currentOrder: Order = null;
  isOrderUpdate = false;
  countLines: any;
  active = false;
  currency: any;
  resto_siret: any;


  constructor(
    private httpClient: HttpClient,
    private appImageResolver: AppImageRosolver,
    private appOrder: AppOrderService) {

  }
  initBasket() {
    let timeout = null;
    this.countLines = this.appOrder.order && this.appOrder.order.ticketLines.length || 0;
    this.appOrder.onUpdate.subscribe(newOrder => {
      this.countLines = newOrder && newOrder.ticketLines.length || 0;

      this.isOrderUpdate = true;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.isOrderUpdate = false;
      }, 5000);

    });
  }


  ngOnInit(): void {
    this.initBasket();
    this.currentOrder = this.appOrder.sortedOrder;
    if (this.currentOrder && this.currentOrder.ticketLines && this.currentOrder.ticketLines.length) {
      this.appImageResolver.resolveProducts(this.currentOrder.ticketLines);
    }
    this.appOrder.onUpdate.subscribe(newOrder => {
      this.currentOrder = newOrder;
      if (this.currentOrder && this.currentOrder.ticketLines && this.currentOrder.ticketLines.length) {
        this.appImageResolver.resolveProducts(this.currentOrder.ticketLines);
      }
    });
    // Event on change in order
    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

  closeBasket() {
    if (this.active) this.active = false;
    else this.active = true;
    /*$(".sidebar").
    animate({width: 'toggle'}, 200)*/

  }

  removeLine(line, index, event): boolean {
    event.stopPropagation();
    this.appOrder.removeLine(line, index);
    return false;
  }
}
