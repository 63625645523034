import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppImageRosolver } from 'src/app/managers/app-image-resolver.service';
import { Router } from '@angular/router';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { AppSnackBarService } from 'src/app/managers/app-snackbar.service';
import { filigrane } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-addional-sales',
  templateUrl: './addional-sales.component.html',
  styleUrls: ['./addional-sales.component.scss']
})
export class AddionalSalesComponent implements OnInit {
  filigrane=filigrane;
  products_id: any;
  licenceID: any;
  products: any;
  currentLoad: Boolean = false;
  optionsView: Boolean = false;
  selectedItemId: any;
  terminer: Boolean = false;
  hideTitle: boolean = false;
  orderType:any;
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  currency: any;
  resto_siret: any;

  constructor(
    private httpClient: HttpClient,
    private appLicenceManager: AppLicenceManagerService,
    private appOrderService: AppOrderService,
    private appImageResolver: AppImageRosolver,
    private productService: ProductService,
    private appSnackBarService: AppSnackBarService,
    private router: Router) {
    this.licenceID = this.appLicenceManager.licenceId;
    this.orderType=this.appOrderService.order.type;
  }

  ngOnInit() {
    window.scrollTo(0,0)
    this.products_id = JSON.parse(localStorage.additionalSales);
    const params = this.appLicenceManager.parameters
    if (params) {
      this.hideTitle = params.hide_name_products || false;
    }
    this.productService.getProductsByListId(this.licenceID, this.products_id,this.orderType)
      .subscribe((res) => {
        this.products = res;
        this.appImageResolver.resolveProducts(this.products)
        this.currentLoad = true;
      });
      this.getbackgroundColorOpacity();
      this.getCurrency();
    }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }
    
  
    getbackgroundColorOpacity() {
      this.httpClient
        .get<any>(
          `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
        )
        .subscribe((backgroundColorOpacityresponse) => {
          this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
          this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
        });
    }
    
  cancelAdditional() {
    this.router.navigate(['categories']);
  }
  closeOptionView($event) {
    this.optionsView = false
    this.terminer = true;
  }
  fetchProduct(item) {

    if (item.is_menu || item.many_size || item.options.length || item.promotions.length) {
      this.optionsView = true;
      this.selectedItemId = item.idItem;
    }
    else {
      let itemPrice = 0;
      if (item.different_price) {
        switch (this.appOrderService.order.type) {
          case OrderTypeEnum.ON_THE_SPOT:
            itemPrice = item.price_sp;
            break;
          case OrderTypeEnum.TAKE_AWAY:
            itemPrice = item.price_emp;
            break;
          case OrderTypeEnum.DELIVERY:
            itemPrice = item.price_lv;
            break;
          //Add more types
        }
      }
      else {
        itemPrice = item.price
      }
      const mTicketLine = {
        product: item.name,
        imageUrl: item.imageUrl || null,
        path: item.path,
        items:[],
        size: null,
        productPrice: itemPrice,
        price: 0,
        priceTotal: itemPrice,
        priceQTotal: itemPrice,
        quantity: 1,
        supplements: [],
        options: [],
        discount: 0.0,
        frais_livraison: 0.0,
        label_discount: null,
        basketIndex: this.appOrderService.order.ticketLines.length,
        promotions: item.promotions,
        note: null,
        place_served: null,
        time_served: null,
        ref_product: item.idItem,
        ref_category: item.idCat,
      };
      this.appOrderService.addLine(mTicketLine);
      this.terminer = true;
      this.appSnackBarService.handle({ data: "Produit ajouté, un autre produit additionel?" });
    }
  }
//   checkPromotions(ticketline, order) {
//     let promo_found = false;
//     ticketline.promotions.forEach(promotion => {
//         if ((promotion.type_order == order.type || promotion.type_order == undefined)
//             && (promotion.size_product == undefined || promotion.size_product == ticketline.size)) {
//             promo_found = true;
//             ticketline.promo_id = promotion.id;
//             let products_in_promo = []
//             var totalQuantity = 0;
//             order.ticketLines.forEach(element => {
//                 if (promotion.id == element.promo_id) {
//                     products_in_promo.push(element)
//                     totalQuantity += element.quantity;
//                 }
//             });
//             products_in_promo.push(ticketline)
//             totalQuantity += ticketline.quantity;
//             if (totalQuantity >= promotion.quantity) {
//                 this.recountPromotion(order, promotion, totalQuantity, products_in_promo);
//             }
//             else {
//                 this.appOrderService.addLine(ticketline);
//             }
//         }

//     });
//     if (!promo_found) {
//         this.appOrderService.addLine(ticketline);
//     }
// }
// recountPromotion(order, promo, quantity, tab) {
//     tab = tab.sort(function (a, b) {
//         return a.productPrice - b.productPrice
//     })
//     var promo_number = Math.trunc(quantity / promo.quantity)
//     tab.forEach(element => {
//         if (promo_number == 0) {
//             element.discount = 0;
//             element.label_discount = "";
//             element.priceQTotal = element.priceTotal * element.quantity;
//         }
//         else {
//             let price = 0
//             if (element.quantity <= promo_number) {
//                 price = (promo.discount / 100) * element.productPrice * element.quantity;
//                 promo_number -= element.quantity;

//             }
//             else if (element.quantity > promo_number) {
//                 price = (promo.discount / 100) * element.productPrice * promo_number;
//                 promo_number = 0;
//             }
//             element.discount = price;
//             element.label_discount = promo.name_promotion;
//             element.priceQTotal = (element.priceTotal * element.quantity) - price;
//         }
//         let index = order.ticketLines.findIndex((t) => t.basketIndex == element.basketIndex)
//         if (index === -1) { order.ticketLines.push(element) }
//         else if (index !== -1) {
//             order.ticketLines[index] = element
//         }
//     });
//     this.recountBasketPrices(order)
// }
// recountBasketPrices(order) {
//     var globalPrice = 0;
//     var globalQPrice = 0;
//     order.ticketLines.forEach(line => {
//         globalPrice += line.priceTotal;
//         globalQPrice += line.priceQTotal;
//     });
//     order.globalPrice = globalPrice;
//     order.globalQPrice = globalQPrice
//     this.appOrderService.updateBasketPromo(order)
// }
}
