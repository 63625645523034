import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppUserService {

    private readonly _storageKey = 'userData';
    private readonly _storageFranchiseKey = 'userFranchiseData';
    private readonly _storageNewCustomerPhone = 'userNewPhone';
    private readonly _onAuth = new Subject<any>()
    onAuth: Observable<any> = this._onAuth.asObservable()

    constructor(private router: Router) { }

    private findUser() {
        return localStorage.getItem(this._storageKey)
    }

    private findFranchiseUser() {
        return localStorage.getItem(this._storageFranchiseKey)
    }

    private findPhoneUser() {
        return localStorage.getItem(this._storageNewCustomerPhone)
    }

    isAuthenticated(): Boolean {
        return this.findUser() ? true : false;
    }

    isFranchiseAuthenticated(): Boolean {
        return this.findFranchiseUser() ? true : false;
    }

    triggerFranchiseAuth(isFranchiseAuthenticated = false) {
        this._onAuth.next(isFranchiseAuthenticated);
    }

    triggerAuth(isAuthenticated = false) {
        this._onAuth.next(isAuthenticated);
    }

    setUser(mData: any) {
        localStorage.setItem(this._storageKey, JSON.stringify(mData));
    }

    setFranchiseUser(mData: any) {
        localStorage.setItem(this._storageFranchiseKey, JSON.stringify(mData));
    }

    setNewCustomerPhone (mData: any) {
        localStorage.setItem(this._storageNewCustomerPhone, JSON.stringify(mData));
    }

    get user() {
        return this.getUser();
    }

    getUser() {
        const mData = this.findUser();
        if (!mData) {
            return null;
        }
        return JSON.parse(mData);
    }

    getFranchiseUser() {
        const mData = this.findFranchiseUser();
        if (!mData) {
            return null;
        }
        return JSON.parse(mData);
    }

    getPhoneUser() {
        const mData = this.findPhoneUser();
        if (!mData) {
            return null;
        }
        return JSON.parse(mData);
    }

    logoutUser() {
        localStorage.removeItem(this._storageKey);
    }

    logoutFranchiseUser() {
        localStorage.removeItem(this._storageFranchiseKey);
    }
}