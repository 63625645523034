import { Component, OnInit, HostListener } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AppOrderService } from "src/app/managers/app-order.service";
import { MyOrderService } from "src/app/services/my-order.service";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { OrderTypeEnum } from "src/app/enums/order-type";
import { AppUserService } from "src/app/managers/app-user.service";
import { WebsocketService } from "src/app/services/websocket.service";
import { StripeService } from "src/app/services/stripe.service";
import { UserService } from "src/app/services/user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { EventEmitter } from "protractor";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StripeCheckoutComponent } from "../stripe-checkout/stripe-checkout.component";
enum PAYMENT_MDOES {
  CASH = "CASH",
  TICKET_RESTO = "TICKECT_RESTO",
  CB = "CB",
  ONLINE = "ONLINE",
}

enum TIMING_TYPE {
  LATER = "LATER",
  NOW = "NOW",
}
declare var require: any;
const moment = require("moment-timezone");
@Component({
  selector: "app-order-checkout",
  templateUrl: "./order-checkout.component.html",
  styleUrls: ["./order-checkout.component.scss"],
})
export class OrderCheckoutComponent implements OnInit {
  orderDateTime: any;
  AVAILABLE_PAYMENT_MDOES = PAYMENT_MDOES;
  AVAILABLE_TIMING_TYPE = TIMING_TYPE;
  userData: any = null;
  orderExtraInfo = {
    comment: null,
    when: null,
    payment_mode: null,
    payment_token: null,
  };
  order: any;
  formExtra: FormGroup = null;
  formExtraselect: FormGroup = null;
  formDelivery: FormGroup = null;
  success: Boolean = false;
  isOrderDelivery = false;
  disableCity = false;
  mData: any;
  handler: any;
  isoWeekDay = moment().isoWeekday();
  isDayOff = false;
  daysShift = [];
  startshift: any;
  startshiftClick: any;
  endshift: any;
  startshift2: any;
  startshiftClick2: any;
  endshift2: any;
  startshiftshow: any = "-";
  endshiftshow: any = "-";
  timesShift: any = [];
  disableMin = false;
  disableMax = false;
  baseTime = moment();
  baseOrderTime = moment();
  basecheckTime = moment().format("HH:MM");
  basecheckTimeOg = moment().locale("fr").add(1, "minutes").format("LT");
  BASE_TIME_VALUE = 15;
  timer_value = null;
  cantPassOrder = false;
  currentShift: any;
  sendingOrder = false;
  /* timers*/
  initialValueSP: number;
  initialValueEMP: number;
  initialValueLV: number;
  stepSP: number;
  stepEmp: number;
  stepLV: number;
  payments_type: any;
  has_fid = false;
  fid_selected = false;
  current_cagnotte = 0;
  hourvalue: string = "";
  codepromovalue: string;
  codepromoprice: number = 0;
  totalapayer: number;
  montant_a_deduire: number;
  resto_siret: any;
  codepromoisValid = false;
  codepromoisnoValid = false;
  codepromoOneValid = false;
  config_cash : boolean = false;
  config_online : boolean = false;
  config_cashResto : boolean = false;
  config_onlineResto : boolean = false;
  config_cashFranchise : boolean = false;
  config_onlineFranchise : boolean = false;
  config_ticketResto : boolean = false;
  config_cb = false;
  backRedStatus = false;
  UserpromoReductionPremiereCommande = false;
  backRedAmount: number = 0;
  backRedAmountCost: number = 0;
  backFraisStatus = false;
  backFraisAmount: number = 0;
  address: any;
  deliveryCostsStatus = false;
  deliveryCostsAmount: number = 0;
  clickStep: number = 0;
  failureStripe: boolean = false;
  failureStripeSpinner: boolean = false;
  dateToday : number;
  NumberGettingWorkshiftDay : number = 0;
  CountOrders : any;
  CountOrders2 : any;
  clickstepatspotv: number = 0;
  clicksteptakeawayv: number = 0;
  clickstepdeliveryv: number = 0;
  clickstepitemv: number = 0;
  clickstepValue: number = 0;
  currency: any;

  constructor(
    private httpClient: HttpClient,
    private appOrderService: AppOrderService,
    private myOrderService: MyOrderService,
    private appLicenceManager: AppLicenceManagerService,
    private websocketService: WebsocketService,
    private stripeService: StripeService,
    private userService: UserService,
    private router: Router,
    private appUser: AppUserService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.userData = this.appUser.getUser();
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.order = this.appOrderService.sortedOrder;
    if (!this.checkLines()) {
      return;
    }
    const mDay = await this.findDayShift();
    this.isDayOff = (mDay && mDay.dayOff) || false;
    this.daysShift =
      mDay && mDay.workShifts && mDay.workShifts.length ? mDay.workShifts : [];
    this.startshift = this.startshift ? this.startshift : "00:00";
    this.endshift = this.endshift ? this.endshift : "00:00";
    this.startshift2 = this.startshift2 ? this.startshift2 : "00:00";
    this.endshift2 = this.endshift2 ? this.endshift2 : "00:00";

    this.appOrderService.onUpdate.subscribe((newOrder) => {
      this.order = newOrder;
    });
    this.orderExtraInfo.when = this.AVAILABLE_TIMING_TYPE.LATER;
    this.formExtra = new FormGroup({
      day: new FormControl(""),
      hours: new FormControl(""),
      minutes: new FormControl(""),
    });
    this.formExtraselect = new FormGroup({
      day: new FormControl(""),
      time: new FormControl(""),
    });

    this.formDelivery = new FormGroup({
      city: new FormControl("", [Validators.required]),
      address: new FormControl("", [Validators.required]),
      zipCode: new FormControl("", [Validators.required]),
      building: new FormControl(""),
      stairs: new FormControl(""),
      entryCode: new FormControl(""),
      level: new FormControl(""),
      flatNumber: new FormControl(""),
      intercom: new FormControl(""),
      extra: new FormControl(""),
    });
    this.initOrderTime();
    if (this.order.type === OrderTypeEnum.DELIVERY) {
      this.isOrderDelivery = true;
      if (this.order.orderCity) {
        this.disableCity = true;
        this.formDelivery.controls["city"].setValue(this.order.orderCity.city);
        this.formDelivery.controls["zipCode"].setValue(this.order.orderCity.zip_code);
      }
      const formDeliveryValue = JSON.parse(
        localStorage.getItem("formDelivery")
      );
        this.formDelivery.controls["city"].setValue(formDeliveryValue.city);
        this.formDelivery.controls["zipCode"].setValue(formDeliveryValue.zipCode);
        this.formDelivery.controls["address"].setValue(formDeliveryValue.address);
        this.formDelivery.controls["building"].setValue(formDeliveryValue.building);
        this.formDelivery.controls["stairs"].setValue(formDeliveryValue.stairs);
        this.formDelivery.controls["entryCode"].setValue(formDeliveryValue.entryCode);
        this.formDelivery.controls["level"].setValue(formDeliveryValue.level);
        this.formDelivery.controls["flatNumber"].setValue(formDeliveryValue.flatNumber);
        this.formDelivery.controls["intercom"].setValue(formDeliveryValue.intercom);
        this.formDelivery.controls["extra"].setValue(formDeliveryValue.extra);
    }
    setTimeout(() => {
    this.getClickStep();
    this.gettingtimes();
  }, 300);
  
    this.getBackofficeModePaiement();
    this.getstatusdeliverycost();
    this.getBackofficeStepclick();

    let element: HTMLElement = document.getElementById(
      "auto_trigger_cb"
    ) as HTMLElement;
    element.click();

    
    this.totalapayer =
      parseFloat(this.order.globalQPrice) +
      this.backFraisAmount +
      this.deliveryCostsAmount -
      this.codepromoprice -
      this.backRedAmountCost;

      localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params.source,
        proceedPayment = params.proceedPayment;
      if (proceedPayment) {
        if (!token) {
          this.failureStripe = true;
        } else {
          this.orderExtraInfo = localStorage.getItem("orderExtraInfo") !== null ? JSON.parse(localStorage.getItem("orderExtraInfo")) : this.orderExtraInfo;
          this.orderExtraInfo.payment_token = token;
          const formDeliveryValue = JSON.parse(
            localStorage.getItem("formDelivery")
          );
          const formExtraValue = JSON.parse(localStorage.getItem("formExtra"));
          const formExtraselectValue = JSON.parse(
            localStorage.getItem("formExtraselect")
          );
          if (formDeliveryValue) {
            this.formDelivery.patchValue(formDeliveryValue,  { emitEvent: false, onlySelf: true });
          }
          if(formExtraValue) {
            this.formExtra.patchValue(formExtraValue, { emitEvent: false, onlySelf: true })
          }
          if(formExtraselectValue) {
            this.formExtraselect.patchValue(formExtraselectValue, { emitEvent: false, onlySelf: true });
          }
          if (
            !this.orderExtraInfo.when ||
            !this.orderExtraInfo.payment_mode ||
            !this.userData ||
            !this.appLicenceManager.licenceId ||
            !this.checkLines()
          ) {
            return;
          }
          this.proceedSubmitOrder();
        }
      }
    });

    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

  getBackofficeStepclick() {

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/${this.resto_siret}`
      )
      .subscribe((clickstepresponse) => {
        this.clickstepatspotv = clickstepresponse ? clickstepresponse.clickstepatspot : 20;
        this.clicksteptakeawayv = clickstepresponse ? clickstepresponse.clicksteptakeaway : 20;
        this.clickstepdeliveryv = clickstepresponse ? clickstepresponse.clickstepdelivery : 40;
        this.clickstepitemv = clickstepresponse ? clickstepresponse.clickstepitem : 10;
        this.config_cashResto = clickstepresponse.CASH ? true : false;
        this.config_onlineResto = clickstepresponse.ONLINE ? true : false;
        
      });

     setTimeout(() => {
      
    if (this.order.type === OrderTypeEnum.ON_THE_SPOT) {
      this.clickstepValue = this.clickstepatspotv;
     } 
     else if (this.order.type === OrderTypeEnum.TAKE_AWAY) {
       this.clickstepValue = this.clicksteptakeawayv;
     } 
     else if (this.order.type === OrderTypeEnum.DELIVERY) {
       this.clickstepValue = this.clickstepdeliveryv;
     } 
     else {
       this.clickstepValue = 10;
     }
    //  console.log(this.clickstepValue);
    }, 200);
  }

  getClickStep() {
    if (this.order.type === OrderTypeEnum.ON_THE_SPOT) {
      this.clickStep = this.clickstepatspotv;
      this.basecheckTimeOg = moment()
        .locale("fr")
        .add(this.clickStep, "minutes")
        .format("LT");
      this.startshiftClick = this.startshift
        ? moment(this.startshift, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
      this.startshiftClick2 = this.startshift2
        ? moment(this.startshift2, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
    } 
    else if (this.order.type === OrderTypeEnum.TAKE_AWAY) {
      this.clickStep = this.clicksteptakeawayv;
      this.basecheckTimeOg = moment()
        .locale("fr")
        .add(this.clickStep, "minutes")
        .format("LT");
      this.startshiftClick = this.startshift
        ? moment(this.startshift, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
      this.startshiftClick2 = this.startshift2
        ? moment(this.startshift2, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
    } 
    else if (this.order.type === OrderTypeEnum.DELIVERY) {
      this.clickStep = this.clickstepdeliveryv;
      this.basecheckTimeOg = moment()
        .locale("fr")
        .add(this.clickStep, "minutes")
        .format("LT");
      this.startshiftClick = this.startshift
        ? moment(this.startshift, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
      this.startshiftClick2 = this.startshift2
        ? moment(this.startshift2, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
    } 
    else {
      this.clickStep = 10;
      this.basecheckTimeOg = moment()
        .locale("fr")
        .add(this.clickStep, "minutes")
        .format("LT");
      this.startshiftClick = this.startshift
        ? moment(this.startshift, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
      this.startshiftClick2 = this.startshift2
        ? moment(this.startshift2, "HH:mm")
            .add(this.clickStep, "minutes")
            .format("HH:mm")
        : "00:00";
    }
  }

  getstatusdeliverycost() {
    this.deliveryCostsStatus =
    this.order.orderCity && this.order.orderCity.deliveryCostOrder >= 0 ? true : false;
    this.deliveryCostsAmount =
      this.deliveryCostsStatus === true
        ? this.order.orderCity.deliveryCostOrder
        : 0;
    this.totalapayer =
      parseFloat(this.order.globalQPrice) +
      this.backFraisAmount +
      this.deliveryCostsAmount -
      this.codepromoprice -
      this.backRedAmountCost;
      
      localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
  }


  async getBackofficeModePaiement() {

    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickncollectParam/published/${environment.franchiseID}`
      )
      .subscribe((clickncollectparamresponse) => {
        this.config_cashFranchise = clickncollectparamresponse.CASH ? true : false;
        this.config_onlineFranchise = clickncollectparamresponse.ONLINE ? true : false;
        this.config_ticketResto = clickncollectparamresponse.TICKECT_RESTO
          ? true
          : false;
        this.config_cb = clickncollectparamresponse.CB ? true : false;
        this.backRedStatus = clickncollectparamresponse.reduction_first_commande
          ? true
          : false;
        this.backRedAmount =
          clickncollectparamresponse.reduction_first_commande_montant
            ? clickncollectparamresponse.reduction_first_commande_montant
            : 0;
        this.backFraisStatus = clickncollectparamresponse.frais_gestion
          ? true
          : false;
        this.backFraisAmount = clickncollectparamresponse.frais_gestion_montant
          ? clickncollectparamresponse.frais_gestion_montant
          : 0;
          
          

        const licenceID = this.appLicenceManager.licenceId;
        if (!licenceID) {
            throw new Error();
        }
        let params = {
            procaisse_licence_id: licenceID,
            client_id: this.userData.id
        }
        this.myOrderService.getOrdersByLicenceAndClient(params).subscribe((res) => {
        this.CountOrders2 = res;
        this.UserpromoReductionPremiereCommande = this.CountOrders2.length > 0 ? false : true;
        
        if (this.UserpromoReductionPremiereCommande) {
              this.userData.promotion = true;
              this.appUser.setUser(this.userData);
        }
          this.backRedAmountCost = clickncollectparamresponse.reduction_first_commande && this.UserpromoReductionPremiereCommande
            ? (this.totalapayer * this.backRedAmount) / 100
            : 0;

            this.totalapayer =
                parseFloat(this.order.globalQPrice) +
                this.backFraisAmount +
                this.deliveryCostsAmount -
                this.codepromoprice -
                this.backRedAmountCost;
      
                localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
    }, (_) => {
    });
          

        if (this.backFraisStatus === true) {
          this.backFraisAmount = this.backFraisAmount;
          this.totalapayer =
            parseFloat(this.order.globalQPrice) +
            this.backFraisAmount +
            this.deliveryCostsAmount -
            this.codepromoprice -
            this.backRedAmountCost;
      
            localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
        } else {
          this.totalapayer =
            parseFloat(this.order.globalQPrice) +
            this.backFraisAmount +
            this.deliveryCostsAmount -
            this.codepromoprice -
            this.backRedAmountCost;
      
            localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
        }
      });

      // PRIORITY RESTO TO FRANCHISE
        // this.config_cash = this.config_cashFranchise ? true : false;
     setTimeout(() => {
                          this.config_cash = this.config_cashResto ? this.config_cashResto 
                          : this.config_cashFranchise ? this.config_cashFranchise 
                          : false;

                          this.config_online = this.config_onlineResto ? this.config_onlineResto 
                          : this.config_onlineFranchise ? this.config_onlineFranchise 
                          : false;

                          if (this.config_cash && !this.config_online) {
                            this.setPaymentMode(PAYMENT_MDOES.CASH);
                          } else if (this.config_online && !this.config_cash) {
                            this.setPaymentMode(PAYMENT_MDOES.ONLINE);
                          }  
                          else {
                            // Aucun mode de paiement n'est activé, vous pouvez gérer cela comme vous le souhaitez.
                          }
                        }, 300);
                        

  }

  applypromo() {
    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/reductions/clickandcollect/${environment.franchiseID}&${this.codepromovalue}&${this.resto_siret}`
      )
      .subscribe((codepromoresponse) => {
        this.codepromoisValid = codepromoresponse.montant ? true : false;
        this.codepromoOneValid = codepromoresponse.montant ? true : false;
        this.codepromoisnoValid = false;

        if (codepromoresponse.type === "espèce") {
          this.codepromoprice = parseFloat(codepromoresponse.montant);
          this.totalapayer =
            parseFloat(this.order.globalQPrice) +
            this.backFraisAmount +
            this.deliveryCostsAmount -
            this.codepromoprice -
            this.backRedAmountCost;
      
            localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
            
        } else if (codepromoresponse.type === "pourcentage") {
          this.codepromoprice = parseFloat(codepromoresponse.montant);
          this.montant_a_deduire =
            (this.codepromoprice * parseFloat(this.order.globalQPrice)) / 100;
          this.codepromoprice = this.montant_a_deduire;
          this.totalapayer =
            parseFloat(this.order.globalQPrice) +
            this.backFraisAmount +
            this.deliveryCostsAmount -
            this.codepromoprice -
            this.backRedAmountCost;
      
            localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
            
        } else {
          this.totalapayer =
            parseFloat(this.order.globalQPrice) +
            this.backFraisAmount +
            this.deliveryCostsAmount -
            this.codepromoprice -
            this.backRedAmountCost;

        localStorage.setItem("totalPaid", JSON.stringify(this.totalapayer));
        
        }
      });

    this.codepromoisnoValid = true;
  }

  alerthour() {
    alert(this.hourvalue);
  }

  gettingtimes() {
    function timeArray(start, end, start2, end2, now, nowOg,clickstepitemvfinal) {
      
      start = start.split(":");
      end = end.split(":");
      start2 = start2.split(":");
      end2 = end2.split(":");
      now = now.split(":");
      nowOg = nowOg.split(":");

      start = parseInt(start[0]) * 60 + parseInt(start[1]);
      end = parseInt(end[0]) * 60 + parseInt(end[1]);
      start2 = parseInt(start2[0]) * 60 + parseInt(start2[1]);
      end2 = parseInt(end2[0]) * 60 + parseInt(end2[1]);
      now = parseInt(now[0]) * 60 + parseInt(now[1]);
      nowOg = parseInt(nowOg[0]) * 60 + parseInt(nowOg[1]);
      clickstepitemvfinal = parseInt(clickstepitemvfinal);

        // Assurez-vous que l'heure de fin est supérieure à l'heure de début
        if (end < start) {
          end += 24 * 60;
        }
        if (end2 < start2) {
          end2 += 24 * 60;
        }
  
      var result = [];
      let clickstepfinal =  clickstepitemvfinal;
      if (nowOg > start && nowOg < end) {
        // result.push("Dès que possible");
        for (let time = nowOg; time <= end; time += clickstepfinal) {
          result.push(timeString(time));
        }
        return result.map(function (time) {
        let [hours, minutes] = time.split(":");
        hours = (parseInt(hours) % 24).toString();
        if (hours.length === 1) hours = "0" + hours;
        return hours + ":" + minutes;
      });
      } else if (start > nowOg && start < end) {
        for (let time = start; time <= end; time += clickstepfinal) {
          result.push(timeString(time));
        }
        return result.map(function (time) {
        let [hours, minutes] = time.split(":");
        hours = (parseInt(hours) % 24).toString();
        if (hours.length === 1) hours = "0" + hours;
        return hours + ":" + minutes;
      });
      } else if (nowOg > start2 && nowOg < end2) {
        // result.push("Dès que possible");
        for (let time = nowOg; time <= end2; time += clickstepfinal) {
          result.push(timeString(time));
        }
        return result.map(function (time) {
        let [hours, minutes] = time.split(":");
        hours = (parseInt(hours) % 24).toString();
        if (hours.length === 1) hours = "0" + hours;
        return hours + ":" + minutes;
      });
      } else if (start2 > end && start2 < end2) {
        for (let time = start2; time <= end2; time += clickstepfinal) {
          result.push(timeString(time));
        }
        return result.map(function (time) {
        let [hours, minutes] = time.split(":");
        hours = (parseInt(hours) % 24).toString();
        if (hours.length === 1) hours = "0" + hours;
        return hours + ":" + minutes;
      });
      } 
        else {
        result.push("Restaurant Fermé");
        return result;
      }
    }

    function timeString(time) {
      var hours: any = Math.floor(time / 60);
      var minutes: any = time % 60;

      if (hours < 10) hours = "0" + hours; //optional
      if (minutes < 10) minutes = "0" + minutes;

      return hours + ":" + minutes;
    }

    const hours = this.basecheckTimeOg.slice(0,2);
    const minutes = parseInt(this.basecheckTimeOg.slice(3,5));
    if (minutes < 15)
      this.basecheckTimeOg = hours + ':00';
    else if (minutes < 30)
      this.basecheckTimeOg = hours + ':15';
    else if (minutes < 45)
      this.basecheckTimeOg = hours + ':30';
    else
      this.basecheckTimeOg = hours + ':45';

    this.timesShift = timeArray(
      this.startshiftClick,
      this.endshift,
      this.startshiftClick2,
      this.endshift2,
      this.basecheckTime,
      this.basecheckTimeOg,
      15 //this.clickstepitemv
    );
  this.hourvalue = this.timesShift[0];


    // this.timesShift = timeArray(
    //   this.startshiftClick,
    //   this.endshift,
    //   this.startshiftClick2,
    //   this.endshift2,
    //   this.basecheckTime,
    //   this.basecheckTimeOg,
    //   this.clickstepitemv
    // );
  }

  private checkLines() {
    if (!this.order || !this.order.ticketLines.length) {
      this.router.navigate(["categories"]);
      return false;
    }
    if (
      this.order &&
      this.order.type === OrderTypeEnum.DELIVERY &&
      this.order.orderCity &&
      this.order.orderCity.min_order &&
      this.order.globalQPrice < this.order.orderCity.min_order
    ) {
      this.router.navigate(["categories"]);
      return false;
    }
    return true;
  }

  private findDayShift(): any {
    return new Promise((resolve, reject) => {
      this.myOrderService
        .fetchOrderParams(this.appLicenceManager.licenceId)
        .subscribe(
          (res) => {

            let a = new Date();
  this.dateToday = a.getDay();
  if(this.dateToday==0){
    this.NumberGettingWorkshiftDay = 6;
  }
  else if(this.dateToday===1){
    this.NumberGettingWorkshiftDay = 0;
  }
  else if(this.dateToday===2){
    this.NumberGettingWorkshiftDay = 1;
  }
  else if(this.dateToday===3){
    this.NumberGettingWorkshiftDay = 2;
  }
  else if(this.dateToday===4){
    this.NumberGettingWorkshiftDay = 3;
  }
  else if(this.dateToday===5){
    this.NumberGettingWorkshiftDay = 4;
  }
  else if(this.dateToday===6){
    this.NumberGettingWorkshiftDay = 5;
  }
  else{
    this.NumberGettingWorkshiftDay = 7;
  }
            this.has_fid = res.has_fid && this.userData.has_fid;
            this.initialValueSP = res.time_table.checkout.initialValueSP;
            this.initialValueEMP = res.time_table.checkout.initialValueEMP;
            this.initialValueLV = res.time_table.checkout.initialValueLV;
            this.stepSP = res.time_table.checkout.stepSP;
            this.stepEmp = res.time_table.checkout.stepEmp;
            this.stepLV = res.time_table.checkout.stepLV;
            this.startshift =
              res.days[this.NumberGettingWorkshiftDay].workShifts[0] !== undefined
                ? res.days[this.NumberGettingWorkshiftDay].workShifts[0].start_shift
                : "00:00";
            this.endshift =
              res.days[this.NumberGettingWorkshiftDay].workShifts[0] !== undefined
                ? res.days[this.NumberGettingWorkshiftDay].workShifts[0].end_shift
                : "00:00";
            this.startshift2 =
              res.days[this.NumberGettingWorkshiftDay].workShifts[1] !== undefined
                ? res.days[this.NumberGettingWorkshiftDay].workShifts[1].start_shift
                : "00:00";
            this.endshift2 =
              res.days[this.NumberGettingWorkshiftDay].workShifts[1] !== undefined
                ? res.days[this.NumberGettingWorkshiftDay].workShifts[1].end_shift
                : "00:00";
            this.payments_type = res.payments_type[this.order.type];
            if (res && res.days && res.days.length) {
              //Find shift
              const mDay = res.days.find((d) => {
                return d.day === this.isoWeekDay;
              });
              resolve(mDay);
            } else {
              resolve(null);
            }
          },
          (_) => {
            reject("error");
          }
        );
    });
  }
  incrementNew(arg): void {
    if (arg === "+") {
      this.getOrderTime("plus");
    }
    if (arg === "-") {
      this.getOrderTime("minus");
    }
  }
  setPaymentMode(mPyament) {
    this.orderExtraInfo.payment_mode = mPyament;
  }

  private formTimeSetter() {
    this.orderDateTime = this.baseOrderTime.toDate();
    this.timer_value = this.baseOrderTime.diff(this.baseTime, "minutes");
    let formValue = {
      day: moment(this.orderDateTime).format("DD/MM/YYYY"),
      hours: moment(this.orderDateTime).format("HH"),
      minutes: moment(this.orderDateTime).format("mm"),
    };
    this.formExtra.setValue(formValue);

    let formValueselect = {
      day: moment(this.orderDateTime).format("DD/MM/YYYY"),
      time:
        this.hourvalue[0] +
        this.hourvalue[1] +
        this.hourvalue[4] +
        this.hourvalue[5],
      // moment(this.orderDateTime).format("HH") +
      // moment(this.orderDateTime).format("mm"),
    };
    this.formExtraselect.setValue(formValueselect);
  }
  initOrderTime() {
    this.baseOrderTime = this.baseTime.clone();
    if (this.daysShift.length) {
      switch (this.order.type) {
        case "Sur Place": {
          let sMoments = this.daysShift.map((s) => {
            let tab =
              s.start_shift_at_spot !== undefined
                ? s.start_shift_at_spot.split(":")
                : s.start_shift.split(":");
            return moment().hours(parseInt(tab[0])).minutes(parseInt(tab[1]));
          });
          sMoments = sMoments.sort((a, b) => a.diff(b));
          let eHours = this.daysShift.map((s) => {
            let tab =
              s.end_shift_at_spot !== undefined
                ? s.end_shift_at_spot.split(":")
                : s.end_shift.split(":");
            return moment()
              .hours(
                parseInt(
                  s.end_shift_at_spot !== undefined
                    ? s.end_shift_at_spot
                    : s.end_shift
                )
              )
              .minutes(parseInt(tab[1]));
          });
          eHours = eHours.sort((a, b) => a.diff(b));
          this.currentShift = this.findCurrentShiftValue(sMoments, eHours);
          if (this.currentShift && this.currentShift.state === 0) {
            this.baseOrderTime = this.currentShift.currentShiftStart.clone();
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueSP,
              "minutes"
            );
          } else if (this.currentShift && this.currentShift.state === 1) {
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueSP,
              "minutes"
            );
          }
          break;
        }
        case "A Emporter": {
          let sMoments = this.daysShift.map((s) => {
            let tab =
              s.start_shift_take_away !== undefined
                ? s.start_shift_take_away.split(":")
                : s.start_shift.split(":");
            return moment().hours(parseInt(tab[0])).minutes(parseInt(tab[1]));
          });
          sMoments = sMoments.sort((a, b) => a.diff(b));
          let eHours = this.daysShift.map((s) => {
            let tab =
              s.end_shift_take_away !== undefined
                ? s.end_shift_take_away.split(":")
                : s.end_shift.split(":");
            return moment()
              .hours(
                parseInt(
                  s.end_shift_take_away !== undefined
                    ? s.end_shift_take_away
                    : s.end_shift
                )
              )
              .minutes(parseInt(tab[1]));
          });
          eHours = eHours.sort((a, b) => a.diff(b));
          this.currentShift = this.findCurrentShiftValue(sMoments, eHours);
          if (this.currentShift && this.currentShift.state === 0) {
            this.baseOrderTime = this.currentShift.currentShiftStart.clone();
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueEMP,
              "minutes"
            );
          } else if (this.currentShift && this.currentShift.state === 1) {
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueEMP,
              "minutes"
            );
          }
          break;
        }
        case "En Livraison": {
          let sMoments = this.daysShift.map((s) => {
            let tab =
              s.start_shift_delivery !== undefined
                ? s.start_shift_delivery.split(":")
                : s.start_shift.split(":");
            return moment().hours(parseInt(tab[0])).minutes(parseInt(tab[1]));
          });
          sMoments = sMoments.sort((a, b) => a.diff(b));
          let eHours = this.daysShift.map((s) => {
            let tab =
              s.end_shift_delivery !== undefined
                ? s.end_shift_delivery.split(":")
                : s.end_shift.split(":");
            return moment()
              .hours(
                parseInt(
                  s.end_shift_delivery !== undefined
                    ? s.end_shift_delivery
                    : s.end_shift
                )
              )
              .minutes(parseInt(tab[1]));
          });
          eHours = eHours.sort((a, b) => a.diff(b));
          this.currentShift = this.findCurrentShiftValue(sMoments, eHours);
          if (this.currentShift && this.currentShift.state === 0) {
            this.baseOrderTime = this.currentShift.currentShiftStart.clone();
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueLV,
              "minutes"
            );
          } else if (this.currentShift && this.currentShift.state === 1) {
            this.baseOrderTime = this.baseOrderTime.add(
              this.initialValueLV,
              "minutes"
            );
          }
          break;
        }
      }
      this.formTimeSetter();
    } else {
      this.baseOrderTime = moment();
      this.baseOrderTime.add(this.BASE_TIME_VALUE, "minutes");
      this.formTimeSetter();
    }
  }
  findCurrentShiftValue(sMoments, eHours) {
    for (let index = 0; index < sMoments.length; index++) {
      if (sMoments[index].diff(this.baseTime) > 0) {
        return {
          state: 0,
          currentShiftStart: sMoments[index],
          currentShiftEnd: eHours[index],
        };
      } else if (
        sMoments[index].diff(this.baseTime) <= 0 &&
        eHours[index].diff(this.baseTime) >= 0
      ) {
        return {
          state: 1,
          currentShiftStart: this.baseTime.clone(),
          currentShiftEnd: eHours[index],
        };
      }
    }
  }
  getOrderTime(action: string) {
    let mDate = this.baseOrderTime.clone();
    if (this.daysShift.length) {
      if (action === "plus") {
        switch (this.order.type) {
          case "Sur Place": {
            if (
              this.currentShift.currentShiftEnd.diff(mDate) >=
              this.stepSP * 60000
            ) {
              mDate = mDate.add(this.stepSP, "minutes");
              this.baseOrderTime = mDate.clone();
              if (this.disableMin) {
                this.disableMin = false;
              }
            } else {
              this.disableMax = true;
            }
            break;
          }
          case "A Emporter": {
            if (
              this.currentShift.currentShiftEnd.diff(mDate) >=
              this.stepEmp * 60000
            ) {
              mDate = mDate.add(this.stepEmp, "minutes");
              this.baseOrderTime = mDate.clone();
              if (this.disableMin) {
                this.disableMin = false;
              }
            } else {
              this.disableMax = true;
            }
            break;
          }
          case "En Livraison": {
            if (
              this.currentShift.currentShiftEnd.diff(mDate) >=
              this.stepLV * 60000
            ) {
              mDate = mDate.add(this.stepLV, "minutes");
              this.baseOrderTime = mDate.clone();
              if (this.disableMin) {
                this.disableMin = false;
              }
            } else {
              this.disableMax = true;
            }
            break;
          }
        }
      } else if (action === "minus") {
        switch (this.order.type) {
          case "Sur Place": {
            mDate = mDate.subtract(this.stepSP, "minutes");
            if (
              mDate.diff(this.currentShift.currentShiftStart) >=
              this.initialValueSP * 60000
            ) {
              this.baseOrderTime = mDate.clone();
              if (this.disableMax) {
                this.disableMax = false;
              }
            } else {
              this.disableMin = true;
            }
            break;
          }
          case "A Emporter": {
            mDate = mDate.subtract(this.stepEmp, "minutes");

            if (
              mDate.diff(this.currentShift.currentShiftStart) >=
              this.initialValueEMP * 60000
            ) {
              this.baseOrderTime = mDate.clone();
              if (this.disableMax) {
                this.disableMax = false;
              }
            } else {
              this.disableMin = true;
            }
            break;
          }
          case "En Livraison": {
            mDate = mDate.subtract(this.stepLV, "minutes");
            if (
              mDate.diff(this.currentShift.currentShiftStart) >=
              this.initialValueLV * 60000
            ) {
              this.baseOrderTime = mDate.clone();
              if (this.disableMax) {
                this.disableMax = false;
              }
            } else {
              this.disableMin = true;
            }
            break;
          }
        }
      }
    } else {
      let actualDate = moment();
      if (action === "plus") {
        mDate = mDate.add(this.BASE_TIME_VALUE, "minutes");
        if (mDate.diff(actualDate) > 0 && mDate.hours() !== 0) {
          this.baseOrderTime = mDate.clone();
        }
      } else if (action === "minus") {
        mDate = mDate.subtract(this.BASE_TIME_VALUE, "minutes");
        if (mDate.diff(actualDate) >= 0) {
          this.baseOrderTime = mDate.clone();
        }
      }
    }
    this.formTimeSetter();
  }

  formatTimerValue(): string {
    if (this.timer_value) {
      let hours = Math.floor(this.timer_value / 60);
      let minutes = this.timer_value % 60;
      let valMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      if (hours) {
        return `${hours}H${valMinutes}`;
      }
      return `${valMinutes} Min`;
    }
    return "-";
  }

  // isValidTime(): boolean {
  //   if (this.daysShift.length && this.orderDateTime) {
  //     const mDate = moment(this.orderDateTime);
  //     //check if hours
  //     let atLessOneShift = 0;
  //     this.daysShift.forEach((s) => {
  //       const sHours = parseInt(s.start_shift),
  //         eHours = parseInt(s.end_shift);
  //       if (mDate.hours() >= sHours && mDate.hours() < eHours) {
  //         atLessOneShift++;
  //       }
  //     });
  //     return atLessOneShift === 0 ? false : true;
  //   }
  //   return true;
  // }

  isValidTime(): boolean {
    if (this.daysShift.length && this.orderDateTime) {
      const mDate = moment(this.orderDateTime);
      //check if hours
      let atLeastOneShift = false;
      this.daysShift.forEach((s) => {
        const sHours = parseInt(s.start_shift),
          eHours = parseInt(s.end_shift);
  
        if (sHours < eHours) {
          if (mDate.hours() >= sHours && mDate.hours() < eHours) {
            atLeastOneShift = true;
          }
        } else {
          // Handle the scenario where end_shift is the next day after midnight
          if ((mDate.hours() >= sHours && mDate.hours() < 24) || (mDate.hours() >= 0 && mDate.hours() < eHours)) {
            atLeastOneShift = true;
          }
        }
      });
  
      return atLeastOneShift;
    }
    return true; // Return true if there are no shifts or no orderDateTime
  }

  async submitOrder(e) {
    if (
      !this.orderExtraInfo.when ||
      !this.orderExtraInfo.payment_mode ||
      !this.userData ||
      !this.appLicenceManager.licenceId ||
      !this.checkLines()
    ) {
      return;
    }
    if (this.isOrderDelivery && !this.formDelivery.valid) {
      return;
    }
    if (this.isDayOff) { // || !this.isValidTime()) {
      return;
    }
    localStorage.setItem(
      "orderExtraInfo",
      JSON.stringify(this.orderExtraInfo)
    );
    localStorage.setItem(
      "formDelivery",
      JSON.stringify(this.formDelivery.value)
    );
    localStorage.setItem("formExtra", JSON.stringify(this.formExtra.value));
    localStorage.setItem(
      "formExtraselect",
      JSON.stringify(this.formExtraselect.value)
    );
    if (this.orderExtraInfo.payment_mode === PAYMENT_MDOES.ONLINE) {
      const popup = this.modalService.open(StripeCheckoutComponent, {
        centered: true,
        scrollable: true,
        backdrop: "static",
        size: "sm",
      });
      popup.componentInstance.amount = this.order.globalPrice;
      this.orderExtraInfo.payment_token = await popup.result;
    }
    this.proceedSubmitOrder();
  }

  async proceedSubmitOrder() {
    let planChoice =
      this.orderExtraInfo.when === this.AVAILABLE_TIMING_TYPE.LATER
        ? "Apres"
        : "Maintenant";
    let buyMethod = null;
    switch (this.orderExtraInfo.payment_mode) {
      case PAYMENT_MDOES.CASH:
        buyMethod = "Espèce";
        break;
      case PAYMENT_MDOES.CB:
        buyMethod = "Carte Bleue";
        break;
      case PAYMENT_MDOES.TICKET_RESTO:
        buyMethod = "Ticket Restau";
        break;
      case PAYMENT_MDOES.ONLINE:
        buyMethod = "En ligne";
        if (!this.orderExtraInfo.payment_token) {
          // Faut afficher une erreur
          return;
        }
        break;
    }
    this.sendingOrder = true;
    this.failureStripeSpinner = true;
    this.failureStripe = false;
    let client = {
      first_name: this.userData.first_name,
      last_name: this.userData.last_name,
      phone: this.userData.phone,
      address: this.userData.address,
      city: this.userData.city,
    };
    if (this.isOrderDelivery) {
      client = {
        ...client,
        ...this.formDelivery.value,
      };
    }
    let transaction = null;
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.has_fid) {
      let value_out = 0;
      this.order.ticketLines.forEach((element) => {
        if (element.isFidProduct) {
          value_out += element.price_point;
        }
      });
      let value_in = Math.trunc(this.order.globalQPrice);
      transaction = {
        value_in,
        value_out,
      };
    }

    this.mData = {
      procaisse_licence_id: this.appLicenceManager.licenceId,
      client_id: this.userData.id,
      type: this.order.type,
      created: moment().tz(timezone).format(),
      payment_type: buyMethod,
      payment_token: this.orderExtraInfo.payment_token,
      paid: false,
      comment: this.orderExtraInfo.comment,
      transaction: transaction,
      total: this.totalapayer,
      sub_total: this.totalapayer,
      discount: this.backRedAmountCost,
      frais_livraison: this.deliveryCostsAmount,
      frais_gestion: this.backFraisAmount,
      reduction: this.codepromoprice,
      code_promo: this.codepromovalue,
      status: "sent",
      when: planChoice,
      order_time: this.formExtraselect.value,
      client: client,
      items: this.order.ticketLines,
    };
    this.mData.order_time.dateTime = moment(this.orderDateTime).set("hour", this.hourvalue[0]+this.hourvalue[1]).set("minute", this.hourvalue[3]+this.hourvalue[4]).tz(timezone).format();
    // this.mData.order_time.dateTime = moment(this.orderDateTime).tz(timezone).format();
    this.mData.order_time.created = this.mData.created;
    // if (!this.userData.promotion) {
    //   let userPromo = await this.disablePromotion(this.userData.id);
    //   if (userPromo) {
    //     this.userData.promotion = true;
    //     this.appUser.setUser(this.userData);
    //     if (this.backRedStatus === true) {
    //       this.mData.discount = (this.mData.total * this.backRedAmount) / 100;
    //       this.mData.sub_total = this.mData.total - this.mData.discount;
    //     } else {
    //       this.mData.sub_total = this.mData.total;
    //     }
        
    //   }
    // }
    //TODO: Loader
    this.myOrderService.createOrderNew(this.mData).subscribe((res) => {
      let dbOrder: any = res;
      if (dbOrder.success === false && dbOrder.message) {
        this.failureStripe = true;
        this.sendingOrder = false;
        this.failureStripeSpinner = false;
      } else {
        this.websocketService.sendMessage(this.appLicenceManager.licenceId);
        setTimeout(() => {
          if (this.mData.transaction !== null) {
            this.userData.cagnotte += this.mData.transaction.value_in;
            this.appUser.setUser(this.userData);
          }
          this.sendingOrder = false;
          this.failureStripeSpinner = false;
          this.failureStripe = true;
          localStorage.setItem("order_id", dbOrder._id);
          this.router.navigate(["suivi"]);
          this.appOrderService.clearMulti(this.order.type);
        }, 1000);
      }
    });
  }

  private disablePromotion(id) {
    return new Promise(async (resolve, reject) => {
      this.userService.disableUserPromotion(id).subscribe(
        (res) => {
          resolve(res);
        },
        (_) => {
          reject(null);
        }
      );
    });
  }
  
  @HostListener("window:popstate")
  onPopstate() {
    this.handler.close();
  }

  formatTimeSlot(time) {
    let [slotHourStart, slotMinStart] = time.split(":").map(Number);
  
    let slotHourEnd = slotHourStart;
    let slotMinEnd = slotMinStart + 30;
  
    if (slotMinEnd > 59) {
      // Increment slotHourEnd by 1, ensuring it stays within 0-23 (24-hour format)
      slotHourEnd = (slotHourEnd + 1) % 24;
  
      // Adjust slotMinEnd to not exceed 59
      slotMinEnd -= 60;
    }
  
    slotHourStart = slotHourStart < 10 ? `0${slotHourStart}` : slotHourStart;
    slotMinStart = slotMinStart < 10 ? `0${slotMinStart}` : slotMinStart;
    slotHourEnd = slotHourEnd < 10 ? `0${slotHourEnd}` : slotHourEnd;
    slotMinEnd = slotMinEnd < 10 ? `0${slotMinEnd}` : slotMinEnd;
  
    return `${slotHourStart}:${slotMinStart} - ${slotHourEnd}:${slotMinEnd}`;
  }
}

function timezone(timezone: any) {
  throw new Error("Function not implemented.");
}
