import { Component, OnInit } from '@angular/core';
import { AppDialogConfig } from 'src/app/config/app-dialog-config';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {

  message: any = null;

    constructor(private mConfig: AppDialogConfig) {

    }

    ngOnInit(): void {
        this.message = this.mConfig.data || '...';
    }


    ngOnDestroy(): void {
        this.message = null;
    }

}
