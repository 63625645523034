import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCustomers'
})
export class FilterCustomersPipe implements PipeTransform {
  transform(customers: any[], searchTerm: string): any[] {
    if (!customers || !searchTerm) {
      return customers;
    }

    const regex = new RegExp(searchTerm, 'i');

    return customers.filter(customer => {
      const firstName = customer.client.first_name;
      const lastName = customer.client.last_name;
      const phone = customer.client.phone;

      return (
        regex.test(phone) ||
        regex.test(firstName) ||
        regex.test(lastName) ||
        regex.test(lastName + ' ' + firstName)
      );
    });
  }
}
