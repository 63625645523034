import { Component, OnInit } from '@angular/core';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { MyOrderService } from 'src/app/services/my-order.service';
import { AppUserService } from 'src/app/managers/app-user.service';
import { AppOrderStatusResolver } from 'src/app/managers/app-order-status-resolver.service';
import { Router } from '@angular/router';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { getLocaleDateFormat } from '@angular/common';

@Component({
    selector: 'app-account-orders',
    templateUrl: './account-orders.component.html',
    styleUrls: ['./account-orders.component.scss']
})
export class AccountOrdersComponent implements OnInit {

    orders: any = [];
    currency: any;
    resto_siret: any;

    restaurants: any;
    selectedRestaurant: any;

    isFetchingOrders: boolean = false;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private appLicenceManager: AppLicenceManagerService,
        private myOrderService: MyOrderService,
        private appUserService: AppUserService,
        private appOrderStatusResolver: AppOrderStatusResolver) {

    }
    ngOnInit(): void {
        this.getCurrency();

        const restaurants = localStorage.getItem("restaurants");
        this.restaurants = JSON.parse(restaurants);
    }

    getCurrency() {
        if (localStorage.getItem("CURRENT_RESTO")) {
          this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
        }
        
        this.httpClient
          .get<any>(
            `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
          )
          .subscribe((Currencyresponse) => {
            this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
            console.log(this.currency)
          });
    }

    goToCategories(){
        this.router.navigate(['/customer-details']);
    }

    viewDetail(order) {
        localStorage.setItem("order_id", order._id);
        this.router.navigate(['account-order-detail']);
    }

    getOrders() {
        this.isFetchingOrders = true;

        let params = {
            procaisse_licence_id: this.selectedRestaurant,
            client_id: this.appUserService.user.id
        }
        
        this.myOrderService.getOrdersByLicenceAndClient(params).subscribe((res) => {
            this.orders = (res as any[])
            .map(order => {
              const timestamp = (new Date(order.created)).getTime();
              order.style = this.appOrderStatusResolver.resolveStyle(order);
              return { ...order, timestamp };
            })
            .sort((a, b) => b.timestamp - a.timestamp);
          
            this.isFetchingOrders = false;

        }, (_) => {
        });
    }
}
