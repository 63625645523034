import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { UserService } from 'src/app/services/user.service';
import { AppUserService } from 'src/app/managers/app-user.service';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
declare var FB:any;
@Component({
    selector: 'app-form-login-center',
    templateUrl: './app-form-login-center.component.html',
    styleUrls: ['./app-form-login-center.component.scss']
})
export class AppFormLoginCenterComponent implements OnInit {
    formLogin: FormGroup;
    licenceID: any;
    franchise:any;
    loginError: String = null;
    loading: Boolean = false;
    @Output()
    onSuccess = new EventEmitter();
    fideliteVersion: String = null;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private appLicenceManager: AppLicenceManagerService,
        private router:Router,
        private mAppUserService: AppUserService) {
        this.licenceID = this.appLicenceManager.licenceId;
        this.franchise=appLicenceManager.franchise;
    }

    ngOnInit() {
        // (window as any).fbAsyncInit = function() {
        //     FB.init({
        //       appId      : '325203776014986',
        //       cookie     : true,
        //       xfbml      : true,
        //       version    : 'v3.1'
        //     });
              
        //     FB.AppEvents.logPageView();   
              
        //   };
        
        //   (function(d, s, id){
        //      var js, fjs = d.getElementsByTagName(s)[0];
        //      if (d.getElementById(id)) {return;}
        //      js = d.createElement(s); js.id = id;
        //      js.src = "https://connect.facebook.net/en_US/sdk.js";
        //      fjs.parentNode.insertBefore(js, fjs);
        //    }(document, 'script', 'facebook-jssdk'));
        this.formLogin = new FormGroup({
            login: new FormControl("", [Validators.required]),
            password: new FormControl("", [Validators.required])
        });

        this.getFideliteVersion();
    }

    
  getFideliteVersion() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickncollectParam/published/${environment.franchiseID}`
      )
      .subscribe((clickncollectparamresponse) => {
        this.fideliteVersion = clickncollectparamresponse.fideliteVersion ? clickncollectparamresponse.fideliteVersion : 'Postgres';
      });
  }


    facebookLogin() {
        FB.login((response) => {
            if(response.authResponse){
                let userData = {
                    cagnotte: 0,
                    email: response.authResponse.userID,
                    first_name: response.authResponse.userID,
                    has_fid: true,
                    id: response.authResponse.userID,
                    last_name: response.authResponse.userID,
                    phone: response.authResponse.userID,
                    token: response.authResponse.accessToken,
                }
                this.mAppUserService.setUser(userData);
                this.onSuccess.emit(userData);
            } else {
                this.loginError = "Couple login/mot de passe est incorrect.";
            }
        })
    }

    submit() {
        const mFormData = this.formLogin.value;
        if (!mFormData.login || !mFormData.password || !this.licenceID) {
            return;
        }
        this.loginError = null;
        this.loading = true;
        // mFormData.franchiseUUID=this.franchise.franchiseUUID;
        // mFormData.procaisse_licence_id = this.licenceID;

            this.userService.loginUserFranchise(mFormData).subscribe((res: any) => {
                const userFranchiseData = res;
                this.loading = false;
                if (userFranchiseData && userFranchiseData.accessToken && userFranchiseData.accessToken != "err") {
                    this.mAppUserService.setFranchiseUser(userFranchiseData);
                    this.onSuccess.emit(userFranchiseData);
                    this.router.navigate(['list-client']);
                } else {
                    this.loginError = "Couple login/mot de passe est incorrect.";
                }
            }, (_) => {
                this.loading = false;
                this.loginError = "Couple login/mot de passe est incorrect..";
            });
        
    }

    passwordRecovery(){
        this.router.navigate(['account-password-recovery']);
    }

}
