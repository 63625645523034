import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { OrderTypeEnum } from "src/app/enums/order-type";
import { AppImageRosolver } from "src/app/managers/app-image-resolver.service";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { AppOrderService } from "src/app/managers/app-order.service";
import { AppUserService } from "src/app/managers/app-user.service";
import { CategorieService } from "src/app/services/categorie.service";
import { FranchiseService } from "src/app/services/franchise.service";
import { MyOrderService } from "src/app/services/my-order.service";
import { UserService } from "src/app/services/user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
declare var require: any;
const moment = require("moment-timezone");
@Component({
  selector: "app-call-multi-layout",
  templateUrl: "./call-multi-layout.component.html",
  styleUrls: ["./call-multi-layout.component.scss"],
})
export class CallMultiLayoutComponent implements OnInit {
  orderType: null;
  AVAILABLE_TYPES = OrderTypeEnum;
  private _order = null;
  showZones = false;
  orderCity = null;
  zones = [];
  licenceID: any;
  franchise: any;
  restaurants = [];
  locations = [];
  enabledOrderType = null;
  showTypeOrders = true;
  user_position: any;
  searchText = "";
  switched = false;
  fidCategory: any;
  userData: any;
  loading = true;
  franchiseENV: any;
  modalOption: NgbModalOptions = {};
  formGenderBirthday: FormGroup = null;
  genderbdayError = false;
  categories: any;
  currentProduct: any;
  public myAngularxQrCode: string = null;
  imageLogo: any = "";
  config_atspot = false;
  config_takeaway = false;
  config_delivery = false;
  config_plateform = false;
  urlPlateform: string = "";
  config_tailleLogo: string = "";
  imageHeaderband: any = "";
  imagebcktransparente: any = "";
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  clickversion = "";
  startshift: any;
  endshift: any;
  startshift2: any;
  endshift2: any;
  dateToday: number;
  NumberGettingWorkshiftDay: number = 0;
  franchiseUuid: any;
  currency: any;
  resto_siret: any;
  extra: string;

  constructor(
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private myOrderService: MyOrderService,
    private router: Router,
    private appLicenceManager: AppLicenceManagerService,
    private appOrderService: AppOrderService,
    private franchiseService: FranchiseService,
    private appUser: AppUserService,
    private appImageResolver: AppImageRosolver,
    private categoryService: CategorieService,
    private userService: UserService,
    private restoManager: AppLicenceManagerService
  ) {
    this.locations =
      (localStorage.locations && JSON.parse(localStorage.locations)) || [];
    this.userData = appUser.getUser();

    this.franchiseENV = this.appLicenceManager.franchise;
    if (this.userData) {
      this.myAngularxQrCode = JSON.stringify({
        franchiseUUID: this.franchiseENV.franchiseUUID,
        phone: this.userData.phone,
        cagnotte: this.userData.cagnotte,
      });
    }
    // this.appOrderService.clear();
    // this.appLicenceManager.clear();
    // this.appOrderService.factory("A Emporter");
  }
  async ngOnInit() {
    // this.fidCategory = await this.getFidProducts();
    const positionUser = {
      coords: {
        latitude: parseFloat(this.userData.lat), // Latitude
        longitude: parseFloat(this.userData.long), // Longitude
      },
      timestamp: Date.now(), // Horodatage actuel
    };

    if (positionUser) {
      this.user_position = positionUser;
      console.log("Nouvelle position de l'utilisateur :");
      console.log("Latitude : " + this.user_position.coords.latitude);
      console.log("Longitude : " + this.user_position.coords.longitude);
      this.getRestaurants(this.franchiseENV.franchiseID);
    }
    // if (window.navigator && window.navigator.geolocation) {
    //   window.navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       console.log("position" + position);
    //       this.user_position = position;
    //       this.getRestaurants(this.franchiseENV.franchiseID);
    //     },
    //     (error) => {
    //       this.getRestaurants(this.franchiseENV.franchiseID);
    //     }
    //   );
    // }

    this.getimageLogo();
    this.getBackofficeOrderTypes();
    this.getimageHeaderband();
    this.getimageBcktransaprente();
    this.getbackgroundColorOpacity();
    this.getVersion();
    this.getCurrency();
    // this.getFranchiseUuidbyfranchiseId();

    this.getExtra();
  }

  getExtra() {
    const itemFromLocalStorage = localStorage.getItem("formDelivery");
    this.extra = JSON.parse(itemFromLocalStorage).extra;
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }

    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse
          ? Currencyresponse.countryInfo.currencyInfo.symbol
          : environment.currency;
        console.log(this.currency);
      });
  }

  getVersion() {
    this.clickversion = environment.clickversion;
  }

  // getFranchiseUuidbyfranchiseId() {
  //   this.httpClient
  //     .get<any>(
  //       `${environment.backofficeapiUrl}/api/franchiseprocaisse/findFranchiseUuid/${environment.franchiseID}`
  //     )
  //     .subscribe((response) => {
  //       this.franchiseUuid = response ? response.franchise_uuid : null;
  //       console.log(this.franchiseUuid);
  //     });
  // }

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse
          ? backgroundColorOpacityresponse.background_home_opacity
          : "black";
        this.listCategoriesColor = backgroundColorOpacityresponse
          ? backgroundColorOpacityresponse.liste_categories
          : "white";
      });
  }

  getimageBcktransaprente() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickncollectBcktransparente/angular/${environment.franchiseID}`
      )
      .subscribe((bcktransparenteresponse) => {
        // this.imagebcktransparente = bcktransparenteresponse ? bcktransparenteresponse.image_url : "../../../assets/images/bg/backgroundDefault.jpg";
        this.imagebcktransparente = bcktransparenteresponse
          ? bcktransparenteresponse.image_url
          : null;
      });
  }

  getimageHeaderband() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectheaderband/angular/${environment.franchiseID}`
      )
      .subscribe((headerbandresponse) => {
        this.imageHeaderband = headerbandresponse
          ? headerbandresponse.image_url
          : null;
      });
  }

  getBackofficeOrderTypes() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickncollectParam/published/${environment.franchiseID}`
      )
      .subscribe((clickncollectparamresponse) => {
        this.config_atspot =
          clickncollectparamresponse && clickncollectparamresponse.sur_place
            ? clickncollectparamresponse.sur_place
            : false;
        this.config_takeaway =
          clickncollectparamresponse && clickncollectparamresponse.emporter
            ? clickncollectparamresponse.emporter
            : false;
        this.config_delivery =
          clickncollectparamresponse && clickncollectparamresponse.livraison
            ? clickncollectparamresponse.livraison
            : false;
        this.config_plateform =
          clickncollectparamresponse && clickncollectparamresponse.plateform
            ? clickncollectparamresponse.plateform
            : false;
        this.urlPlateform =
          clickncollectparamresponse && clickncollectparamresponse.plateform_url
            ? clickncollectparamresponse.plateform_url
            : null;
        this.config_tailleLogo =
          clickncollectparamresponse && clickncollectparamresponse.taille_logo
            ? clickncollectparamresponse.taille_logo
            : 13;
      });
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse
          ? logoresponse.image_url
          : "../../../assets/images/main-logo.png";
      });
  }

  getRestaurants(id) {
    this.franchiseService.getRestosPostgres(id).subscribe((res) => {
      this.franchise = res;
      this.franchise.img = this.imageLogo;
      if (this.user_position) {
        this.franchise.restos = this.franchise.restos.map((r) => {
          let newResto = r;
          if (r.lat && r.lng) {
            newResto.distance = this.distance(
              r.lat,
              r.lng,
              // parseFloat(this.userData.lat),
              // parseFloat(this.userData.long)
              this.user_position.coords.latitude,
              this.user_position.coords.longitude
            );
          }
          return newResto;
        });
      }
      //this.restaurants=this.franchise.restos.filter(r=>r.status===true)
      this.filterByTpe(OrderTypeEnum.TAKE_AWAY);
    });
  }

  filterRestos(value: string) {
    this.searchText = value;
    if (this.searchText === "") {
      this.restaurants = this.restaurants = this.franchise.restos.filter(
        (r) => r.status === true
      );
    } else {
      this.restaurants = this.restaurants = this.franchise.restos.filter(
        (r) => r.status === true
      );
      this.restaurants = this.restaurants.filter(
        (r) =>
          r.adresse.toUpperCase().includes(this.searchText.toUpperCase()) ==
          true
      );
    }
  }

  filterByTpe(type) {
    this.restaurants = this.franchise.restos.filter((r) => r.status === true);
    // this.appOrderService.factory(type);
    switch (type) {
      case OrderTypeEnum.ON_THE_SPOT: {
        this.restaurants = this.restaurants.filter((r) => r.atSpot === true);
        // this.restaurants.forEach(element => {
        //   if(element.atSpot===true){element.disabled=false}
        //   else element.disabled=true
        // });
        this.enabledOrderType = "0";
        break;
      }
      case OrderTypeEnum.TAKE_AWAY: {
        // this.restaurants.forEach(element => {
        //   if(element.takeAway===true){element.disabled=false}
        //   else element.disabled=true
        // });
        this.restaurants = this.restaurants.filter((r) => r.takeAway === true);
        this.enabledOrderType = "1";
        break;
      }
      case OrderTypeEnum.DELIVERY: {
        this.restaurants = this.restaurants.filter((r) => r.delivery === true);
        // this.restaurants.forEach(element => {
        //   if(element.delivery===true){element.disabled=false}
        //   else element.disabled=true
        // });
        this.enabledOrderType = "2";
        break;
      }
    }
    this.loading = false;
  }

  displayTypes(item) {
    if (item.displayTypes == undefined || item.displayTypes == false) {
      item.displayTypes = true;
    } else item.displayTypes = false;
  }

  distance(lat1, lon1, lat2, lon2) {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      //
      return dist;
    }
  }

  async fetchProduct(item, content) {
    if (this.userData.cagnotte >= item.price_point) {
      if (this.userData.birthday && this.userData.gender) {
        this.categories = await this.getCategories();
        let param = await this.getParams();
        localStorage.setItem(
          "restaurant_categories",
          JSON.stringify(this.categories)
        );
        this.router.navigate(["product-details"], {
          queryParams: {
            idItem: item.idItem,
            idCategory: this.fidCategory.idCat,
          }
        });
      } else {
        this.currentProduct = item;
        this.formGenderBirthday = new FormGroup({
          gender: new FormControl("", Validators.required),
          day: new FormControl("", Validators.required),
          month: new FormControl("", Validators.required),
          year: new FormControl("", Validators.required),
        });
        this.modalOption.backdrop = "static";
        this.modalOption.keyboard = false;
        this.modalService.open(content, this.modalOption);
      }
    }
  }

  updateUserInfos() {
    let gender = this.formGenderBirthday.get("gender").value;
    let day = this.formGenderBirthday.get("day").value;
    let month = this.formGenderBirthday.get("month").value;
    let year = this.formGenderBirthday.get("year").value;
    let birthdate = moment(`${year}-${month}-${day}`);
    if (birthdate._isValid && gender !== "") {
      this.genderbdayError = false;
      this.userData.birthday = `${day}-${month}-${year}`;
      this.userData.gender = gender;
      this.userService
        .registerUserGenderBirthday(this.userData)
        .subscribe((res) => {
          this.appUser.setUser(this.userData);
          this.modalService.dismissAll("finished");
          localStorage.setItem(
            "restaurant_categories",
            JSON.stringify(this.categories)
          );
          this.router.navigate(["product-details"], {
            queryParams: {
              idItem: this.currentProduct.idItem,
              idCategory: this.fidCategory.idCat,
            }
          });
        });
    } else {
      this.genderbdayError = true;
    }
  }

  // private getFidProducts() {
  //   return new Promise(async (resolve, reject) => {
  //     this.categoryService
  //       .getCategory(
  //         this.appLicenceManager.franchise.franchiseUUID,
  //         "FID",
  //         this.appOrderService.order.type
  //       )
  //       .subscribe(
  //         (res) => {
  //           this.fidCategory = res;
  //           if (
  //             this.fidCategory.sous_categories &&
  //             Array.isArray(this.fidCategory.sous_categories)
  //           ) {
  //             this.appImageResolver.resolveCategories(
  //               this.fidCategory.sous_categories
  //             );
  //             for (
  //               let i = 0;
  //               i < this.fidCategory.sous_categories.length;
  //               i++
  //             ) {
  //               this.appImageResolver.resolveProducts(
  //                 this.fidCategory.sous_categories[i].products
  //               );
  //             }
  //           }
  //           this.appImageResolver.resolveProducts(this.fidCategory.products);
  //           resolve(res);
  //         },
  //         (_) => {
  //           reject(null);
  //         }
  //       );
  //   });
  // }

  private getParams() {
    return new Promise(async (resolve, reject) => {
      this.myOrderService
        .fetchOrderParams(this.appLicenceManager.licenceId)
        .subscribe(
          (res) => {
            if (res) {
              const isoWeekDay = moment().isoWeekday(),
                now = moment();
              let mDay = null;
              if (res.days && res.days.length) {
                mDay = res.days.find((d) => {
                  return d.day === isoWeekDay;
                });
              }
              let isOpenTime = false;
              if (mDay) {
                mDay.workShifts.forEach((s) => {
                  const sHours = parseInt(s.start_shift),
                    eHours = parseInt(s.end_shift);
                  if (now.hours() >= sHours && now.hours() < eHours) {
                    isOpenTime = true;
                  }
                });
              }
              if (res.zones && res.zones.length) {
                res.zones = res.zones.map((zone) => {
                  zone.cities = zone.cities.map((city) => {
                    city.min_order = parseFloat(zone.amount) || 0;
                    city.deliveryCostOrder =
                      parseFloat(zone.delivery_costs) || 0;
                    return city;
                  });
                  return zone;
                });

                //TODO: Sort min to max amount
              }

              this.zones = res.zones || [];
              this.appLicenceManager.saveParameters(res);
              resolve(res);
            }
          },
          (_) => {
            reject(null);
          }
        );
    });
  }

  private getCategories() {
    return new Promise(async (resolve, reject) => {
      this.categoryService
        .getCategories(this.appLicenceManager.licenceId)
        .subscribe(
          (res) => {
            let categories: any;
            categories = res;
            categories.unshift({
              name: "Avantages",
              idCat: "FID",
              parent_id: -1,
              path: "Avantages.png",
            });
            this.appImageResolver.resolveCategories(categories);
            resolve(categories);
          },
          (_) => {
            reject(null);
          }
        );
    });
  }

  switchView() {
    window.scrollTo(0, 0);
    this.switched = !this.switched;
  }

  navigate_to_restaurant(item) {
    this.restoManager.saveResto(item);
    this.router.navigateByUrl(`${item.currentLicence}`);
  }

  navigate_order_type(item) {
    // this.appOrderService.clear();
    this.restoManager.saveResto(item);
    this.router.navigateByUrl(`order-types`);
  }

  sortBy(prop: string) {
    return this.restaurants.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  setOrderTypeDesktop(mValue, item) {
    this.orderType = mValue;
    if (!this.orderType) {
      return;
    }
    this.appLicenceManager.factory(item.currentLicence);
    this.secondInit(item);
    this.restoManager.saveResto(item);
  }

  setOrderType(item) {
    let mValue;
    if (this.enabledOrderType === "1") {
      mValue = this.AVAILABLE_TYPES.TAKE_AWAY;
    }
    if (this.enabledOrderType === "2") {
      mValue = this.AVAILABLE_TYPES.DELIVERY;
    }
    if (this.enabledOrderType === "0") {
      mValue = this.AVAILABLE_TYPES.ON_THE_SPOT;
    }
    this.orderType = mValue;
    if (!this.orderType) {
      return;
    }
    this.appLicenceManager.factory(item.currentLicence);
    this.secondInit(item);
  }

  setCity() {
    if (!this.orderCity && this.orderType === OrderTypeEnum.DELIVERY) {
      return;
    }
    this.appOrderService.setOrderCity(this.orderCity);
    this.router.navigate(["categories"]);
  }

  secondInit(item) {
    this.myOrderService
      .fetchOrderParams(this.appLicenceManager.licenceId)
      .subscribe((res) => {
        if (res) {
          const isoWeekDay = moment().isoWeekday(),
            now = moment();
          let mDay = null;
          if (res.days && res.days.length) {
            mDay = res.days.find((d) => {
              return d.day === isoWeekDay;
            });
          }
          let isDayOff = (mDay && mDay.dayOff) || false;
          let isOpenTime = false;
          if (mDay) {
            mDay.workShifts.forEach((s) => {
              const sHours = parseInt(s.start_shift),
                eHours = parseInt(s.end_shift);
              if (now.hours() >= sHours && now.hours() < eHours) {
                isOpenTime = true;
              }
            });
          }
          if (res.zones && res.zones.length) {
            res.zones = res.zones.map((zone) => {
              zone.cities = zone.cities.map((city) => {
                city.min_order = parseFloat(zone.amount) || 0;
                city.deliveryCostOrder = parseFloat(zone.delivery_costs) || 0;
                return city;
              });
              return zone;
            });

            //TODO: Sort min to max amount
          }

          this.zones = res.zones || [];
          this.appLicenceManager.saveParameters(res);
          // this._order = this.appOrderService.order;
          // if (this._order && this._order.type) {
          //   this.orderType = this._order.type;
          // }
          // if (!this._order) {
          //   this.appOrderService.factory(this.orderType);
          // } else {
          //   if (this._order.type !== this.orderType) {
          //     this.appOrderService.factory(this.orderType);
          //   } else {
          //     this.appOrderService.setType(this.orderType);
          //   }
          // }
          if (this.orderType === OrderTypeEnum.DELIVERY && this.zones.length) {
            // Redirect to choose City
            this.showTypeOrders = false;
            this.showZones = true;
            item.showZones = true;
            return;
          }
          this.router.navigate(["categories"]);
        }
      });
  }
}
