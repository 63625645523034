import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppUserService } from 'src/app/managers/app-user.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register-solution',
  templateUrl: './register-solution.component.html',
  styleUrls: ['./register-solution.component.scss']
})
export class RegisterSolutionComponent implements OnInit {

  formRegister: FormGroup;
  licenceID: any;
  franchise:any;
  loading: Boolean = false;
  @Output()
  onSuccess = new EventEmitter();
  registerError = null;
  has_fid:Boolean=false;
  siret:any;
  constructor(private userService: UserService,
    private router:Router,
      private appLicenceManager: AppLicenceManagerService,
      private mAppUserService: AppUserService) {
      this.licenceID = this.appLicenceManager.licenceId;
      this.franchise=appLicenceManager.franchise
     this.has_fid=this.franchise.has_fid;
     //this.siret=appLicenceManager.parameters.siret
  }

  ngOnInit() {
      this.formRegister = new FormGroup({
          login: new FormControl(null, [Validators.required]),
          password: new FormControl(null, [Validators.required]),
          first_name: new FormControl(null, [Validators.required]),
          last_name: new FormControl(null, [Validators.required]),
          email: new FormControl(null, [Validators.required, Validators.email]),
           has_fid: new FormControl(true),
          // conditions: new FormControl(null, [Validators.required]),
      });
  }

  submit(e) {
      e.preventDefault();
      this.registerError = null;
      this.registerError = null;
      this.formRegister.get('email').setValue(this.formRegister.get('email').value.replace(/\s/g,''));
      this.formRegister.get('login').setValue(this.formRegister.get('login').value.replace(/\s/g,''));
      this.formRegister.get('password').setValue(this.formRegister.get('password').value.replace(/\s/g,''));
      const mFormData = this.formRegister.value;
      if (!this.formRegister.valid || !this.franchise.franchiseUUID) {
          return;
      }
      this.loading = true;
      mFormData.procaisse_licence_id = this.licenceID;
      mFormData.franchiseUUID=this.franchise.franchiseUUID;
      this.userService.createUserETNew(mFormData).subscribe((res: any) => {
          const userData = res;
          this.loading = false;
          if (userData && userData.token && userData.token != "err") {
              delete userData.password;
              delete userData.login;
              this.mAppUserService.setUser(userData);
              this.mAppUserService.triggerAuth(true);
      this.router.navigate(['/account-orders']);
          } else {
              this.registerError = "N° de téléphone et/ou email déjà existant.";
          }
      }, (_) => {
          this.loading = false;
          this.registerError = "Une erreur s'est produite.";
      });
  }

}
