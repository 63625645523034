import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLicenceManagerService } from '../managers/app-licence-manager.service.';


@Injectable({
    providedIn: 'root'
})
export class AppLicenceGuardService implements CanActivate {


    constructor(
        private router: Router,
        private appLicenceManager: AppLicenceManagerService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.appLicenceManager.licenceId) {
            return true;
        }
        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}