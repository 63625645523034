import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  constructor(private httpClient: HttpClient) { }
  private productsUrl = `${environment.apiUrl}/products`;

  public getAllProducts(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.productsUrl}ByLicence`, params);
  }
  public getAllProductsByCat(licence: any, categorieID: any, categorieName: any) {
    let params = { "procaisse_licence_id": licence, "categorieID": categorieID, "categorieName": categorieName }
    return this.httpClient.post(`${this.productsUrl}ByLicenceAndCategoryAll`, params);
  }
  public getProducts(licence: any, categorieID: any, orderType: any) {
    let params = { "procaisse_licence_id": licence, "categorieID": categorieID, "orderType": orderType }
    return this.httpClient.post(`${this.productsUrl}ByLicenceAndCategory`, params);
  }
  public saveProduct(licence: any, product: any) {
    let params = { "procaisse_licence_id": licence, "product": product }
    return this.httpClient.post(`${this.productsUrl}ByLicenceUpdate`, params);
  }

  public getProduct(licence: any, productId: any,orderType:any) {
    const params = { procaisse_licence_id: licence,orderType: orderType };
    return this.httpClient.post(`${this.productsUrl}/${productId}/retrieveInfos`, params);
  }
  public getProductsByListId(licence: any, products_id: any,orderType:any) {
    let params = { "procaisse_licence_id": licence, "products_id": products_id,"orderType":orderType}
    return this.httpClient.post(`${this.productsUrl}ByLicenceAndIdsNewV0`, params);
  }
  public getGlobalAdditionalProducts(licence: any, orderType: any) {
    let params = { "procaisse_licence_id": licence, "orderType": orderType }
    return this.httpClient.post(`${this.productsUrl}AdditionalSalesByLicenceAndOrderType`, params);
  }
  public getProductsSizeNames(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.productsUrl}Sizes`, params);
  }
}
