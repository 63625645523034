  import { BrowserModule } from '@angular/platform-browser';
  import { NgModule } from '@angular/core';
  import { ReactiveFormsModule, FormsModule } from '@angular/forms';
  import { AppRoutingModule } from './app-routing.module';
  import { AppComponent } from './app.component';
  import { HttpClientModule } from '@angular/common/http';
  import { CategoriesListComponent } from './routes/categories-list/categories-list.component';
  import { ProductDetailComponent } from './routes/product-detail/product-detail.component';
  import { OrderCheckoutComponent } from './routes/order-checkout/order-checkout.component';
  import { AppFormLoginComponent } from './components/app-form-login/app-form-login.component';
  import { AppFormLoginCenterComponent } from './components/app-form-login-center/app-form-login-center.component';
  import { AppFormRegisterComponent } from './components/app-form-register/app-form-register.component';
  import { SecurityComponent } from './routes/security/security.component';
  import { SecurityCallCenterComponent } from './routes/security-call-center/security-call-center.component';
  import { AppFormPasswordRecoveryComponent } from './components/app-form-password-recovery/app-form-password-recovery.component';
  import { AccountProfilComponent } from './routes/account/profil/account-profil/account-profil.component';
  import { AccountOrdersComponent } from './routes/account/account-orders/account-orders.component';
  import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
  import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
  import { AssistanceComponent } from './routes/account/assistance/assistance.component';
  import { AccountOrderDetailComponent } from './routes/account/account-order-detail/account-order-detail.component';
  import { OrderRecapComponent } from './components/order-recap/order-recap.component';
  import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
  import { GlobalSalesComponent } from './routes/global-sales/global-sales.component';
  import { AdditionalOptionsComponent } from './components/additional-options/additional-options.component';
  import { AddionalSalesComponent } from './components/addional-sales/addional-sales.component';
  import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
  import { HomeComponent } from './routes/home/home.component';
  import { BasketComponent } from './components/basket/basket.component';
  import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
  import { BasketContentComponent } from './components/basket-content/basket-content.component';
  import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
  import { OrderStatutComponent } from './routes/order-statut/order-statut.component';
  import { MultiLayoutComponent } from './layouts/multi-layout/multi-layout.component';
  import {OrderTypeComponent } from './routes/order-type/order-type/order-type.component';
  import { OrderTypesComponent } from './routes/order-types/order-types.component';
  import { QRCodeModule } from 'angularx-qrcode';
  import { FideliteDetailsComponent } from './routes/fidelite-details/fidelite-details.component';
  import { AppFormPasswordResetComponent } from './components/app-form-password-reset/app-form-password-reset.component';
  import { AppLinkResetComponent } from './components/app-link-reset/app-link-reset.component';
  import { RegisterSolutionComponent } from './components/register-solution/register-solution.component';
  import { CheckoutResponseComponent } from './routes/checkout-response/checkout-response.component';
  import { SpinnerLoaderComponent } from './routes/spinner-loader/spinner-loader.component';
  import { StripeCheckoutComponent } from './routes/stripe-checkout/stripe-checkout.component';
import { ClientListComponent } from './routes/client-list/client-list.component';
import { ClientsOrdersComponent } from './routes/clients-orders/clients-orders.component';
import { NouveauClientComponent } from './routes/nouveau-client/nouveau-client.component';
import { CallMultiLayoutComponent } from './layouts/call-multi-layout/call-multi-layout.component';
import { AppRedirectGuardFranchiseService } from './security/redirect-guard.service';
import { CustomerDetailsComponent } from './routes/customer-details/customer-details.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete'; 
import { NavbarComponent } from './components/navbar/navbar.component';
import { FilterCustomersPipe } from './filter-customers.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

  @NgModule({
    declarations: [
      BaseLayoutComponent,
      AppComponent,
      CategoriesListComponent,
      ProductDetailComponent,
      OrderCheckoutComponent,
      AppFormLoginComponent,
      AppFormLoginCenterComponent,
      AppFormRegisterComponent,
      SecurityComponent,
      SecurityCallCenterComponent,
      AccountOrdersComponent,
      AppFormPasswordRecoveryComponent,
      AccountProfilComponent,
      AccountSidebarComponent,
      SnackBarComponent,
      AssistanceComponent,
      AccountOrderDetailComponent,
      OrderRecapComponent,
      GlobalSalesComponent,
      AdditionalOptionsComponent,
      AddionalSalesComponent,
      HomeComponent,
      BasketComponent,
      BasketContentComponent,
      OrderStatutComponent,
      MultiLayoutComponent,
      OrderTypeComponent,
      OrderTypesComponent,
      FideliteDetailsComponent,
      AppFormPasswordResetComponent,
      AppLinkResetComponent,
      RegisterSolutionComponent,
      CheckoutResponseComponent,
      SpinnerLoaderComponent,
      StripeCheckoutComponent,
      ClientListComponent,
      ClientsOrdersComponent,
      NouveauClientComponent,
      CallMultiLayoutComponent,
      CustomerDetailsComponent,
      NavbarComponent,
      FilterCustomersPipe,
      ConfirmationDialogComponent,
      AlertDialogComponent,
    ],
    imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      ReactiveFormsModule,
      FormsModule,
      AppRoutingModule,
      NgbModule,
      NgxUsefulSwiperModule,
      QRCodeModule, 
      GooglePlaceModule,
      MatDialogModule,
    ],
    entryComponents: [SnackBarComponent, ConfirmationDialogComponent, AlertDialogComponent],
    providers: [NgbActiveModal, AppRedirectGuardFranchiseService],
    bootstrap: [AppComponent]
  })
  export class AppModule { }
