import { Component, OnInit } from '@angular/core';
// import { Client } from './client.model'; // Assurez-vous d'importer correctement le modèle
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { phoneNumberValidator, textNotEmptyValidator } from '../../components/app-form-register/custom-validators';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { UserService } from 'src/app/services/user.service';
import { AppUserService } from 'src/app/managers/app-user.service';
import { Router } from '@angular/router';

class Client {
  constructor(
    public promotion: boolean,
    public has_fid: boolean,
    public _id: string,
    public login: string,
    public password: string,
    public first_name: string,
    public last_name: string,
    public email: string,
    public franchiseUUID: number
  ) {}
}

@Component({
  selector: 'app-nouveau-client',
  templateUrl: './nouveau-client.component.html',
  styleUrls: ['./nouveau-client.component.scss']
})
export class NouveauClientComponent implements OnInit {

  licenceID: any;
  has_fid:Boolean=false;
  franchise:any;
  formRegister: FormGroup;
  client: any;
  imageLogo: any = "";
  backgroundColorOpacity: string = "";
  formSubmitted: boolean = false;
  registerError = null;
  loading: Boolean = false;
  nameFranchise : any;
  clickAndCollectUrl : any;
  logoFranchise : any;
  NewUserPhone: string;

  constructor(
    private httpClient: HttpClient,
    private appLicenceManager: AppLicenceManagerService,
    private userService: UserService,
    private mAppUserService: AppUserService,
    private router: Router,) {
      this.licenceID = this.appLicenceManager.licenceId;
      this.franchise=appLicenceManager.franchise
     this.has_fid=this.franchise.has_fid;
  }

  ngOnInit() {
    this.NewUserPhone = this.mAppUserService.getPhoneUser();

        this.formRegister = new FormGroup({
            // login: new FormControl(null, [Validators.required]),
            login: new FormControl(this.NewUserPhone, [Validators.required, phoneNumberValidator()]),
            password: new FormControl(null, [Validators.required, textNotEmptyValidator()]),
            first_name: new FormControl(' ', [Validators.required, textNotEmptyValidator()]),
            last_name: new FormControl(null, [Validators.required, textNotEmptyValidator()]),
            email: new FormControl(null, [Validators.required, Validators.email]),
             has_fid: new FormControl(true),
            conditions: new FormControl(null, [Validators.required]),
        });
    // Initialisation
    this.getimageLogo();
    this.getbackgroundColorOpacity();
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse ? logoresponse.image_url : "../../../assets/images/main-logo.png";
      });
  }

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : null;
      });
  }
  getFranchiseUuidbyfranchiseId() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/franchiseprocaisse/findFranchiseUuid/${environment.franchiseID}`
      )
      .subscribe((response) => {
        this.nameFranchise = response ? response.franchise_name : null;
        this.clickAndCollectUrl = response ? response.click_and_collect_url : null;
        this.logoFranchise = response ? response.logo : null;
      });
  }
  
  submit(e) {
    e.preventDefault();
    this.formSubmitted = true;
    

    this.registerError = null;
    // this.formRegister.get('email').setValue(this.formRegister.get('email').value);
    this.formRegister.get('email').setValue(this.formRegister.get('login').value + '@biborne.com');
    this.formRegister.get('login').setValue(this.formRegister.get('login').value);
    this.formRegister.get('password').setValue(this.formRegister.get('login').value);
    // this.formRegister.get('password').setValue(this.formRegister.get('password').value);
    const mFormData = this.formRegister.value;

    // Marquer tous les champs comme touchés pour afficher les messages d'erreur
    Object.values(this.formRegister.controls).forEach(control => {
        control.markAsTouched();
    });

    this.loading = true;
    mFormData.procaisse_licence_id = this.licenceID;
    mFormData.franchiseUUID=this.franchise.franchiseUUID;
    mFormData.nameFranchise=this.nameFranchise;
    mFormData.clickAndCollectUrl="https://"+this.clickAndCollectUrl;
    mFormData.logoFranchise=this.logoFranchise;
    this.userService.createUserNew(mFormData).subscribe((res: any) => {
        const userData = res;
        this.loading = false;
        if (userData && userData.token && userData.token != "err") {
            delete userData.password;
            delete userData.login;
            this.mAppUserService.setUser(userData);
            // this.onSuccess.emit(userData);
            this.router.navigate(['customer-details']);
        } else {
            this.registerError = "N° de téléphone et/ou email déjà existant.";
        }
    }, (_) => {
        this.loading = false;
        this.registerError = "Une erreur s'est produite.";
    });
}

}
