import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private httpClient: HttpClient) {}
  private userUrlNew = `${environment.apiUrl}/franchise/users`;
  private userUrlNewUpAd = `${environment.apiUrl}/franchise/userAddress`;
  private userUrlBackoffice = `${environment.backofficeapiUrl}/api/auth/`;
  public createUser(user) {
    return this.httpClient.post(`${this.userUrlNew}`, user);
  }
  public registerUserGenderBirthday(user) {
    return this.httpClient.post(
      `${this.userUrlNew}/updateGenderBirthday`,
      user
    );
  }
  public getUserExpirationLink(userId) {
    return this.httpClient.get(`${this.userUrlNew}/${userId}/expiration`);
  }
  public disableUserPromotion(userId) {
    return this.httpClient.get(`${this.userUrlNew}/${userId}/promotion`);
  }
  public loginUser(user) {
    return this.httpClient.post(`${this.userUrlNew}/generateJwt`, user);
  }
  public loginUserNew(user) {
    return this.httpClient.post(`${this.userUrlNew}/generateJwt`, user);
  }
  public loginUserFranchise(user) {
    return this.httpClient.post(`${this.userUrlBackoffice}/signin`, user);
  }
  public loginUserNewPg(user) {
    return this.httpClient.post(`${this.userUrlNew}/generateJwtPg`, user);
  }
  public loginUserNewWithoutPassword(user) {
    return this.httpClient.post(`${this.userUrlNew}/generateJwtWithoutPassword`, user);
  }

  public getUser(userId) {
    return this.httpClient.get(`${this.userUrlNew}/${userId}/getInfos`);
  }

  public updateUser(userId, data) {
    return this.httpClient.post(`${this.userUrlNew}/${userId}/update`, data);
  }

  public updateAddressUser(userId, data) {
    return this.httpClient.put(`${this.userUrlNewUpAd}/${userId}`, data);
  }

  public updatePassword(userId, data) {
    return this.httpClient.post(`${this.userUrlNew}/${userId}/password`, data);
  }

  public forgotPassword(data) {
    return this.httpClient.post(`${this.userUrlNew}/forgotReset`, data);
  }
  public forgotPassword2(data) {
    return this.httpClient.post(`${this.userUrlNew}/forgotReset2`, data);
  }
  public forgotPasswordlogin(data) {
    return this.httpClient.post(`${this.userUrlNew}/forgotResetlogin`, data);
  }
  public forgotPasswordloginSms(data) {
    return this.httpClient.post(`${this.userUrlNew}/forgotResetloginSms`, data);
  }
  public resetPassword(data) {
    return this.httpClient.post(
      `${this.userUrlNew}/${data.userId}/resetPassword`,
      data
    );
  }
  public getUserNew(userId) {
    return this.httpClient.get(`${this.userUrlNew}/${userId}/getInfos`);
  }

  public updateUserNew(userId, data) {
    return this.httpClient.post(`${this.userUrlNew}/${userId}/update`, data);
  }

  public updatePasswordNew(userId, data) {
    return this.httpClient.post(`${this.userUrlNew}/${userId}/password`, data);
  }

  public forgotPasswordNew(data) {
    return this.httpClient.post(`${this.userUrlNew}/forgot`, data);
  }
  public createUserNew(user) {
    return this.httpClient.post(`${this.userUrlNew}`, user);
  }
  public createUserETNew(user) {
    return this.httpClient.post(`${this.userUrlNew}/et`, user);
  }
}
