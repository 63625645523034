import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppUserService } from 'src/app/managers/app-user.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    @Input() homepage: boolean;
    imageLogo: any = "";
    backgroundColorOpacity: string = "";
    listCategoriesColor: string = "";

    constructor(
        private appUserService: AppUserService,
        private router: Router,
        private httpClient: HttpClient
    ) { }

    ngOnInit() {
      this.getimageLogo();
      this.getbackgroundColorOpacity();
    }

    logOut() {
        this.appUserService.logoutFranchiseUser();
        this.router.navigate(['authenticatecallcenter']);
    }

    getimageLogo() {
      this.httpClient
        .get<any>(
          `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
        )
        .subscribe((logoresponse) => {
          this.imageLogo = logoresponse ? logoresponse.image_url : "../../../assets/images/main-logo.png";
        });
    }
      
    
    getbackgroundColorOpacity() {
      this.httpClient
        .get<any>(
          `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
        )
        .subscribe((backgroundColorOpacityresponse) => {
          this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
          this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
        });
    }
}