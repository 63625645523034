import { trigger, transition, style, animate, query } from '@angular/animations';
export const home_animation = trigger('home_animation', [
    transition('void => *', [
        style({ transform: 'translateY(-100%)' }),
        animate(400),
        query('.logo-container,.btn', [
            style({ transform: 'rotate(0)' }),
            animate('0.1s', style({ transform: 'rotate(10deg)' })),
            animate('0.1s', style({ transform: 'rotate(-10deg)' })),
            animate('0.1s', style({ transform: 'rotate(10deg)' })),
            animate('0.1s', style({ transform: 'rotate(0)' })),
        ])
    ])
])
export const home_exit = trigger('routeAnimations0', [
    transition('position0 => *', [
        style({ transform: 'translateY(-100%)' }),
        animate(400),
        query(':leave', [
            style({ transform: 'translateY(100%)' }),
            animate(400),
        ]),



    ])
])
