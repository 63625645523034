import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppImageRosolver } from 'src/app/managers/app-image-resolver.service';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { OptionService } from 'src/app/services/option.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filigrane } from 'src/environments/environment';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import {
    trigger, state, style, transition,
    animate, group
} from '@angular/animations';
@Component({
    selector: 'app-additional-options',
    templateUrl: './additional-options.component.html',
    styleUrls: ['./additional-options.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                'transform': 'translateY(0%)', 'opacity': '1', 'visibility': 'visible'
            })),
            state('out', style({
                'transform': 'translateY(100%)', 'opacity': '0', 'visibility': 'hidden'
            })),
            transition('in => out', [group([
                animate('400ms ease-in-out', style({
                    'opacity': '0'
                })),
                animate('600ms ease-in-out', style({
                    'transform': 'translateY(100%)',
                })),
                animate('700ms ease-in-out', style({
                    'visibility': 'hidden'
                }))
            ]
            )]),
            transition('out => in', [group([
                animate('400ms ease-in-out', style({
                    'visibility': 'visible'
                })),
                animate('1s ease-in-out', style({
                    'transform': 'translateY(0%)',
                })),
                animate('800ms ease-in-out', style({
                    'opacity': '1'
                }))
            ]
            )])
        ]),
    ]
})
export class AdditionalOptionsComponent implements OnInit {
    filigrane = filigrane;
    modalOption: NgbModalOptions = {};
    @Input() productId;
    @Output() messageEvent = new EventEmitter<string>();
    loading: Boolean = true;
    currentAdditionalItem: any;
    licenceID: any;
    hideTitle: boolean = false;
    hideTitleOption: boolean = false;
    hideImageOption: boolean = false;
    sizeAdditional: any;
    price_order_type: Boolean;
    availablePrices: any = [];
    mOrder: any;
    currentTicketLine: any;
    options: any;
    ingredients: any;
    hide_ingredients: any;
    sizeNames: any;
    sizeLabel: any = null;
    currentOption: any;
    modalCancel = false;
    currentMenuTicketLine: any = [];
    menuLoading = [];
    currentMenuItem = [];
    currentMenuIndex: any;
    menuOptions: any = [];
    menuIngredients: any = [];
    hide_menu_ingredients: any = [];
    erreurMinMenu = false;
    oneCatError=false;
    activeCounter = -1;
    swiperConfig = {
        direction: 'horizontal',
        slidesPerView: '3',
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
    };
    stickyPriceAnimation = 'out';
    currency: any;
    resto_siret: any;

    constructor(
        private httpClient: HttpClient,
        private productService: ProductService,
        private modalService: NgbModal,
        private optionService: OptionService,
        private appLicenceManager: AppLicenceManagerService,
        private appImageResolver: AppImageRosolver,
        private appOrderService: AppOrderService) {
        this.licenceID = this.appLicenceManager.licenceId;
        this.mOrder = this.appOrderService.order;

    }

    async ngOnInit() {
        if (!this.licenceID) {
            throw new Error("Licence ID not defined");
        }
        window.scrollTo(0,0)
        this.currentAdditionalItem = await this.fetchCurrentProduct(this.productId);
        if (!this.currentAdditionalItem) {
            //redirect to 404
            return;
        }
        if (this.currentAdditionalItem.is_menu) {
            this.currentAdditionalItem.cartesJson.forEach(element => {
                if (element.items.length) {
                    this.appImageResolver.resolveProducts(element.items)
                }
            });
        }
        const params = this.appLicenceManager.parameters
        if (params) {
            this.hideTitle = params.hide_name_products || false;
            this.hideTitleOption = params.hide_name_options || false;
            this.hideImageOption = params.hide_image_options && !this.hideTitleOption || false;
        }
        this.appImageResolver.resolveProduct(this.currentAdditionalItem);
        this.price_order_type = this.currentAdditionalItem.different_price;
        const metaDataItemSize = {
            name: null,
            price: null
        }
        if (this.mOrder) {
            if (this.currentAdditionalItem.many_size) {
                this.sizeNames = this.currentAdditionalItem.sizeNames;
                if (this.price_order_type) {
                    this.getEnabledSizesWithPricesAndTypes();
                }
                else {
                    this.getEnabledSizesWithPrices();
                }
                this.sizeAdditional = this.availablePrices[0].size;
                this.sizeLabel = this.availablePrices[0].label;
                metaDataItemSize.price = this.availablePrices[0].price

            }
            else {
                this.sizeAdditional = null;
                if (this.price_order_type) {
                    this.getPricesByOrderTypes(metaDataItemSize)
                }
                else {
                    metaDataItemSize.price = this.currentAdditionalItem.price
                }
            }
            metaDataItemSize.name = this.sizeAdditional;
        }
        this.currentTicketLine = {
            product: this.currentAdditionalItem.name,
            path: this.currentAdditionalItem.path,
            items: [],
            imageUrl: this.currentAdditionalItem.imageUrl || null,
            size: metaDataItemSize.name,
            productPrice: metaDataItemSize.price,
            price: 0,
            priceTotal: metaDataItemSize.price,
            priceQTotal: metaDataItemSize.price,
            quantity: 1,
            supplements: [],
            options: [],
            discount: 0.0,
            label_discount: null,
            basketIndex: this.mOrder.ticketLines.length,
            promotions: this.currentAdditionalItem.promotions,
            note: null,
            place_served: null,
            time_served: null,
            ref_product: this.productId,
        };
        this.loading = false;
        this.fetchOptions();
        setTimeout(() => {
            this.stickyPriceAnimation = 'in';
        }, 300)
    }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

    setSelectedSize(mSize) {
        this.sizeAdditional = mSize.size;
        this.sizeLabel = mSize.label;
        this.currentTicketLine.size = mSize.size;
        this.currentTicketLine.productPrice = mSize.price;
        this.refetchOptions();
        this.refetchProductMenu();
        this.calculTotal();
    }
    increment(arg) {
        if (arg === '+') {
            this.currentTicketLine.quantity += 1;
            this.calculTotal();
            for (let i = 0; i < this.currentMenuTicketLine.length; i++) {
                this.currentMenuTicketLine[i].quantity += 1
                this.calculMenuTotal(i)
            }
        }
        if (arg === '-' && this.currentTicketLine.quantity > 1) {
            this.currentTicketLine.quantity -= 1
            this.calculTotal();
            for (let i = 0; i < this.currentMenuTicketLine.length; i++) {
                this.currentMenuTicketLine[i].quantity -= 1
                this.calculMenuTotal(i)
            }
        }
    }
    setStateMenuIngredient(ingredientItem, index): void {
        const selected = ingredientItem.selected;
        ingredientItem.selected = !ingredientItem.selected;
        if (!selected) {
            this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(ingredientItem, true));
        } else {
            this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.filter(o => o.ref_option !== ingredientItem.id_ingredient);
        }
    }
    private calculTotal() {
        let priceTotal = this.currentTicketLine.productPrice;
        let priceQTotal = this.currentTicketLine.productPrice * this.currentTicketLine.quantity
        this.currentTicketLine.supplements.forEach(o => {
            priceTotal += o.price !== undefined ? o.price : 0;
            priceQTotal += o.price !== undefined ? o.price * this.currentTicketLine.quantity : 0;
        });
        this.currentTicketLine.priceTotal = priceTotal;
        this.currentTicketLine.priceQTotal = priceQTotal;
    }
    private calculMenuTotal(index) {
        let priceTotal = this.currentMenuTicketLine[index].productPrice;
        let priceQTotal = this.currentMenuTicketLine[index].productPrice * this.currentMenuTicketLine[index].quantity
        this.currentMenuTicketLine[index].supplements.forEach(o => {
            priceTotal += o.price !== undefined ? o.price : 0;
            priceQTotal += o.price !== undefined ? o.price * this.currentMenuTicketLine[index].quantity : 0;
        });
        this.currentMenuTicketLine[index].priceTotal = priceTotal;
        this.currentMenuTicketLine[index].priceQTotal = priceQTotal;
    }
    getCurrentPrice() {
        let price = this.currentTicketLine.priceQTotal;
        this.currentMenuTicketLine.forEach(element => {
            price += element.priceQTotal;
        });
        return price;
    }
    addToBasket() {
        let selectedSize = 0
        if (this.currentAdditionalItem.is_menu) {
            this.erreurMinMenu = false;
            this.currentMenuTicketLine.forEach(element => {
                if (element.product != undefined) { selectedSize++ }
            });
            this.erreurMinMenu = selectedSize === this.currentAdditionalItem.cartesJson.length ? false : true
        }
        let erreur = false;
        if (!this.erreurMinMenu) {
            this.options.forEach(element => {
                let countSelected = 0;
                element.items.forEach(item => {
                    if (item.selected) { countSelected += item.selected};
                });
                if (element.actualMin && element.actualMin > countSelected) {
                    element.minErreur = true;
                    this.oneCatError=true
                    erreur = true;
                }
                else {
                    element.minErreur = false;
                }
            });
            if (!erreur) {
                let menuErreur = false;
                this.menuOptions.forEach(group => {
                    group.forEach(element => {
                        let countSelected = 0;
                        element.items.forEach(item => {
                            if (item.selected) { countSelected += item.selected};
                        });
                        if (element.actualMin && element.actualMin > countSelected) {
                            element.minErreur = true;
                            menuErreur = true;
                        }
                        else {
                            element.minErreur = false;
                        }
                    });
                });
                if (!menuErreur) {
                    if (!this.currentTicketLine) {
                        return;
                    }
                    let price = this.currentTicketLine.priceQTotal;
                    this.currentMenuTicketLine.forEach(element => {
                        price += element.priceQTotal;
                        this.currentTicketLine.items.push(element)
                    });
                    this.currentTicketLine.priceQTotal = price;
                    if (this.currentTicketLine.promotions.length) {
                        this.checkPromotions(this.currentTicketLine, this.appOrderService.order)
                    }
                    else if (!this.currentTicketLine.promotions.length) {
                        this.appOrderService.addLine(this.currentTicketLine);
                    }
                    this.closeOptionView();
                }
            }
        }
    }
    setStateIngredient(ingredientItem): void {
        const selected = ingredientItem.selected;
        ingredientItem.selected = !ingredientItem.selected;
        if (!selected) {
            this.currentTicketLine.supplements.push(this.makeOptionLine(ingredientItem, true));
        } else {
            this.currentTicketLine.supplements = this.currentTicketLine.supplements.filter(o => o.ref_option !== ingredientItem.id_ingredient);
        }
    }
    checkPromotions(ticketline, order) {
        let promo_found = false;
        ticketline.promotions.forEach(promotion => {
            if ((promotion.type_order == order.type || promotion.type_order == undefined)
                && (promotion.size_product == undefined || promotion.size_product == this.sizeAdditional)) {
                promo_found = true;
                ticketline.promo_id = promotion.id;
                let products_in_promo = []
                var totalQuantity = 0;
                order.ticketLines.forEach(element => {
                    if (promotion.id == element.promo_id) {
                        products_in_promo.push(element)
                        totalQuantity += element.quantity;
                    }
                });
                products_in_promo.push(ticketline)
                totalQuantity += ticketline.quantity;
                if (totalQuantity >= promotion.quantity) {
                    this.recountPromotion(order, promotion, totalQuantity, products_in_promo);
                }
                else {
                    this.appOrderService.addLine(this.currentTicketLine);
                }
            }

        });
        if (!promo_found) {
            this.appOrderService.addLine(this.currentTicketLine);
        }
    }
    recountPromotion(order, promo, quantity, tab) {
        tab = tab.sort(function (a, b) {
            return a.productPrice - b.productPrice
        })
        var promo_number = Math.trunc(quantity / promo.quantity)
        tab.forEach(element => {
            if (promo_number == 0) {
                element.discount = 0;
                element.label_discount = "";
                element.priceQTotal = element.priceQTotal;
            }
            else {
                let price = 0
                if (element.quantity <= promo_number) {
                    price = (promo.discount / 100) * element.productPrice * element.quantity;
                    promo_number -= element.quantity;

                }
                else if (element.quantity > promo_number) {
                    price = (promo.discount / 100) * element.productPrice * promo_number;
                    promo_number = 0;
                }
                element.discount = price;
                element.label_discount = promo.name_promotion;
                element.priceQTotal -= price;
            }
            let index = order.ticketLines.findIndex((t) => t.basketIndex == element.basketIndex)
            if (index === -1) { order.ticketLines.push(element) }
            else if (index !== -1) {
                order.ticketLines[index] = element
            }
        });
        this.recountBasketPrices(order)
    }
    recountBasketPrices(order) {
        var globalPrice = 0;
        var globalQPrice = 0;
        order.ticketLines.forEach(line => {
            globalPrice += line.priceTotal;
            globalQPrice += line.priceQTotal;

        });
        order.globalPrice = globalPrice;
        order.globalQPrice = globalQPrice
        this.appOrderService.updateBasketPromo(order)
    }
    /* NEW */
    private fetchCurrentProduct(productId) {
        return new Promise(async (resolve, reject) => {
            this.productService.getProduct(this.licenceID, productId, this.mOrder.type).subscribe(res => {
                resolve(res);
            }, _ => {
                reject(null);
            });
        })
    }
    private findSubOptionsInfos(sub_ids) {
        return new Promise((resolve, reject) => {
            this.optionService.getSubGroupsInfo(this.licenceID, sub_ids).subscribe((result) => {
                resolve(result);
            });
        });
    }
    /************************************************************************************************************ 
 * ***********************************************TOOLS******************************************************
 * **********************************************************************************************************
*/
    getPricesByOrderTypes(md) {
        switch (this.mOrder.type) {
            case OrderTypeEnum.ON_THE_SPOT:
                md.price = this.currentAdditionalItem.price_sp;
                break;
            case OrderTypeEnum.TAKE_AWAY:
                md.price = this.currentAdditionalItem.price_emp;
                break;
            case OrderTypeEnum.DELIVERY:
                md.price = this.currentAdditionalItem.price_lv;
                break;
        }
    }
    getEnabledSizesWithPrices() {
        if (this.sizeNames.product_size_1 !== "" && this.currentAdditionalItem.size_junior_enabled) {
            this.availablePrices.push(
                { label: "product_size_1", size: this.sizeNames.product_size_1, price: this.currentAdditionalItem.price_junior }
            )
        }
        if (this.sizeNames.product_size_2 !== "" && this.currentAdditionalItem.size_senior_enabled) {
            this.availablePrices.push(
                { label: "product_size_2", size: this.sizeNames.product_size_2, price: this.currentAdditionalItem.price_senior }
            )
        }
        if (this.sizeNames.product_size_3 !== "" && this.currentAdditionalItem.size_mega_enabled) {
            this.availablePrices.push(
                { label: "product_size_3", size: this.sizeNames.product_size_3, price: this.currentAdditionalItem.price_mega }
            )
        }
        if (this.sizeNames.product_size_4 !== "" && this.currentAdditionalItem.sizes[0] && this.currentAdditionalItem.sizes[0].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_4", size: this.sizeNames.product_size_4, price: this.currentAdditionalItem.sizes[0].price }
            )
        }
        if (this.sizeNames.product_size_5 !== "" && this.currentAdditionalItem.sizes[1] && this.currentAdditionalItem.sizes[1].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_5", size: this.sizeNames.product_size_5, price: this.currentAdditionalItem.sizes[1].price }
            )
        }
        if (this.sizeNames.product_size_6 !== "" && this.currentAdditionalItem.sizes[2] && this.currentAdditionalItem.sizes[2].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_6", size: this.sizeNames.product_size_6, price: this.currentAdditionalItem.sizes[2].price }
            )
        }
        if (this.sizeNames.product_size_7 !== "" && this.currentAdditionalItem.sizes[3] && this.currentAdditionalItem.sizes[3].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_7", size: this.sizeNames.product_size_7, price: this.currentAdditionalItem.sizes[3].price }
            )
        }
        if (this.sizeNames.product_size_8 !== "" && this.currentAdditionalItem.sizes[4] && this.currentAdditionalItem.sizes[4].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_8", size: this.sizeNames.product_size_8, price: this.currentAdditionalItem.sizes[4].price }
            )
        }
        if (this.sizeNames.product_size_9 !== "" && this.currentAdditionalItem.sizes[5] && this.currentAdditionalItem.sizes[5].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_9", size: this.sizeNames.product_size_9, price: this.currentAdditionalItem.sizes[5].price }
            )
        }
        if (this.sizeNames.product_size_10 !== "" && this.currentAdditionalItem.sizes[6] && this.currentAdditionalItem.sizes[6].size_enabled) {
            this.availablePrices.push(
                { label: "product_size_10", size: this.sizeNames.product_size_10, price: this.currentAdditionalItem.sizes[6].price }
            )
        }
    }
    getEnabledSizesWithPricesAndTypes() {
        switch (this.mOrder.type) {
            case OrderTypeEnum.ON_THE_SPOT:
                if (this.sizeNames.product_size_1 !== "" && this.currentAdditionalItem.size_junior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_1", size: this.sizeNames.product_size_1, price: this.currentAdditionalItem.price_sp_junior }
                    )
                }
                if (this.sizeNames.product_size_2 !== "" && this.currentAdditionalItem.size_senior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_2", size: this.sizeNames.product_size_2, price: this.currentAdditionalItem.price_sp_senior }
                    )
                }
                if (this.sizeNames.product_size_3 !== "" && this.currentAdditionalItem.size_mega_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_3", size: this.sizeNames.product_size_3, price: this.currentAdditionalItem.price_sp_mega }
                    )
                }
                if (this.sizeNames.product_size_4 !== "" && this.currentAdditionalItem.sizes[0] && this.currentAdditionalItem.sizes[0].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_4", size: this.sizeNames.product_size_4, price: this.currentAdditionalItem.sizes[0].price_sp }
                    )
                }
                if (this.sizeNames.product_size_5 !== "" && this.currentAdditionalItem.sizes[1] && this.currentAdditionalItem.sizes[1].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_5", size: this.sizeNames.product_size_5, price: this.currentAdditionalItem.sizes[1].price_sp }
                    )
                }
                if (this.sizeNames.product_size_6 !== "" && this.currentAdditionalItem.sizes[2] && this.currentAdditionalItem.sizes[2].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_6", size: this.sizeNames.product_size_6, price: this.currentAdditionalItem.sizes[2].price_sp }
                    )
                }
                if (this.sizeNames.product_size_7 !== "" && this.currentAdditionalItem.sizes[3] && this.currentAdditionalItem.sizes[3].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_7", size: this.sizeNames.product_size_7, price: this.currentAdditionalItem.sizes[3].price_sp }
                    )
                }
                if (this.sizeNames.product_size_8 !== "" && this.currentAdditionalItem.sizes[4] && this.currentAdditionalItem.sizes[4].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_8", size: this.sizeNames.product_size_8, price: this.currentAdditionalItem.sizes[4].price_sp }
                    )
                }
                if (this.sizeNames.product_size_9 !== "" && this.currentAdditionalItem.sizes[5] && this.currentAdditionalItem.sizes[5].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_9", size: this.sizeNames.product_size_9, price: this.currentAdditionalItem.sizes[5].price_sp }
                    )
                }
                if (this.sizeNames.product_size_10 !== "" && this.currentAdditionalItem.sizes[6] && this.currentAdditionalItem.sizes[6].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_10", size: this.sizeNames.product_size_10, price: this.currentAdditionalItem.sizes[6].price_sp }
                    )
                }
                break;
            case OrderTypeEnum.TAKE_AWAY:
                if (this.sizeNames.product_size_1 !== "" && this.currentAdditionalItem.size_junior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_1", size: this.sizeNames.product_size_1, price: this.currentAdditionalItem.price_emp_junior }
                    )
                }
                if (this.sizeNames.product_size_2 !== "" && this.currentAdditionalItem.size_senior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_2", size: this.sizeNames.product_size_2, price: this.currentAdditionalItem.price_emp_senior }
                    )
                }
                if (this.sizeNames.product_size_3 !== "" && this.currentAdditionalItem.size_mega_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_3", size: this.sizeNames.product_size_3, price: this.currentAdditionalItem.price_emp_mega }
                    )
                }
                if (this.sizeNames.product_size_4 !== "" && this.currentAdditionalItem.sizes[0] && this.currentAdditionalItem.sizes[0].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_4", size: this.sizeNames.product_size_4, price: this.currentAdditionalItem.sizes[0].price_emp }
                    )
                }
                if (this.sizeNames.product_size_5 !== "" && this.currentAdditionalItem.sizes[1] && this.currentAdditionalItem.sizes[1].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_5", size: this.sizeNames.product_size_5, price: this.currentAdditionalItem.sizes[1].price_emp }
                    )
                }
                if (this.sizeNames.product_size_6 !== "" && this.currentAdditionalItem.sizes[2] && this.currentAdditionalItem.sizes[2].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_6", size: this.sizeNames.product_size_6, price: this.currentAdditionalItem.sizes[2].price_emp }
                    )
                }
                if (this.sizeNames.product_size_7 !== "" && this.currentAdditionalItem.sizes[3] && this.currentAdditionalItem.sizes[3].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_7", size: this.sizeNames.product_size_7, price: this.currentAdditionalItem.sizes[3].price_emp }
                    )
                }
                if (this.sizeNames.product_size_8 !== "" && this.currentAdditionalItem.sizes[4] && this.currentAdditionalItem.sizes[4].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_8", size: this.sizeNames.product_size_8, price: this.currentAdditionalItem.sizes[4].price_emp }
                    )
                }
                if (this.sizeNames.product_size_9 !== "" && this.currentAdditionalItem.sizes[5] && this.currentAdditionalItem.sizes[5].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_9", size: this.sizeNames.product_size_9, price: this.currentAdditionalItem.sizes[5].price_emp }
                    )
                }
                if (this.sizeNames.product_size_10 !== "" && this.currentAdditionalItem.sizes[6] && this.currentAdditionalItem.sizes[6].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_10", size: this.sizeNames.product_size_10, price: this.currentAdditionalItem.sizes[6].price_emp }
                    )
                }
                break;
            case OrderTypeEnum.DELIVERY:
                if (this.sizeNames.product_size_1 !== "" && this.currentAdditionalItem.size_junior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_1", size: this.sizeNames.product_size_1, price: this.currentAdditionalItem.price_lv_junior }
                    )
                }
                if (this.sizeNames.product_size_2 !== "" && this.currentAdditionalItem.size_senior_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_2", size: this.sizeNames.product_size_2, price: this.currentAdditionalItem.price_lv_senior }
                    )
                }
                if (this.sizeNames.product_size_3 !== "" && this.currentAdditionalItem.size_mega_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_3", size: this.sizeNames.product_size_3, price: this.currentAdditionalItem.price_lv_mega }
                    )
                }
                if (this.sizeNames.product_size_4 !== "" && this.currentAdditionalItem.sizes[0] && this.currentAdditionalItem.sizes[0].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_4", size: this.sizeNames.product_size_4, price: this.currentAdditionalItem.sizes[0].price_lv }
                    )
                }
                if (this.sizeNames.product_size_5 !== "" && this.currentAdditionalItem.sizes[1] && this.currentAdditionalItem.sizes[1].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_5", size: this.sizeNames.product_size_5, price: this.currentAdditionalItem.sizes[1].price_lv }
                    )
                }
                if (this.sizeNames.product_size_6 !== "" && this.currentAdditionalItem.sizes[2] && this.currentAdditionalItem.sizes[2].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_6", size: this.sizeNames.product_size_6, price: this.currentAdditionalItem.sizes[2].price_lv }
                    )
                }
                if (this.sizeNames.product_size_7 !== "" && this.currentAdditionalItem.sizes[3] && this.currentAdditionalItem.sizes[3].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_7", size: this.sizeNames.product_size_7, price: this.currentAdditionalItem.sizes[3].price_lv }
                    )
                }
                if (this.sizeNames.product_size_8 !== "" && this.currentAdditionalItem.sizes[4] && this.currentAdditionalItem.sizes[4].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_8", size: this.sizeNames.product_size_8, price: this.currentAdditionalItem.sizes[4].price_lv }
                    )
                }
                if (this.sizeNames.product_size_9 !== "" && this.currentAdditionalItem.sizes[5] && this.currentAdditionalItem.sizes[5].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_9", size: this.sizeNames.product_size_9, price: this.currentAdditionalItem.sizes[5].price_lv }
                    )
                }
                if (this.sizeNames.product_size_10 !== "" && this.currentAdditionalItem.sizes[6] && this.currentAdditionalItem.sizes[6].size_enabled) {
                    this.availablePrices.push(
                        { label: "product_size_10", size: this.sizeNames.product_size_10, price: this.currentAdditionalItem.sizes[6].price_lv }
                    )
                }
                break;
        }
    }
    getOptionsPrices(optionItem) {
        let actualPrice = optionItem.price || 0;
        if (this.sizeAdditional) {
            if (optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    switch (this.sizeLabel) {
                        case "product_size_1":
                            actualPrice = mPriceByTypeOrder["price_junior"] || actualPrice;
                            break;
                        case "product_size_2":
                            actualPrice = mPriceByTypeOrder["price_senior"] || actualPrice;
                            break;
                        case "product_size_3":
                            actualPrice = mPriceByTypeOrder["price_mega"] || actualPrice;
                            break;
                        case "product_size_4":
                            actualPrice = mPriceByTypeOrder["price_size1"] || actualPrice;
                            break;
                        case "product_size_5":
                            actualPrice = mPriceByTypeOrder["price_size2"] || actualPrice;
                            break;
                        case "product_size_6":
                            actualPrice = mPriceByTypeOrder["price_size3"] || actualPrice;
                            break;
                        case "product_size_7":
                            actualPrice = mPriceByTypeOrder["price_size4"] || actualPrice;
                            break;
                        case "product_size_8":
                            actualPrice = mPriceByTypeOrder["price_size5"] || actualPrice;
                            break;
                        case "product_size_9":
                            actualPrice = mPriceByTypeOrder["price_size6"] || actualPrice;
                            break;
                        case "product_size_10":
                            actualPrice = mPriceByTypeOrder["price_size7"] || actualPrice;
                            break;
                    }
                }
            }
            else if (optionItem.many_size && !optionItem.many_type) {
                switch (this.sizeLabel) {
                    case "product_size_1":
                        actualPrice = optionItem.price_junior;
                        break;
                    case "product_size_2":
                        actualPrice = optionItem.price_senior;
                        break;
                    case "product_size_3":
                        actualPrice = optionItem.price_mega;
                        break;
                    case "product_size_4":
                        actualPrice = optionItem.price_size1;
                        break;
                    case "product_size_5":
                        actualPrice = optionItem.price_size2;
                        break;
                    case "product_size_6":
                        actualPrice = optionItem.price_size3;
                        break;
                    case "product_size_7":
                        actualPrice = optionItem.price_size4;
                        break;
                    case "product_size_8":
                        actualPrice = optionItem.price_size5;
                        break;
                    case "product_size_9":
                        actualPrice = optionItem.price_size6;
                        break;
                    case "product_size_10":
                        actualPrice = optionItem.price_size7;
                        break;
                }
            }
            else if (!optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price"] || actualPrice
                }
            }
        }
        else {
            if (optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price_junior"] || actualPrice
                }
            }
            else if (optionItem.many_size && !optionItem.many_type) {
                actualPrice = optionItem.price_junior;
            }
            else if (!optionItem.many_size && optionItem.many_type && optionItem.prices
                && Array.isArray(optionItem.prices) && optionItem.prices.length) {
                const mPriceByTypeOrder = optionItem.prices.find(o => o.type_order === this.appOrderService.order.type);
                if (mPriceByTypeOrder) {
                    actualPrice = mPriceByTypeOrder["price"] || actualPrice
                }
            }
        }
        return actualPrice;
    }
    getInfoGroupSupplement(option, param, index) {
        let oCategory = null;
        if (param == "menuCall") {
            oCategory = this.currentMenuItem[index].options.find(c => c.idSupplement === option.id)
        }
        else {
            oCategory = this.currentAdditionalItem.options.find(c => c.idSupplement === option.id)
        }
        let info_option = "";
        let free_number = 0;
        if (this.sizeAdditional && oCategory.many_size) {
            switch (this.sizeLabel) {
                case 'product_size_1':
                    if (oCategory.min_option > 0) {
                        info_option += oCategory.min_option + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option
                    if (oCategory.max_option > 0) {
                        info_option += oCategory.max_option + ' maximum '
                    }
                    if (oCategory.option_free > 0) {
                        free_number = oCategory.option_free;
                        info_option += oCategory.option_free + ' offert '
                    }
                    break;
                case 'product_size_2':
                    if (oCategory.min_option_size2 > 0) {
                        info_option += oCategory.min_option_size2 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size2
                    if (oCategory.max_option_size2 > 0) {
                        info_option += oCategory.max_option_size2 + ' maximum '
                    }
                    if (oCategory.option_free_size2 > 0) {
                        free_number = oCategory.option_free_size2
                        info_option += oCategory.option_free_size2 + ' offert '
                    }
                    break;
                case 'product_size_3':
                    if (oCategory.min_option_size3 > 0) {
                        info_option += oCategory.min_option_size3 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size3
                    if (oCategory.max_option_size3 > 0) {
                        info_option += oCategory.max_option_size3 + ' maximum '
                    }
                    if (oCategory.option_free_size3 > 0) {
                        free_number = oCategory.option_free_size3;
                        info_option += oCategory.option_free_size3 + ' offert '
                    }
                    break;
                case 'product_size_4':
                    if (oCategory.min_option_size4 > 0) {
                        info_option += oCategory.min_option_size4 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size4
                    if (oCategory.max_option_size4 > 0) {
                        info_option += oCategory.max_option_size4 + ' maximum '
                    }
                    if (oCategory.option_free_size4 > 0) {
                        free_number = oCategory.option_free_size4;
                        info_option += oCategory.option_free_size4 + ' offert '
                    }
                    break;
                case 'product_size_5':
                    if (oCategory.min_option_size5 > 0) {
                        info_option += oCategory.min_option_size5 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size5
                    if (oCategory.max_option_size5 > 0) {
                        info_option += oCategory.max_option_size5 + ' maximum '
                    }
                    if (oCategory.option_free_size5 > 0) {
                        free_number = oCategory.option_free_size5;
                        info_option += oCategory.option_free_size5 + ' offert '
                    }
                    break;
                case 'product_size_6':
                    if (oCategory.min_option_size6 > 0) {
                        info_option += oCategory.min_option_size6 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size6
                    if (oCategory.max_option_size6 > 0) {
                        info_option += oCategory.max_option_size6 + ' maximum '
                    }
                    if (oCategory.option_free_size6 > 0) {
                        free_number = oCategory.option_free_size6
                        info_option += oCategory.option_free_size6 + ' offert '
                    }
                    break;
                case 'product_size_7':
                    if (oCategory.min_option_size7 > 0) {
                        info_option += oCategory.min_option_size7 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size7
                    if (oCategory.max_option_size7 > 0) {
                        info_option += oCategory.max_option_size7 + ' maximum '
                    }
                    if (oCategory.option_free_size7 > 0) {
                        free_number = oCategory.option_free_size7
                        info_option += oCategory.option_free_size7 + ' offert '
                    }
                    break;
                case 'product_size_8':
                    if (oCategory.min_option_size8 > 0) {
                        info_option += oCategory.min_option_size8 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size8
                    if (oCategory.max_option_size8 > 0) {
                        info_option += oCategory.max_option_size8 + ' maximum '
                    }
                    if (oCategory.option_free_size8 > 0) {
                        free_number = oCategory.option_free_size8
                        info_option += oCategory.option_free_size8 + ' offert '
                    }
                    break;
                case 'product_size_9':
                    if (oCategory.min_option_size9 > 0) {
                        info_option += oCategory.min_option_size9 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size9
                    if (oCategory.max_option_size9 > 0) {
                        info_option += oCategory.max_option_size9 + ' maximum '
                    }
                    if (oCategory.option_free_size9 > 0) {
                        free_number = oCategory.option_free_size9
                        info_option += oCategory.option_free_size9 + ' offert '
                    }
                    break;
                case 'product_size_10':
                    if (oCategory.min_option_size10 > 0) {
                        info_option += oCategory.min_option_size10 + ' minimum '
                    }
                    oCategory.actualMin = oCategory.min_option_size10
                    if (oCategory.max_option_size10 > 0) {
                        info_option += oCategory.max_option_size10 + ' maximum '
                    }
                    if (oCategory.option_free_size10 > 0) {
                        free_number = oCategory.option_free_size10
                        info_option += oCategory.option_free_size10 + ' offert '
                    }
                    break;
            }
        }
        if (!this.sizeAdditional || !oCategory.many_size) {
            if (oCategory.min_option > 0) {
                info_option += oCategory.min_option + ' minimum '
                oCategory.actualMin = oCategory.min_option
            }
            if (oCategory.max_option > 0) {
                info_option += oCategory.max_option + ' maximum '
            }
            if (oCategory.option_free > 0) {
                free_number = oCategory.option_free
                info_option += oCategory.option_free + ' offert '
            }
        }
        free_number > 0 ? option.show_prices = false : true;
        option.actualMin = oCategory.actualMin
        return info_option;
    }
    getInfoSubGroupSupplement(option) {
        let info_option = "";
        if (option.min_option > 0) {
            info_option += option.min_option + ' minimum '
        }
        if (option.max_option > 0) {
            info_option += option.max_option + ' maximum '
        }
        if (option.option_free > 0) {
            option.show_prices = false
            info_option += option.option_free + ' offert '
        }
        else if (option.min_option <= 0) {
            option.show_prices = true
        }
        return info_option;
    }
    /************************************************************************************************************ 
     * *****************************************END***TOOLS******************************************************
     * **********************************************************************************************************
    */
    /************************************************************************************************************ 
      * ***********************************************SUPPLEMENT*************************************************
      * **********************************************************************************************************
     */
    private async fetchOptions() {
        const ingredients = this.currentAdditionalItem.ingredients;
        let options: any = [];
        options = this.currentAdditionalItem.newOptions
        this.options = options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'simpleCall', -1);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.selected = 0;
                optionItem.showQT = false
                optionItem.free = 0;
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.ingredients = ingredients && Array.isArray(ingredients) && ingredients.map(item => {
            item.selected = false;
            return item;
        }) || [];
        if (this.ingredients && this.ingredients.length) this.hide_ingredients = true;
        this.appImageResolver.resolveOptions(this.options);
        this.appImageResolver.resolveIngredients(this.ingredients);
    }
    refetchOptions() {
        this.ingredients.forEach(element => {
            element.selected = false;
        });
        this.options = this.options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'simpleCall', -1);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.selected = 0;
                optionItem.showQT = false
                optionItem.free = 0;
                optionItem.freeStyle = false;
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.currentTicketLine.supplements = [];
        this.calculTotal();
    }
    private async fetchSubOptions(optionParentItem) {
        let subOptionsDataIds: any = []
        subOptionsDataIds = await this.findSubOptionsInfos(optionParentItem.groups_sub_supplement);
        this.currentOption.sub_options_data = subOptionsDataIds.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true
            optionCategory.infos = this.getInfoSubGroupSupplement(optionCategory);
            optionCategory.sub_supplements = optionCategory.sub_supplements.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.selected = 0;
                optionItem.showQT = false
                optionItem.free = 0;
                optionItem.parentIndex = optionParentItem.listIndex
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.appImageResolver.resolveSubOptions(this.currentOption.sub_options_data);
        this.currentOption.sub_loaded = true;
    }
    private makeOptionLine(optionItem, isIngredient = false, parent = -1) {
        return {
            name: !isIngredient && optionItem.name || `Sans ${optionItem.name}`, // Sans ?
            is_ingredient: isIngredient,
            quantity: !isIngredient && optionItem.selected ? optionItem.selected : 1,
            price: !isIngredient && (optionItem.selected - optionItem.free) * optionItem.actualPrice || 0,
            ref_option: !isIngredient && optionItem.id || optionItem.id_ingredient || null, // id_ingredient
            ref_product: -1,
            ref_carte: -1,
            number_option: 1,
            number_free: !isIngredient && optionItem.free ? optionItem.free : 0,
            name_product: null,
            name_carte: null,
            index_carte: -1,
            listIndex: optionItem.listIndex,
            parentIndex: parent > -1 && parent
        }
    }
    makeSelection2(group, item, param, index): void {
        group.minErreur = false;
        let option_free = group.option_free;
        let max_option = group.max_option;
        group.max_selected = max_option
        group.max_free = option_free
        this.makeCounts(group, item, 'sub', null, param, index)
        this.calculTotal();
        this.modalCancel = this.getCancelBtnValue();
    }
    makeSelection(content, group, item, param, index): void {
        let oCategory = null;
        group.minErreur = false;
        if (param == "menuCall") {
            oCategory = this.currentMenuItem[index].options.find(c => c.idSupplement === group.id)
            this.currentMenuIndex = index;
        }
        else {
            oCategory = this.currentAdditionalItem.options.find(c => c.idSupplement === group.id)
        }

        let option_free = oCategory.option_free;
        let max_option = oCategory.max_option;
        if (this.sizeAdditional && oCategory.many_size) {
            switch (this.sizeLabel) {
                case 'product_size_1':
                    option_free = oCategory.option_free;
                    max_option = oCategory.max_option;
                    break;
                case 'product_size_2':
                    option_free = oCategory.option_free_size2;
                    max_option = oCategory.max_option_size2;
                    break;
                case 'product_size_3':
                    option_free = oCategory.option_free_size3;
                    max_option = oCategory.max_option_size3;
                    break;
                case 'product_size_4':
                    option_free = oCategory.option_free_size4;
                    max_option = oCategory.max_option_size4;
                    break;
                case 'product_size_5':
                    option_free = oCategory.option_free_size5;
                    max_option = oCategory.max_option_size5;
                    break;
                case 'product_size_6':
                    option_free = oCategory.option_free_size6;
                    max_option = oCategory.max_option_size6;
                    break;
                case 'product_size_7':
                    option_free = oCategory.option_free_size7;
                    max_option = oCategory.max_option_size7;
                    break;
                case 'product_size_8':
                    option_free = oCategory.option_free_size8;
                    max_option = oCategory.max_option_size8;
                    break;
                case 'product_size_9':
                    option_free = oCategory.option_free_size9;
                    max_option = oCategory.max_option_size9;
                    break;
                case 'product_size_10':
                    option_free = oCategory.option_free_size10;
                    max_option = oCategory.max_option_size10;
                    break;
            }
        }
        else if (!this.sizeAdditional || !oCategory.many_size) {
            option_free = oCategory.option_free;
            max_option = oCategory.max_option;
        }
        group.max_selected = max_option
        group.max_free = option_free
        this.makeCounts(group, item, 'option', content, param, index)
        if (param == "menuCall") {
            this.calculMenuTotal(index);
        }
        else {
            this.calculTotal();
        }


    }
    makeCounts(group, item, param, content, menuParam, index) {
        if (group.total_selected < group.max_selected) {
            if (item.selected == 0) {
                item.selected = 1;
                group.total_selected++;
                if (group.total_free < group.max_free) {
                    item.free++;
                    group.total_free++;
                    item.freeStyle = true;
                    if (group.total_free == group.max_free) {
                        group.show_prices = true;
                    }
                }
                if (menuParam == "menuCall") {
                    item.listIndex = this.currentMenuTicketLine[index].supplements.length;
                    if (param === "sub") { this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(item, false, item.parentIndex)); }
                    else if (param !== "sub") { this.currentMenuTicketLine[index].supplements.push(this.makeOptionLine(item)); }
                }
                else if (menuParam == "simpleCall") {
                    item.listIndex = this.currentTicketLine.supplements.length;
                    if (param === "sub") { this.currentTicketLine.supplements.push(this.makeOptionLine(item, false, item.parentIndex)); }
                    else if (param !== "sub") { this.currentTicketLine.supplements.push(this.makeOptionLine(item)); }
                }

                if (item.groups_sub_supplement.length) {
                    this.modalCancel = false;
                    this.currentOption = item;
                    item.quantity_off = true;
                    this.modalOption.backdrop = 'static';
                    this.modalOption.keyboard = false;
                    this.modalService.open(content, this.modalOption)
                    this.fetchSubOptions(item)
                }
            }
            else {
                group.total_selected -= item.selected
                item.selected = 0;
                this.distributeFree(group, item, param, menuParam, index)
                group.total_free -= item.free;
                item.free = 0;
                item.freeStyle = false;
                this.removeItem(item, menuParam, index)
                if (group.total_free < group.max_free) {
                    group.show_prices = false;
                }
            }
        }
        else {
            if (item.selected != 0) {
                group.total_selected -= item.selected
                item.selected = 0;
                this.distributeFree(group, item, param, menuParam, index)
                group.total_free -= item.free;
                item.free = 0;
                item.freeStyle = false;
                this.removeItem(item, menuParam, index)
                if (group.total_free < group.max_free) {
                    group.show_prices = false;
                }
            }
            else {
                // else : click select on unselected while max is exceeded
            }

        }
        item.showQT = item.selected == 0 ? false : true;
    }
    incrementOQ(group, item, param, index): void {
        group.minErreur = false;
        if (group.total_selected < group.max_selected) {
            item.selected++;
            group.total_selected++;
            if (group.total_free < group.max_free) {
                item.free++;
                group.total_free++;
                item.freeStyle = true;
                if (group.total_free == group.max_free) {
                    group.show_prices = true;
                }
            }
            else {
                item.freeStyle = false;
            }
            let ticketSupplement = null;
            if (param === "simpleCall") {
                ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == item.listIndex)
            }
            else if (param === "menuCall") {
                ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == item.listIndex)
            }
            ticketSupplement.number_free = item.free;
            ticketSupplement.number_option = item.selected;
            ticketSupplement.quantity = item.selected;
            ticketSupplement.price = (item.selected - item.free) * item.actualPrice;

        }
        else {
            //else : click on increment while max is exceeded
            //later item max selection < group max selection
            // increment item selection, retrieve the number from selected list 
        }
        if (param === "simpleCall") {
            this.calculTotal();
        }
        else if (param === "menuCall") {
            this.calculMenuTotal(index);
        }
    }
    decrementOQ(group, item, param, index): void {
        if (item.selected > 0 && item.selected > item.free) {
            item.selected--;
            group.total_selected--;
            if (item.selected == item.free) { item.freeStyle = true }
        }
        else if (item.selected > 0 && item.selected == item.free) {
            item.selected--;
            group.total_selected--;
            item.free--;
            group.total_free--;
            this.distributeOneFree(group, item, param, index)
            if (group.total_free < group.max_free) {
                group.show_prices = false;
            }
        }
        if (item.free == 0) {
            item.freeStyle = false
        }
        if (item.selected > 0) {
            let ticketSupplement = null;
            if (param === "simpleCall") {
                ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == item.listIndex)
            }
            else if (param === "menuCall") {
                ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == item.listIndex)
            }
            ticketSupplement.number_free = item.free;
            ticketSupplement.number_option = item.selected;
            ticketSupplement.quantity = item.selected;
            ticketSupplement.price = (item.selected - item.free) * item.actualPrice;
        }
        else if (item.selected == 0) {
            this.removeItem(item, param, index)
        }
        if (param === "simpleCall") {
            this.calculTotal();
        }
        else if (param === "menuCall") {
            this.calculMenuTotal(index);
        }
        item.showQT = item.selected == 0 ? false : true;
    }
    checkSubsMinRequired() {
        let minErreur = false
        this.currentOption.sub_options_data.forEach(element => {
            if (element.min_option > element.total_selected) {
                element.minErreur = true;
                minErreur = true;
            }
        });
        if (minErreur === false) {
            this.modalService.dismissAll('saved')
        }
    }
    distributeFree(group, item, param, menuParam, index) {
        if (item.free > 0) {
            let selectedItems = null;
            if (param == "sub") {
                selectedItems = group.sub_supplements.filter((i) => i.selected > 0)
            }
            else {
                selectedItems = group.items.filter((i) => i.selected > 0)
            }
            selectedItems.forEach(element => {
                let counterFree = 0;
                if (element.selected > item.free && element.selected > element.free) {
                    counterFree = item.free;
                }
                else if (element.selected <= item.free && element.selected > element.free) {
                    counterFree = element.selected - element.free;
                }
                element.free += counterFree;
                item.free -= counterFree
                if (element.selected == element.free) {
                    element.freeStyle = true;
                }
                let ticketSupplement = null;
                if (menuParam === "menuCall") {
                    ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == element.listIndex)

                }
                else if (menuParam === "simpleCall") {
                    ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == element.listIndex)
                }
                ticketSupplement.number_free = element.free;
                ticketSupplement.price = (element.selected - element.free) * element.actualPrice;
            });
        }
    }
    distributeOneFree(group, item, param, index) {
        let selectedItems = null;
        if (item.parentIndex === undefined) { selectedItems = group.items.filter((i) => i.selected > 0 && i !== item) }
        else { selectedItems = group.sub_supplements.filter((i) => i.selected > 0 && i !== item) }
        let value = 1
        selectedItems.forEach(element => {
            if (value > 0) {
                if (element.selected > element.free) {
                    element.free += value;
                    group.total_free += value;
                    value--;
                }
                if (element.selected == element.free) {
                    element.freeStyle = true;
                }
                let ticketSupplement = null
                if (param === "simpleCall") {
                    ticketSupplement = this.currentTicketLine.supplements.find((o) => o.listIndex == element.listIndex)
                }
                else if (param === "menuCall") {
                    ticketSupplement = this.currentMenuTicketLine[index].supplements.find((o) => o.listIndex == element.listIndex)
                }

                ticketSupplement.number_free = element.free;
                ticketSupplement.price = (element.selected - element.free) * element.price;
            }
        });
    }
    removeItem(item, param, index) {
        if (item.parentIndex === undefined) {
            this.updateIndexes(item, "option", param, index);
        }
        else {
            this.updateIndexes(item, "sub", param, index)
        }
    }
    updateIndexes(item, param, menuParam, index) {
        let listIndex = item.listIndex;
        let count = 1
        if (menuParam === "simpleCall") {
            this.currentTicketLine.supplements = this.currentTicketLine.supplements.
                filter(o => o.listIndex !== listIndex);
            this.currentTicketLine.supplements.forEach(element => {
                if (element.parentIndex === listIndex) {
                    count++;
                    this.currentTicketLine.supplements = this.currentTicketLine.supplements.
                        filter(o => o.listIndex !== element.listIndex);
                }
            })
            this.currentTicketLine.supplements.forEach(element => {
                this.currentTicketLine.supplements.forEach(element1 => {
                    if (element1.parentIndex !== false && element1.parentIndex === element.listIndex) {
                        element1.parentIndex -= count
                    }
                });
                this.updateOptionIndexes(param, element, count)
                element.listIndex -= count;
            });
        }
        else if (menuParam === "menuCall") {
            this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.
                filter(o => o.listIndex !== listIndex);
            this.currentMenuTicketLine[index].supplements.forEach(element => {
                if (element.parentIndex === listIndex) {
                    count++;
                    this.currentMenuTicketLine[index].supplements = this.currentMenuTicketLine[index].supplements.
                        filter(o => o.listIndex !== element.listIndex);
                }
            })
            this.currentMenuTicketLine[index].supplements.forEach(element => {
                this.currentMenuTicketLine[index].supplements.forEach(element1 => {
                    if (element1.parentIndex !== false && element1.parentIndex === element.listIndex) {
                        element1.parentIndex -= count
                    }
                });
                this.updateOptionIndexes(param, element, count)
                element.listIndex -= count;
            });
        }

    }
    updateOptionIndexes(param, compared, value) {
        let selectedItems = []
        if (param == "sub") {
            this.currentOption.sub_options_data.forEach(element => {
                selectedItems = element.sub_supplements.filter((o) => o.selected > 0);
                selectedItems.forEach(item => {
                    if (item.listIndex === compared.listIndex) {
                        item.listIndex -= value
                    }
                });
            });
        }
        else {
            this.options.forEach(element => {
                selectedItems = element.items.filter((o) => o.selected > 0)
                selectedItems.forEach(item => {
                    if (item.listIndex === compared.listIndex) {
                        item.listIndex -= value
                    }
                });
            });
        }
    }
    /************************************************************************************************************ 
     * *********************************************END***SUPPLEMENT*********************************************
     * **********************************************************************************************************
    */
    toggleOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleMenuOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleSubOptionItems(group) {
        group.showItems = !group.showItems;
    }
    toggleIngredient() {
        if (this.hide_ingredients == true) this.hide_ingredients = false;
        else this.hide_ingredients = true;
    }
    toggleMenuIngredient(index) {
        if (this.hide_menu_ingredients[index] == true) this.hide_menu_ingredients[index] = false;
        else this.hide_menu_ingredients[index] = true;
    }
    getCancelBtnValue() {
        for (let k = 0; k < this.currentOption.sub_options_data.length; k++) {
            for (let m = 0; m < this.currentOption.sub_options_data[k].sub_supplements.length; m++) {
                if (this.currentOption.sub_options_data[k].sub_supplements[m].selected) {
                    return true;
                }
            }
        }
        return false;
    }

    refetchProductMenu() {
        this.currentAdditionalItem.cartesJson.forEach(menu => {
            menu.items.forEach(product => {
                product.selected = false;
            });
        })
        this.currentMenuTicketLine = [];
        this.menuLoading = [];
        this.currentMenuItem = [];
        this.currentMenuIndex = null;
        this.menuOptions = [];
        this.menuIngredients = [];
    }
    fetchProductMenu(carte, item, index,contentOptions) {
        if (!item.selected) {
            this.currentMenuTicketLine[index] = {
                product: item.name,
                path: item.path,
                imageUrl: item.imageUrl || null,
                size: this.sizeAdditional,
                productPrice: item.price,
                price: 0,
                priceTotal: item.price,
                priceQTotal: item.price,
                quantity: 1,
                supplements: [],
                options: [],
                discount: 0.0,
                label_discount: null,
                note: null,
                place_served: null,
                time_served: null,
                ref_product: item.idItem,
                ref_category: item.idCat,
            };
            carte.items.forEach(element => {
                element.selected = false;
            });
            this.menuLoading[index] = true;
            item.selected = true;
            this.currentMenuItem[index] = item;
            this.activeCounter = index;
            this.fetchMenuOptions(item, index)
            this.modalService.open(contentOptions, {
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
                centered: true,
                scrollable: true
            });
        }
    }
    private async fetchMenuOptions(product, index) {
        const ingredients = product.ingredients;
        let options: any = [];
        options = product.newOptions
        this.menuOptions[index] = options.map(optionCategory => {
            optionCategory.total_selected = 0;
            optionCategory.total_free = 0;
            optionCategory.show_prices = true;
            optionCategory.showItems = true;
            optionCategory.infos = this.getInfoGroupSupplement(optionCategory, 'menuCall', index);
            optionCategory.items = optionCategory.items.map(optionItem => {
                let actualPrice = this.getOptionsPrices(optionItem);
                optionItem.selected = 0;
                optionItem.free = 0;
                optionItem.freeStyle = false;
                optionItem.actualPrice = actualPrice;
                return optionItem;
            })
            return optionCategory;
        });
        this.menuIngredients[index] = ingredients && Array.isArray(ingredients) && ingredients.map(item => {
            item.selected = false;
            return item;
        }) || [];
        if (this.menuIngredients[index] && this.menuIngredients[index].length) this.hide_menu_ingredients[index] = true;
        this.appImageResolver.resolveOptions(this.menuOptions[index]);
        this.appImageResolver.resolveIngredients(this.menuIngredients[index]);
        this.menuLoading[index] = false;
    }

    closeOptionView() {
        this.messageEvent.emit("test")
    }

    goBack() {
        this.closeOptionView();
    }
}
