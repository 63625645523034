import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket: SocketIOClient.Socket;
  constructor() { 
    this.socket = io('wss://api1.biborne.com');
  }
  sendMessage(msg: string) {
    this.socket.emit('Front New Order',  msg );
  }
}
