import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppUserService } from 'src/app/managers/app-user.service';
import { UserService } from 'src/app/services/user.service';
declare var require: any
const moment = require('moment-timezone');

@Component({
  selector: 'app-app-link-reset',
  templateUrl: './app-link-reset.component.html',
  styleUrls: ['./app-link-reset.component.scss']
})
export class AppLinkResetComponent implements OnInit {
  userData: FormGroup;
  error = null;
  success = false;
  result:any;
  expired=false;
  startTime:any;
  constructor(
      private userService: UserService,
      private router:Router,
      private route:ActivatedRoute,
      private appUser: AppUserService,
      private appLicenceManager: AppLicenceManagerService) {
  }

  ngOnInit() {
    if(!this.route.snapshot.paramMap.get('id')){
      return ;
    }
    this.userService.getUserExpirationLink(this.route.snapshot.paramMap.get('id')).subscribe(res=>{
      this.startTime=res
      let now=moment(new Date())
      let before=moment(this.startTime.url_validation)
      let duration=moment.duration(now.diff(before)).asMinutes()
      if(duration>5){this.expired=true}
    })
      this.userData = new FormGroup({
          password: new FormControl("", [Validators.required]),
          confirmation: new FormControl("", [Validators.required]),
      });
  }


  send() {
      this.success = false;
      this.error = null;
      if (this.userData.valid) {
       
          const mData = {
              ...this.userData.value,
              userId:this.route.snapshot.paramMap.get('id'),
              franchiseUUID: this.appLicenceManager.franchise.franchiseUUID
          };
          if (mData.password.length < 6) {
              this.error = "Le nouveau mot de passe doit être avoir au moins 6 caractères.";
              return;
          }
          if (mData.password !== mData.confirmation) {
              this.error = "Les mots de passe ne correspondent pas.";
              return;
          }
          this.userService.resetPassword(mData).subscribe(res => {
            this.result=res;
              const userData = res;
              if (this.result && this.result.token && this.result.token != "err") {
                  this.appUser.setUser(userData);
                  this.appUser.triggerAuth(true);
                  this.router.navigate(['/account-orders']);
              }
              else{
                  this.error = "Compte Introuvable.";
              }
          }, _ => {
              this.error = "Une erreur s'est produite.";
          })
       }
  }

}
