import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Order } from "src/app/models/my-order";
import { AppOrderService } from "src/app/managers/app-order.service";
import { Router, NavigationEnd } from "@angular/router";
import { OrderTypeEnum } from "src/app/enums/order-type";
import { ProductService } from "src/app/services/product.service";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { AppImageRosolver } from "src/app/managers/app-image-resolver.service";
import { BasketBehaviorService } from "src/app/services/basket-behavior.service";
import { filigrane } from "src/environments/environment";
import { AppUserService } from "src/app/managers/app-user.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-basket-content",
  templateUrl: "./basket-content.component.html",
  styleUrls: ["./basket-content.component.scss"],
})
export class BasketContentComponent implements OnInit {
  @Input() position;
  @Output() close = new EventEmitter();
  filigrane = filigrane;
  ORDER_TYPES = OrderTypeEnum;
  currentOrder: Order = null;
  isOrderUpdate = false;
  countLines: any;
  active = false;
  licenceID: any;
  orderType: any;
  globalProducts: any;
  basketBigSlide: any;
  userData: any;
  loaded = false;
  currency: any;
  resto_siret: any;

  constructor(
    private httpClient: HttpClient,
    private appLicenceManager: AppLicenceManagerService,
    private appOrder: AppOrderService,
    private appUserService: AppUserService,
    private appImageResolver: AppImageRosolver,
    private productService: ProductService,
    private router: Router,
    private basketBehavior: BasketBehaviorService
  ) {}

  initBasket() {
    let timeout = null;
    this.countLines =
      (this.appOrder.order && this.appOrder.order.ticketLines.length) || 0;
    this.appOrder.onUpdate.subscribe((newOrder) => {
      this.countLines = (newOrder && newOrder.ticketLines.length) || 0;
      if (this.countLines > 0) {
        this.basketBehavior.onBasketOrder(true);
      } else {
        this.basketBehavior.onBasketOrder(false);
      }
      this.isOrderUpdate = true;
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.isOrderUpdate = false;
      }, 5000);
    });
    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }

    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse
          ? Currencyresponse.countryInfo.currencyInfo.symbol
          : environment.currency;
        console.log(this.currency);
      });
  }

  ngOnInit(): void {
    this.initBasket();
    this.currentOrder = this.appOrder.order;

    this.loaded = true;
    if (
      this.currentOrder &&
      this.currentOrder.ticketLines &&
      this.currentOrder.ticketLines.length
    ) {
      this.appImageResolver.resolveProducts(this.currentOrder.ticketLines);
    }
    this.appOrder.onUpdate.subscribe((newOrder) => {
      this.currentOrder = newOrder;
      if (
        this.currentOrder &&
        this.currentOrder.ticketLines &&
        this.currentOrder.ticketLines.length
      ) {
        this.appImageResolver.resolveProducts(this.currentOrder.ticketLines);
      }
    });
    // Event on change in order
  }

  closeBasket() {
    //Hack click
    $("body").trigger("click");
    if (this.basketBigSlide) {
      this.basketBigSlide.controller.changeState();
      this.basketBigSlide.view.toggleClose();
    }
  }

  // EDIT PRODUCT ICON
  editProduct(line, index) {
    this.router.navigate(["product-details"], {
      queryParams: {
        idItem: line.ref_product,
        lineIndex: index,
      }
    });
  }

  // CHANGE QUANTITY PRODUCT ICONS
  changeProductQuantity(line, index, qtyOffset, event) {
    if (line.quantity == 1 && qtyOffset == -1) {
      this.removeLine(line, index, event);
    }
    else {
      this.appOrder.changeLineQuantity(index, qtyOffset);
    }
  }

  removeLine(line, index, event): boolean {
    event.stopPropagation();
    if (line.promo_id) {
      this.checkPromotions(line, this.currentOrder);
    } else {
      if (line.isFidProduct) {
        this.userData = this.appUserService.getUser();
        this.userData.cagnotte += line.price_point;
        this.appUserService.setUser(this.userData);
        this.basketBehavior.onCagnotteChange(this.userData.cagnotte);
      }
      this.appOrder.removeLine(line, index);
    }
    return false;
  }
  checkPromotions(ticketline, order) {
    let promotion = ticketline.promotions.find(
      (p) => p.id == ticketline.promo_id
    );
    let products_in_promo = [];
    var totalQuantity = 0;
    order.ticketLines.forEach((element) => {
      if (
        promotion.id == element.promo_id &&
        ticketline.basketIndex !== element.basketIndex
      ) {
        products_in_promo.push(element);
        totalQuantity += element.quantity;
      }
    });
    // if(totalQuantity>=promotion.quantity){
    //    this.recountPromotion(order,promotion,totalQuantity,products_in_promo);
    // }
    this.recountPromotion(order, promotion, totalQuantity, products_in_promo);
    this.appOrder.removeLine(ticketline, ticketline.basketIndex);
  }
  recountPromotion(order, promo, quantity, tab) {
    tab = tab.sort(function (a, b) {
      return a.productPrice - b.productPrice;
    });
    var promo_number = Math.trunc(quantity / promo.quantity);
    tab.forEach((element) => {
      if (promo_number == 0) {
        element.discount = 0;
        element.label_discount = "";
        element.priceQTotal = element.priceTotal * element.quantity;
      } else {
        let price = 0;
        if (element.quantity <= promo_number) {
          // price = element.productPrice * element.quantity;
          price =
            (promo.discount / 100) * element.productPrice * element.quantity;
          promo_number -= element.quantity;
        } else if (element.quantity > promo_number) {
          //price = element.productPrice * element.promo_number;
          price = (promo.discount / 100) * element.productPrice * promo_number;
          promo_number = 0;
        }
        element.discount = price;
        element.label_discount = promo.name_promotion;
        element.priceQTotal = element.priceTotal * element.quantity - price;
      }
      let index = order.ticketLines.findIndex(
        (t) => t.basketIndex == element.basketIndex
      );
      order.ticketLines[index] = element;
    });
    this.recountBasketPrices(order);
  }
  recountBasketPrices(order) {
    var globalPrice = 0;
    var globalQPrice = 0;
    order.ticketLines.forEach((line) => {
      globalPrice += line.priceTotal;
      globalQPrice += line.priceQTotal;
    });
    order.globalPrice = globalPrice;
    order.globalQPrice = globalQPrice;
    this.appOrder.updateBasketPromo(order);
  }

  canBeDelivered() {
    if (
      this.currentOrder &&
      this.currentOrder.type === OrderTypeEnum.DELIVERY &&
      this.currentOrder.orderCity &&
      this.currentOrder.orderCity.min_order &&
      this.currentOrder.globalQPrice < this.currentOrder.orderCity.min_order
    ) {
      return false;
    }
    return true;
  }
  checkNextRoute() {
    this.licenceID = this.appLicenceManager.licenceId;
    this.orderType = this.appOrder.order.type;
    this.productService
      .getGlobalAdditionalProducts(this.licenceID, this.orderType)
      .subscribe((res) => {
        this.globalProducts = res;
        if (this.globalProducts.length) {
          localStorage.setItem(
            "additional_dessert",
            JSON.stringify(this.globalProducts)
          );
          this.router.navigate(["global-additional"]);
        } else {
          this.router.navigate(["order-checkout"]);
        }
      });
    // before it was redirect to order-checkout
  }
}
