import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppLicenceManagerService } from '../managers/app-licence-manager.service.';
import { AppUserService } from '../managers/app-user.service';

@Injectable({
    providedIn: 'root'
})
export class AppAuthGuardService implements CanActivate {


    constructor(
        private appLicenceManager: AppLicenceManagerService,
        private router: Router,
        private appUser: AppUserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.appUser.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['authenticate'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}