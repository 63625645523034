import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasketBehaviorService {
  closedRestaurant = new BehaviorSubject<any>(false);
  isClosed = this.closedRestaurant.asObservable();
  basketOrderSource = new BehaviorSubject<any>(null);
  basketOrder = this.basketOrderSource.asObservable();
  openBasketSource = new BehaviorSubject<any>(null);
  openBasket = this.openBasketSource.asObservable();
  basketNumberSource = new BehaviorSubject<any>(0);
  numberBasket = this.basketNumberSource.asObservable();
  cagnotteSource = new BehaviorSubject<any>(null);
  cagnotte = this.cagnotteSource.asObservable();
  constructor() { }
  onCagnotteChange(cagnotte){
    this.cagnotteSource.next(cagnotte)
  }
  onBasketOrder(basketOrder) {
    this.basketOrderSource.next(basketOrder);
  }
  onNumberBasket(numberBasket){
    this.basketNumberSource.next(numberBasket)
  }
  onOpenBasket(openBasket) {
    this.openBasketSource.next(openBasket);
  }
  closedNotification(status) {
    this.closedRestaurant.next(status)
  }
}
