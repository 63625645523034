import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent implements OnInit {

  resto_siret: any;
  telephone: any;
  email: any;
  adresse: any;

  constructor(
    private httpClient: HttpClient) { }

  ngOnInit() {
    this.getDetailsResto();
  }

  

  getDetailsResto() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findOneRestaurantCheckCaisse/${this.resto_siret}`
      )
      .subscribe((reponse) => {
        this.telephone = reponse && reponse.telephone ? reponse.telephone : null;
        this.email = reponse && reponse.email ? reponse.email : null;
        this.adresse = reponse && reponse.adresse ? reponse.adresse : null;
        console.log("asasas" + this.telephone);
      });
  }

}
