import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppUserService } from 'src/app/managers/app-user.service';
import { phoneNumberValidator, textNotEmptyValidator } from './custom-validators';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-form-register',
  templateUrl: './app-form-register.component.html',
  styleUrls: ['./app-form-register.component.scss']
})
export class AppFormRegisterComponent implements OnInit {

  formRegister: FormGroup;
    licenceID: any;
    franchise:any;
    loading: Boolean = false;
    @Output()
    onSuccess = new EventEmitter();
    registerError = null;
    conditionsError = null;
    has_fid:Boolean=false;
    siret:any;
    conditionsAccepted: boolean = false;
    formSubmitted: boolean = false;
    nameFranchise : any;
    clickAndCollectUrl : any;
    logoFranchise : any;

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private appLicenceManager: AppLicenceManagerService,
        private mAppUserService: AppUserService) {
        this.licenceID = this.appLicenceManager.licenceId;
        this.franchise=appLicenceManager.franchise
       this.has_fid=this.franchise.has_fid;
       //this.siret=appLicenceManager.parameters.siret
    }
    

    ngOnInit() {
        this.formRegister = new FormGroup({
            // login: new FormControl(null, [Validators.required]),
            login: new FormControl(null, [Validators.required, phoneNumberValidator()]),
            password: new FormControl(null, [Validators.required, textNotEmptyValidator()]),
            first_name: new FormControl(null, [Validators.required, textNotEmptyValidator()]),
            last_name: new FormControl(null, [Validators.required, textNotEmptyValidator()]),
            email: new FormControl(null, [Validators.required, Validators.email]),
             has_fid: new FormControl(true),
            conditions: new FormControl(null, [Validators.required]),
        });

        this.getFranchiseUuidbyfranchiseId();
    }

  getFranchiseUuidbyfranchiseId() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/franchiseprocaisse/findFranchiseUuid/${environment.franchiseID}`
      )
      .subscribe((response) => {
        this.nameFranchise = response ? response.franchise_name : null;
        this.clickAndCollectUrl = response ? response.click_and_collect_url : null;
        this.logoFranchise = response ? response.logo : null;
      });
  }

    onEmailInputChange(inputValue: string) {
        const cleanedValue = inputValue.replace(/\s/g, '');
        this.formRegister.get('email').setValue(cleanedValue);
      }
      onPhoneInputChange(inputValue: string) {
          const cleanedValue = inputValue.replace(/\s/g, '');
          this.formRegister.get('login').setValue(cleanedValue);
        }
        

    submit(e) {
        e.preventDefault();
        this.formSubmitted = true;
        


        if (!this.conditionsAccepted) {
            this.conditionsError = "Vous devez accepter les conditions pour continuer.";
            return;
        }
        
        if (!this.formRegister.valid || !this.franchise.franchiseUUID) {
            return;
        }

        this.registerError = null;
        this.formRegister.get('email').setValue(this.formRegister.get('email').value.replace(/\s/g, ''));
        this.formRegister.get('login').setValue(this.formRegister.get('login').value.replace(/\s/g,''));
        this.formRegister.get('password').setValue(this.formRegister.get('password').value.replace(/\s/g,''));
        const mFormData = this.formRegister.value;

        // Marquer tous les champs comme touchés pour afficher les messages d'erreur
        Object.values(this.formRegister.controls).forEach(control => {
            control.markAsTouched();
        });

        this.loading = true;
        mFormData.procaisse_licence_id = this.licenceID;
        mFormData.franchiseUUID=this.franchise.franchiseUUID;
        mFormData.nameFranchise=this.nameFranchise;
        mFormData.clickAndCollectUrl="https://"+this.clickAndCollectUrl;
        mFormData.logoFranchise=this.logoFranchise;
        this.userService.createUserNew(mFormData).subscribe((res: any) => {
            const userData = res;
            this.loading = false;
            if (userData && userData.token && userData.token != "err") {
                delete userData.password;
                delete userData.login;
                this.mAppUserService.setUser(userData);
                this.onSuccess.emit(userData);
            } else {
                this.registerError = "N° de téléphone et/ou email déjà existant.";
            }
        }, (_) => {
            this.loading = false;
            this.registerError = "Une erreur s'est produite.";
        });
    }

}
