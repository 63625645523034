import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppOrderService } from '../managers/app-order.service';

@Injectable({
    providedIn: 'root'
})
export class AppOrderGuardService implements CanActivate {


    constructor(private router: Router, private appOrder: AppOrderService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.appOrder.order) {
            const lastUpdate = this.appOrder.order.lastUpdate || 0,
                nowDate = Date.now();
            const diff = Math.floor((nowDate - lastUpdate) / 60000);
            if (!lastUpdate || diff > 60) {
                this.appOrder.clear();
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}