import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OrderTypeEnum } from "src/app/enums/order-type";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { AppOrderService } from "src/app/managers/app-order.service";
import { MyOrderService } from "src/app/services/my-order.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
declare var require: any;
const moment = require("moment-timezone");
@Component({
  selector: "app-order-types",
  templateUrl: "./order-types.component.html",
  styleUrls: ["./order-types.component.scss"],
})
export class OrderTypesComponent implements OnInit {
  current_resto: any;
  orderType: null;
  AVAILABLE_TYPES = OrderTypeEnum;
  private _order = null;
  isDeliveryEnabled = false;
  isTakeAwayEnabled = false;
  isOnTheSpotEnabled = false;
  isDeliveryAtPlatform = false;
  urlPlatform = null;
  showTypeOrders = true;
  showZones = false;
  orderCity = null;
  zones = [];
  isClosed = false;
  backgroundType: any = "";
  imageAtspot: any = "";
  imageTakeaway: any = "";
  imagePlateforme: any = "";
  delivery: any = "";
  imageLogo: any = "";
  config_atspot = false;
  config_takeaway = false;
  config_delivery = false;
  config_plateform = false;
  urlPlateform: string = "";
  resto_siret: any;
  currency: any;

  constructor(
    private httpClient: HttpClient,
    private restoManager: AppLicenceManagerService,
    private appLicenceManager: AppLicenceManagerService,
    private myOrderService: MyOrderService,
    private router: Router,
    private appOrderService: AppOrderService
  ) {
    this.current_resto = this.restoManager.currentResto;
    this.appLicenceManager.factory(this.current_resto.currentLicence);
  }

  ngOnInit() {
    this.myOrderService
      .fetchOrderParams(this.appLicenceManager.licenceId)
      .subscribe((res) => {
        if (res) {
          const isoWeekDay = moment().isoWeekday(),
            now = moment();
          let mDay = null;
          if (res.days && res.days.length) {
            mDay = res.days.find((d) => {
              return d.day === isoWeekDay;
            });
          }
          let isDayOff = (mDay && mDay.dayOff) || false;
          let isOpenTime = false;
          if (mDay) {
            mDay.workShifts.forEach((s) => {
              const sHours = parseInt(s.start_shift),
                eHours = parseInt(s.end_shift);
              if (now.hours() >= sHours && now.hours() < eHours) {
                isOpenTime = true;
              }
            });
          }
          // this.isClosed = !isOpenTime || isDayOff || false;
          // localStorage.setItem("isClosed", JSON.stringify(this.isClosed));
          if (res.zones && res.zones.length) {
            res.zones = res.zones.map((zone) => {
              zone.cities = zone.cities.map((city) => {
                city.min_order = parseFloat(zone.amount) || 0;
                city.deliveryCostOrder = parseFloat(zone.delivery_costs) || 0;
                return city;
              });
              return zone;
            });

            //TODO: Sort min to max amount
          }
          this.isDeliveryEnabled = this.current_resto.delivery || false;
          this.isTakeAwayEnabled = this.current_resto.takeAway || false;
          this.isOnTheSpotEnabled = this.current_resto.atSpot || false;
          this.zones = res.zones || [];
          this.appLicenceManager.saveParameters(res);
        }
      });
    this._order = this.appOrderService.order;
    if (this._order && this._order.type) {
      this.orderType = this._order.type;
    }

    this.getbackgroundcnctype();
    this.getimageAspot();
    this.getimageTakeaway();
    this.getimageLogo();
    this.getimageDelivery();
    this.getBackofficeOrderTypes();
    this.getPlateforme();
    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

  getBackofficeOrderTypes() {
    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/clickandcollect/${this.resto_siret}`
      )
      .subscribe((clickncollectparamresponse) => {
        this.config_atspot = clickncollectparamresponse.at_spot ? true : false;
        this.config_takeaway = clickncollectparamresponse.take_away ? true : false;
        this.config_delivery = clickncollectparamresponse.delivery ? true : false;
        this.config_plateform = clickncollectparamresponse.plateform ? true : false;
        this.urlPlateform = clickncollectparamresponse.plateform_url ? clickncollectparamresponse.plateform_url : null;
      });
  }

  getPlateforme() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectPlateforme/angular/${environment.franchiseID}`
      )
      .subscribe((bkgrdtyperesponse) => {
        this.imagePlateforme = bkgrdtyperesponse ? bkgrdtyperesponse.image_url : null;
      });
  }

  getbackgroundcnctype() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectbkgrdtype/angular/${environment.franchiseID}`
      )
      .subscribe((bkgrdtyperesponse) => {
        this.backgroundType = bkgrdtyperesponse ? bkgrdtyperesponse.image_url : null;
      });
  }

  getimageAspot() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectatspot/angular/${environment.franchiseID}`
      )
      .subscribe((atspotresponse) => {
        this.imageAtspot = atspotresponse ? atspotresponse.image_url : null;
      });
  }

  getimageTakeaway() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollecttakeaway/angular/${environment.franchiseID}`
      )
      .subscribe((takeawayresponse) => {
        this.imageTakeaway = takeawayresponse ? takeawayresponse.image_url : null;
      });
  }

  getimageDelivery() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectLivraison/angular/${environment.franchiseID}`
      )
      .subscribe((deliveryresponse) => {
        this.delivery = deliveryresponse ? deliveryresponse.image_url : null;
      });
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse ? logoresponse.image_url : null;
      });
  }

  setOrderType(mValue) {
    this.orderType = mValue;
    if (!this.orderType) {
      return;
    }
    if (
      this.orderType === OrderTypeEnum.DELIVERY &&
      this.isDeliveryAtPlatform
    ) {
      //TODO: redirect to platform url 'this.urlPlatform'

      return;
    }
    if (!this._order) {
      this.appOrderService.factory(this.orderType);
    } else {
      if (this._order.type !== this.orderType) {
        this.appOrderService.factory(this.orderType);
      } else {
        this.appOrderService.setType(this.orderType);
      }
    }
    if (this.orderType === OrderTypeEnum.DELIVERY && this.zones.length) {
      //Redirect to choose City
      this.showTypeOrders = false;
      this.showZones = true;
      return;
    }

    this.router.navigateByUrl("categories");
  }

  setCity() {
    if (!this.orderCity && this.orderType === OrderTypeEnum.DELIVERY) {
      return;
    }
    this.appOrderService.setOrderCity(this.orderCity);
    this.router.navigateByUrl("categories");
  }
}
