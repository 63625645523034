import { Component, OnInit } from "@angular/core";
import { MyOrderService } from "src/app/services/my-order.service";
import { AppLicenceManagerService } from "src/app/managers/app-licence-manager.service.";
import { Router, NavigationEnd, RouterOutlet } from "@angular/router";
import { BasketBehaviorService } from "src/app/services/basket-behavior.service";
import { phone } from "src/environments/environment";
import { AppOrderService } from "src/app/managers/app-order.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppUserService } from "src/app/managers/app-user.service";

@Component({
  selector: "app-base-layout",
  templateUrl: "./base-layout.component.html",
  styleUrls: ["./base-layout.component.scss"],
})
export class BaseLayoutComponent implements OnInit {
  isShow = false;
  isClosed = false;
  resto_name: any;
  phone = phone;
  countLines: number;
  imageLogo: any = "";
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  imageHeaderband: any = "";
  clickversion = "";
  userData: any;
  privateNote: string;
  constructor(
    private httpClient: HttpClient,
    private myOrderService: MyOrderService,
    private appLicenceManager: AppLicenceManagerService,
    private basketBehavior: BasketBehaviorService,
    private appOrder: AppOrderService,
    private router: Router,
    private appUser: AppUserService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isShow = false;
      }
    });
    
    this.userData = appUser.getUser();
  }
  ngOnInit() {
    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_name = JSON.parse(localStorage["CURRENT_RESTO"]).pseudo;
    }
    //this.isClosed = true;
    this.basketBehavior.isClosed.subscribe(
      (status) => (this.isClosed = status)
    );
    this.countLines =
      (this.appOrder.order && this.appOrder.order.ticketLines.length) || 0;
    this.appOrder.onUpdate.subscribe((newOrder) => {
      this.countLines = (newOrder && newOrder.ticketLines.length) || 0;
    });

    this.getimageLogo();
    this.getimageHeaderband();
    this.getbackgroundColorOpacity();
    this.getVersion();
    this.getPrivatenote();
  }

  logOut() {

  }
  
  getVersion() {
    this.clickversion = environment.clickversion;
  }

  getimageHeaderband() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectheaderband/angular/${environment.franchiseID}`
      )
      .subscribe((headerbandresponse) => {
        this.imageHeaderband = headerbandresponse ? headerbandresponse.image_url : null;
      });
  }

  getimageLogo() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/clickncollectlogo/angular/${environment.franchiseID}`
      )
      .subscribe((logoresponse) => {
        this.imageLogo = logoresponse ? logoresponse.image_url : "../../../assets/images/main-logo.png";
      });
  }

  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
        this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
      });
  }

  getPrivatenote() {
    const noteFromLocalStorage = localStorage.getItem("formDelivery");
    this.privateNote = JSON.parse(noteFromLocalStorage).privateNote;
  }

  toggleState() {
    this.isShow = !this.isShow;
  }
  navigate_home() {
    this.router.navigate(["/categories"]);
  }
  navigate_categories() {
    this.router.navigate(["/categories"]);
  }
  basketinitial() {
    alert("Vous avez reinitialiser votre panier !");
    this.router.navigate(["/call-restos"]);
  }
  navigate_account() {
    this.router.navigate(["authenticatecallcenter"]);
  }
}
  