import { Injectable, ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { AppDialogConfig } from '../config/app-dialog-config';
import { AppDialogInjector } from '../config/app-dialog-injector';


@Injectable({
    providedIn: 'root',
})
export class AppSnackBarService {
    private cardComponentRef: ComponentRef<SnackBarComponent>;
    timeout = 5000;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector) {
    }

    private appendComponentToBody(config: AppDialogConfig) {
        if (this.cardComponentRef) {
            this.removeComponentFromBody();
        }
        const map = new WeakMap();

        map.set(AppDialogConfig, config);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SnackBarComponent);
        const componentRef = componentFactory.create(new AppDialogInjector(this.injector, map));
        this.appRef.attachView(componentRef.hostView);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        setTimeout(() => {
            this.destroy();
        }, this.timeout);

        this.cardComponentRef = componentRef;
    }

    private removeComponentFromBody() {
        if (this.cardComponentRef) {
            this.appRef.detachView(this.cardComponentRef.hostView);
            this.cardComponentRef.destroy();
        }
        this.cardComponentRef = null;
    }

    private destroy() {
        this.removeComponentFromBody();
    }

    public handle(config: AppDialogConfig) {
        this.appendComponentToBody(config);
    }


}