import { Component, OnInit } from '@angular/core';
import { AppImageRosolver } from 'src/app/managers/app-image-resolver.service';
import { Router } from '@angular/router';
import { AppOrderService } from 'src/app/managers/app-order.service';
import { AppSnackBarService } from 'src/app/managers/app-snackbar.service';
import { OrderTypeEnum } from 'src/app/enums/order-type';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { filigrane } from 'src/environments/environment';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-global-sales',
  templateUrl: './global-sales.component.html',
  styleUrls: ['./global-sales.component.scss']
})
export class GlobalSalesComponent implements OnInit {
  filigrane=filigrane;
  desserts: any;
  currentLoad: Boolean = false;
  optionsView: Boolean = false;
  terminer: Boolean = false;
  selectedItemId: any;
  hideTitle: Boolean = false;
  backgroundColorOpacity: string = "";
  listCategoriesColor: string = "";
  currency: any;
  resto_siret: any;

  constructor(
    private httpClient: HttpClient,
    private appImageResolver: AppImageRosolver,
    private appLicenceManager: AppLicenceManagerService,
    private appOrderService: AppOrderService,
    private appSnackBarService: AppSnackBarService,
    private router: Router) { }

  ngOnInit() {
    window.scrollTo(0,0)
    const params = this.appLicenceManager.parameters
    if (params) {
      this.hideTitle = params.hide_name_products || false;
    }
    this.desserts = JSON.parse(localStorage.additional_dessert);
    this.appImageResolver.resolveProducts(this.desserts)
    this.currentLoad = true;

    this.getbackgroundColorOpacity();
    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }
    
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse ? Currencyresponse.countryInfo.currencyInfo.symbol : environment.currency;
        console.log(this.currency)
      });
  }

  
  getbackgroundColorOpacity() {
    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/ClickandCollectCouleur/findOneColor/${environment.franchiseID}`
      )
      .subscribe((backgroundColorOpacityresponse) => {
        this.backgroundColorOpacity = backgroundColorOpacityresponse ? backgroundColorOpacityresponse.background_home_opacity : 'black';
        this.listCategoriesColor =  backgroundColorOpacityresponse ? backgroundColorOpacityresponse.liste_categories : 'white';
      });
  }

  cancelAdditional() {
    this.router.navigate(['order-checkout']);
  }
  closeOptionView($event) {
    this.optionsView = false
    this.terminer = true;
  }
  fetchProduct(item) {

    if (item.is_menu || item.many_size || item.options.length || item.promotions.length) {
      this.optionsView = true;
      this.selectedItemId = item.idItem;
    }
    else {
      let itemPrice = 0;
      if (item.different_price) {
        switch (this.appOrderService.order.type) {
          case OrderTypeEnum.ON_THE_SPOT:
            itemPrice = item.price_sp;
            break;
          case OrderTypeEnum.TAKE_AWAY:
            itemPrice = item.price_emp;
            break;
          case OrderTypeEnum.DELIVERY:
            itemPrice = item.price_lv;
            break;
          //Add more types
        }
      }
      else {
        itemPrice = item.price
      }
      const mTicketLine = {
        product: item.name,
        path: item.path,
        items:[],
        imageUrl: item.imageUrl || null,
        size: null,
        productPrice: itemPrice,
        price: 0,
        priceTotal: itemPrice,
        priceQTotal: itemPrice,
        quantity: 1,
        supplements: [],
        options: [],
        discount: 0.0,
        label_discount: null,
        note: null,
        place_served: null,
        time_served: null,
        ref_product: item.idItem,
        ref_category: item.idCat,
      };
      this.appOrderService.addLine(mTicketLine);
      this.terminer = true;
      this.appSnackBarService.handle({ data: "Dessert ajouté, un autre dessert?" });
    }
  }
}
