import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppOrderStatusResolver {


    resolveStyle(order): any {
        let style = null;
        switch (order.status) {
            case 'reject':
                //red
                style = {
                    color: 'white',
                    backgound: '#ff7979'
                }
                break;
            case 'paid':
                //green
                style = {
                    color: 'white',
                    backgound: '#00b894'
                }
                break;
            case 'pending':
                //yellow
                style = {
                    backgound: '#fdcb6e'
                }
                break;
            case 'cancel':
                //red light
                style = {
                    color: 'white',
                    backgound: '#e17055'
                }
                break;
            case 'ready':
                style = {
                    color: 'white',
                    backgound: '#74b9ff'
                }
                break;
        }
        return style;
    }
}
