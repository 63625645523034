import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategorieService {

  constructor(private httpClient: HttpClient) { }
  private categoriesUrl = `${environment.apiUrl}/categories`;

  public getCategories(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.categoriesUrl}ByLicenceV0`, params);
  }

  public getSousCategories(licence: any, categorieID: any) {
    let params = { "procaisse_licence_id": licence, "categorieID": categorieID }
    return this.httpClient.post(`${this.categoriesUrl}ChildsByLicence`, params);
  }

  public getCategory(licence: any, categorieID: any,orderType:any) {
    let params = { "procaisse_licence_id": licence,"orderType":orderType }
    return this.httpClient.post(`${this.categoriesUrl}/${categorieID}/getInfosV0`, params);
  }

  public getSizesName(licence: any) {
    let params = { "procaisse_licence_id": licence }
    return this.httpClient.post(`${this.categoriesUrl}/getProductSizes`, params);
  }

}
