import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppLicenceManagerService } from 'src/app/managers/app-licence-manager.service.';
import { AppUserService } from 'src/app/managers/app-user.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-app-form-password-reset',
  templateUrl: './app-form-password-reset.component.html',
  styleUrls: ['./app-form-password-reset.component.scss']
})
export class AppFormPasswordResetComponent implements OnInit {
  userData: FormGroup;
  error = null;
  success = false;
  result:any;
  userCache:any;
  constructor(
      private userService: UserService,
      private router:Router,
      private appUser: AppUserService,
      private appLicenceManager: AppLicenceManagerService) {

  }

  ngOnInit() {
      if(!localStorage.getItem('resetSettings')){
        return ;
      }
      this.userCache=JSON.parse(localStorage.resetSettings)
      this.userData = new FormGroup({
          password: new FormControl("", [Validators.required]),
          confirmation: new FormControl("", [Validators.required]),
      });
  }


  send() {
      this.success = false;
      this.error = null;
      if (this.userData.valid) {
       
          const mData = {
              ...this.userData.value,
              userId:this.userCache.msg.id,
              franchiseUUID: this.appLicenceManager.franchise.franchiseUUID
          };
          if (mData.password.length < 6) {
              this.error = "Le nouveau mot de passe doit être avoir au moins 6 caractères.";
              return;
          }
          if (mData.password !== mData.confirmation) {
              this.error = "Les mots de passe ne correspondent pas.";
              return;
          }
          this.userService.resetPassword(mData).subscribe(res => {
            this.result=res;
              const userData = res;
              if (this.result && this.result.token && this.result.token != "err") {
                  this.appUser.setUser(userData);
                  this.appUser.triggerAuth(true);
                  this.router.navigate(['/account-orders']);
              }
              else{
                  this.error = "Compte Introuvable.";
              }
          }, _ => {
              this.error = "Une erreur s'est produite.";
          })
       }
  }
}
