import { Component, OnInit } from "@angular/core";
import { MyOrderService } from "src/app/services/my-order.service";
import { AppOrderStatusResolver } from "src/app/managers/app-order-status-resolver.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-account-order-detail",
  templateUrl: "./account-order-detail.component.html",
  styleUrls: ["./account-order-detail.component.scss"],
})
export class AccountOrderDetailComponent implements OnInit {
  order: any = [];
  currency: any;
  resto_siret: any;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private myOrderService: MyOrderService,
    private appOrderStatusResolver: AppOrderStatusResolver
  ) {}

  ngOnInit(): void {
    const orderId = localStorage.getItem("order_id");
    this.myOrderService.getOrder(orderId).subscribe(
      (res) => {
        this.order = res;
        this.order.style = this.appOrderStatusResolver.resolveStyle(this.order);
      },
      (_) => {}
    );

    this.getCurrency();
  }

  getCurrency() {
    // this.currency = environment.currency;

    if (localStorage.getItem("CURRENT_RESTO")) {
      this.resto_siret = JSON.parse(localStorage["CURRENT_RESTO"]).siret;
    }

    this.httpClient
      .get<any>(
        `${environment.backofficeapiUrl}/api/restos/findCurrencyBySiret/${this.resto_siret}`
      )
      .subscribe((Currencyresponse) => {
        this.currency = Currencyresponse
          ? Currencyresponse.countryInfo.currencyInfo.symbol
          : environment.currency;
        console.log(this.currency);
      });
  }

  addProductToBasket() {
    console.log("%%%%", this.order.items);
    const myOrder = JSON.parse(localStorage.getItem("myOrder"));
    myOrder.ticketLines = this.order.items;

    myOrder.globalQPrice = this.order.items.reduce((total, item) => {
      return total + item.priceQTotal;
    }, 0);

    localStorage.setItem("myOrder", JSON.stringify(myOrder));
    this.router.navigate(["categories"]);
  }
}
